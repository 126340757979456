declare let $:any;
declare let moment:any;

export class Helpers {

    static setLoading(enable) {
        
		if (!enable) {
			$("#load").fadeOut();
            $('#pre-loader').delay(0).fadeOut('slow');
		} else {
            $("#load").show();
            $('#pre-loader').show();
        }
        
    }

    static movePageTop(){
        setTimeout(function() {
            $('#back-to-top').click();
        }, 1000);
    }

    static fromNow(date:any){
        return moment(date).fromNow();
    }
    
    static longDateTime(date){
        return moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
    }

    static longDate(date){
        return moment(date).format("dddd, MMMM Do YYYY");
    }

    static longShortDate(date){
        return moment(date).format("ddd, MMM DD YYYY");
    }

    static shortDate(date){
        return moment(date).format("MMM Do YYYY");
    }

    static formatMoney(n, c, d, t) {
        var c = isNaN(c = Math.abs(c)) ? 2 : c,
          d = d == undefined ? "." : d,
          t = t == undefined ? "," : t,
          s = n < 0 ? "-" : "",
          i:any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
          j = (j = i.length) > 3 ? j % 3 : 0;
      
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    }
}