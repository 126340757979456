import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-messenger-applicant',
  templateUrl: './chat-messenger-applicant.component.html',
  styleUrls: ['./chat-messenger-applicant.component.css']
})
export class ChatMessengerApplicantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
