import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { SharedResources } from '../../_services/shared.resources';
import { Router } from '@angular/router';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { HttpClient, HttpEvent,HttpEventType, HttpRequest } from '@angular/common/http';

declare let Dropzone:any;
declare let toastr:any;
declare let $:any;
declare let document:any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = null;
    SavedJobs:any = [];

	FileUploads:any = [];
    ActiveDropzones:any = [];

    my_email:string = "";
    alert:any = null;

    cookiesetting:boolean = false;

	constructor(
		private _api: ApiService,
		private _router: Router,
		public appset: AppSettingsUnilynq,
		private httpClient:HttpClient,
		private _resources: SharedResources
	) { }


	ngOnInit() {
        this._resources.UpdateSavedJobs.subscribe(x=>{
            this.GetSavedJobs();
        });
		this._resources.UpdateAuthentication.subscribe(x=>{
			this.AuthenticationStatus();
		});

		this._resources.LogoutAuthentication.subscribe(x=>{
			this.isAuthenticated = false;
			this.user = null;
		});

		this._resources.$HeaderFileUploader.subscribe((res)=>{
            this.AddMediaFiles(res.p,res.e,res.pe,res.a,res.s,res.c,res.err,res.i);
        });

        this._resources.$HeaderFileDownloader.subscribe((res)=>{
            this.downloadFile(res.u,res.m,res.f,res.c,res.b);
        });

        this.AuthenticationStatus();
        this.GetSavedJobs();
        this.AlertSettings();

        if (document.cookie.split(';').some((item) => item.includes('Jobmatch=')
		
		)) {
			this.cookiesetting=true;
			
		}
	}

    CookieSetting(){
        document.cookie = "Jobmatch=Cookies; expires=Wed, 01 Jan 2070 00:00:00 GMT;";
        location.reload();
    }

    ExitDelegateAccess(){
        this.appset.isLocalResolver = false;
        this.appset.LocalResolver = "";
        this.appset.LocalResolverName = "";

        location.reload();
    }

    ReturnDelegateClass(){
        if(this.appset.isLocalResolver) return 'step-down';
        if(!this.cookiesetting) return 'step-down';
        return '';
    }

	AuthenticationStatus(){
		this._api.GET({
			api_route: 'api/account/me',
			success: (data)=>{

				this._resources._isAuthenticated = true;
				this._resources._user = data;

				this.isAuthenticated = true;
				this.user = data;

				this._resources.AuthenticationStatus.next({isAuthenticated: true,user: data});
			},
			error: (error)=>{
				this._resources._isAuthenticated = false;
				this._resources.User = null;

				this._resources.AuthenticationStatus.next({isAuthenticated: false,user: null});
			}
		});
    }

    AlertSettings(){
		this._api.GET({
			api_route: 'api/job/alerts/v1/active',
			success: (data)=>{

                this.alert = data;
                
			},
			error: (error)=>{
				
			}
		});
    }

    GetSavedJobs(){
		this._api.GET({
			api_route: 'api/favourites/v1/all',
			success: (data)=>{
                this.SavedJobs = data.Favourites;
                this._resources._savedJobs = data.Favourites;
                this._resources.SavedJobs.next(data);
			},
			error: (error)=>{
				
			}
		});
    }
    
    subscribeAlerts(){
        if (this.alert.email == "" || this.alert.email == null) {
			toastr.clear();
			toastr.error('kindly enter a valid email');
			return;
        }

        if (this.alert.phone == "" || this.alert.phone == null) {
			toastr.clear();
			toastr.error('kindly enter a valid phone');
			return;
        }
        
        if (!this.alert.email.includes("@")) {
			toastr.clear();
			toastr.error('kindly enter a valid email');
			return;
        }
        
        $('#job_alert_modal').modal('toggle');

        var url = 'api/job/alerts/v1/update';

		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.alert,
			success: (data)=>{
                toastr.ck
                toastr.success('Settings Updated Successfully');
			},
			error: (error)=>{
				
			}
		});
    }

	AddMediaFiles(page,element,previewElement,api,sending,callback,error,init){
        var self = this;
        
        var active = this.ActiveDropzones.find(x=>x.page == page && x.element == element);
        if(active!=null){
            toastr.clear();
            toastr.warning('File uploader is in use.');
            return;
        }

        $(element).each(function () {

            let dropzoneControl = $(this)[0].dropzone;
            if (dropzoneControl) {
                dropzoneControl.destroy();
            }
        });

        var id = "upload_"+new Date().getTime();

        var new_dropify = this.CreateDropify(element,
            previewElement,
            api,
            function (file, xhr, formdata) {
                // if($('#m_quicksearch_file').hasClass('m-dropdown--open')==false){
                //     $('#file_uploader_service_header')[0].click();
                // }
                self.ActiveDropzones.push({
                    id: 0,
                    page: page,
                    element: element
                });
                //var id = self.FileUploads.length+1;//filename,page,progress
                self.FileUploads.unshift({
                    id: id,
                    dropzone: new_dropify,
                    isUpload: true,
                    progress: 0,
                    page: page,
                    element: element,
                    filename: file.name,
                    link: self._router.url,
                    done:false,
                    size: self.humanFileSize(file.size,false),
                    extention: '.'+self.getExtension(file.name)
                });
                sending(file,xhr,formdata);
            },function(file, progress, bytesSent) {
                if (file.previewElement) {
                    
                    var fu = self.FileUploads.find(x=>x.id == id);
                    if(fu!=null){
                        $('#file_upload_progress_').css({'width': progress + '%'});
                        var prg = Math.round(progress*100)/100;
                        fu.progress = prg;
                    }
                }
            },
            function (res) {
                self.ActiveDropzones = self.ActiveDropzones.filter(x=>x.page!= page && x.element!= element);
                var fu = self.FileUploads.find(x=>x.id == id);
                if(fu!=null){
                    fu.done = true;
                    // $('#file_upload_spinner_'+fu.id).removeClass('m-loader');
                    // $("#file_upload_progress_"+fu.id).removeClass('m--bg-danger');
                    // $("#file_upload_progress_"+fu.id).addClass('m--bg-success');
                    fu.dropzone.destroy();
                }
                // if($('#m_quicksearch_file').hasClass('m-dropdown--open')==false){
                //     $('#file_uploader_service_header')[0].click();
                // }
                callback(res);
            },
            function (err){
                console.log('ErrUploads',err);
                self.ActiveDropzones = self.ActiveDropzones.filter(x=>x.page!= page && x.element!= element);
                var fu = self.FileUploads.find(x=>x.id == id);
                if(fu!=null){
                    fu.done = true;
                    //$('#file_upload_spinner_'+fu.id).removeClass('m-loader');
                    //$("#file_upload_progress_"+fu.id).removeClass('m--bg-danger');
                    //$("#file_upload_progress_"+fu.id).addClass('m--bg-success');
                    fu.dropzone.destroy();
                }
                error(err);
            });
        
        
        init();
    }

    OpenPage(q){
        this._router.navigate([q.link]);
    }

    CreateDropify(element,previewElement,api,sending,myProgress,callback,error){
        return new Dropzone(element, {
            init: function () {
                this.on("addedfile", function (file) {
                    var removeButton = Dropzone.createElement("<button class='btn btn-sm btn-block'>Remove file</button>");
                    
                    var _this = this;
                    removeButton.addEventListener("click", function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        _this.removeFile(file);
                    });

                    file.previewElement.appendChild(removeButton);
                });

                $('.dropzone .dz-message').css({ 'position': 'relative', 'margin-top': '100px' })
            },
            sending: sending,
            success: function (file, res) {
                var _this = this;
                _this.removeFile(file);
                callback(res);
            },
            uploadprogress: myProgress,
            error:error,
            clickable: true,
            url: this.appset.HURL + api,
            headers: this.appset.getDropZoneHeaders(),
            uploadMultiple: false,
            timeout: 3600000,
            parallelUploads: 1,
            paramName: "file",
            previewsContainer: previewElement, 
            dictDefaultMessage: ""
        });
    }

    getExtension(filename){
        return filename.split('.').pop();
    }

    humanFileSize(bytes, si) {
        var thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1)+' '+units[u];
    }

    MediaStatus(q){
        var status_text = 'file download';
        if(q.isUpload)status_text = 'file upload';

        var status_mes = 'is in progress';
        toastr.clear();
        if(q.done) {
            status_mes ='is completed. Check your downloads.';
            toastr.success('Your '+q.filename + ' ' + status_text + ' '+status_mes);
        }else{
            toastr.info('Your '+q.filename + ' ' + status_text + ' '+status_mes);
        }
    }

    downloadFile(url,method,filename,callback,body=null){
        var self = this;

        var id = "download_"+new Date().getTime();
        self.FileUploads.unshift({
            id: id,
            dropzone: null,
            isUpload: false,
            progress: 0,
            page: self._router.url,
            element: '',
            filename: filename,
            link: self._router.url,
            done:false,
            size: self.humanFileSize(0,false),
            extention: '.'+self.getExtension(filename)
        });

        if($('#m_quicksearch_file').hasClass('m-dropdown--open')==false){
            $('#file_uploader_service_header')[0].click();
        }
        self.ActiveDropzones.push({
            id: id,
            page: self._router.url,
            element: filename
        });


        let _headers = this.appset.getDownloadCommonHeaders();
        method = method.toUpperCase();

        const req = new HttpRequest(method, url, body, {
            reportProgress: true,
            responseType: 'blob',
            headers: _headers
        });
          
        this.httpClient.request(req).subscribe(result=>{
            if (result.type === HttpEventType.DownloadProgress) {
                const percentDone = Math.round(100 * result.loaded / result.total);
                var fu = self.FileUploads.find(x=>x.id == id);
                if(fu!=null){
                    $('#file_upload_progress_'+fu.id).css({'width': percentDone + '%'});
                    fu.progress = percentDone;
                }
            }
            if (result.type === HttpEventType.Response) {
                this.saveFileFinally(result.body,filename,callback,id);
            }
        });
                
    }

    private saveFileFinally(res, filename,callback,id){
        var self = this;
        self.ActiveDropzones = self.ActiveDropzones.filter(x=>x.id != id);
        var fu = self.FileUploads.find(x=>x.id == id);
        if(fu!=null){
            fu.done = true;
            fu.size = self.humanFileSize(res.size,false);
            $('#file_upload_spinner_'+fu.id).removeClass('m-loader');
            $("#file_upload_progress_"+fu.id).removeClass('m--bg-danger');
            $("#file_upload_progress_"+fu.id).addClass('m--bg-success');
        }
        if($('#m_quicksearch_file').hasClass('m-dropdown--open')==false){
            $('#file_uploader_service_header')[0].click();
        }

        var url = window.URL.createObjectURL(res);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        callback();
    }
}
