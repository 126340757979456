import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-saved-jobs',
	templateUrl: './saved-jobs.component.html',
	styleUrls: ['./saved-jobs.component.css']
})
export class SavedJobsComponent implements OnInit {

	saved_jobs: any = [];

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";
	savedJobs:any = [];

	_jobSearch: string = "";

	jobs:any = [];
	companies:any = [];

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this.savedJobs = this._resources._savedJobs;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
		});

		this._resources.AuthenticationStatus.subscribe(res => {
			this.savedJobs = this._resources._savedJobs;
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.getSavedJobs();
	}

	getSavedJobs() {
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/favourites/v1/all', success: (data) => {
				if (data != null) {
					//this.saved_jobs = data;
					this.jobs = data.Jobs;
					this.companies = data.Companies;
					
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		this.jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.fav = 'far fa-heart';

			if(j.job_type == 'Part-time')j.klass = 'part-time';
			else if(j.job_type == 'Full-time')j.klass = 'full-time';
			else if(j.job_type == 'Internship')j.klass = 'temporary';
			else j.klass = 'freelance';

			var c = this.companies.find(x=>x.id == j.company_id);
			if(c!=null){
				j.location = c.location;
				j.address = c.address;
				j.companyName = c.name;
			}

			// var fav = this.savedJobs.find(x=>x.job_id == j.id);
			// if(fav!=null){
			// 	j.fav = 'fas fa-heart text-danger';
			// }
		});
	}

	confirmRemoval(_job){
        var options = {
            title: "Remove Job from Favorites",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			
			if(e!=null){
				if(e==true){
					self.UpdateFavourites(_job);
				}
			}
        });
    }

	UpdateFavourites(_job){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to update your favourites.')
			this._router.navigate(['/login']);
			
			return;
		}
		console.log('Fired',_job);
		this._api.POST({
			load: true,
			body: _job,
			api_route: 'api/favourites/v1/update', success: (data) => {
				if (data != null) {
					
					this._resources.UpdateSavedJobs.next({});
					this.savedJobs = data;

					this.getSavedJobs();
				}
			},
			error: (error) => {
			}
		});
	}
}
