import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { NgxSocialShareModule } from 'ngx-social-share';
import {NgxSocialButtonModule, SocialServiceConfig} from 'ngx-social-button';
import { MagicSocialShareModule } from 'ngx-magic-social-share';
//import { CeiboShare } from 'ng2-social-share';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AccountModule } from './pages/account/account.module';
import { EmployerAccountModule } from './pages/account_employer/account_employer.module';
import { AccountRoutingModule } from './pages/account/account-routing.module';
import { AdminAccountRoutingModule } from './pages/account_admin/account-account-routing.module';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';

import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AccountActivateComponent } from './pages/account-activate/account-activate.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';

import { AccountComponent } from './pages/account/account.component';
import { EmployerAccountComponent } from './pages/account_employer/account_employer.component';
import { AdminAccountComponent } from './pages/account_admin/account_admin.component';

import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { JobCategoriesComponent } from './pages/job-categories/job-categories.component';
import { JobListingComponent } from './pages/job-listing/job-listing.component';
import { JobDetailsComponent } from './pages/job-details/job-details.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { EmployersComponent } from './pages/employers/employers.component';
import { EmployerDetailComponent } from './pages/employer-detail/employer-detail.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { TermsComponent } from './pages/terms/terms.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { PostNewsComponent } from './pages/post-news/post-news.component';
import { ResearchComponent } from './pages/research/research.component';

import { AlertsPromptsLoaders } from './_services/alerts.loaders';
import { AppSettingsUnilynq } from './_services/app-settings.service';
import { ApiService } from './_services/api.service';
import { SharedResources } from './_services/shared.resources';
import { PostJobComponent } from './pages/post-job/post-job.component';
import { EditJobDetailComponent } from './pages/edit-job-detail/edit-job-detail.component';
import { CandidateResumeComponent } from './pages/candidate-resume/candidate-resume.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { JobCategoryDetailsComponent } from './pages/job-category-details/job-category-details.component';
import { AdminAccountModule } from './pages/account_admin/account_admin.module';
import { AppraisalsComponent } from './pages/account_admin/appraisals/appraisals.component';
import { ExpresspayComponent } from './pages/payments/expresspay/expresspay.component';
import { ExpresspayAppraisalComponent } from './pages/payments/expresspay-appraisal/expresspay-appraisal.component';
import { PaymentsComponent } from './pages/account_admin/payments/payments.component';
import { JobStatusComponent } from './pages/account_admin/job-status/job-status.component';
import { AdminEditJobPostComponent } from './pages/admin-edit-job-post/admin-edit-job-post.component';
import { ChatMessengerApplicantComponent } from './pages/chat-messenger-applicant/chat-messenger-applicant.component';

//import { NgxFloatButtonModule } from 'ngx-float-button';
//import {CountdownTimerModule} from 'ngx-countdown-timer';
//import {CookieService} from 'ngx-cookie-service';

const appRoutes: Routes = [
	{ path: 'account/:page', loadChildren: './pages/account/account.module#AccountModule' },

	//{ path: 'admin-account/:page', loadChildren: './pages/account_admin/account_admin.module#AdminAccountModule' },

	{ path: 'login', component: LoginComponent },

	{ path: 'logout', component: LogoutComponent },

	{ path: 'register', component: RegisterComponent },

	{ path: 'reset', component: ForgotPasswordComponent },

	{ path: 'account-activation', component: AccountActivateComponent },

	{ path: 'home', component: HomeComponent },

	{ path: 'about', component: AboutComponent },

	{ path: 'testimonials', component: TestimonialsComponent },

	{ path: 'contact', component: ContactComponent },

	{ path: 'how-it-works', component: HowItWorksComponent },

	{ path: 'job-categories', component: JobCategoriesComponent },

	{ path: 'job-category-search', component: JobCategoryDetailsComponent },

	{ path: 'job-listing', component: JobListingComponent },

	{ path: 'job-detail/:id', component: JobDetailsComponent },

	{ path: 'employer-listing', component: EmployersComponent },

	{ path: 'employer-detail/:id', component: EmployerDetailComponent },

	{ path: 'candidate-detail/:job_id/:user_id', component: CandidateResumeComponent },

	{ path: 'insights', component: NewsComponent },

	{ path: 'news-detail/:id', component: NewsDetailComponent },

	{ path: 'terms-and-conditions', component: TermsComponent },

	{ path: 'resources', component:ResearchComponent },

	{ path: 'payment-status/expresspay/jobs', component: ExpresspayComponent },
	
	{ path: 'payment-status/expresspay/appraisals', component: ExpresspayAppraisalComponent },
	
	{ path: 'post-new-job', component: PostJobComponent },

	{ path: 'post-feed', component: PostNewsComponent },

	{ path: 'faq', component: FaqComponent },

	{ path: 'edit-job-detail/:id', component: EditJobDetailComponent },

	{ path: 'admin-edit-job-detail/:id', component: AdminEditJobPostComponent },

	{ path: 'messages', component: ChatMessengerApplicantComponent },
	
	{ path: '404', component: NotfoundComponent },

	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	
    { path: "**", redirectTo: "404", pathMatch: "full"
    }
];

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		PagesComponent,
		HomeComponent,
		LoginComponent,
		RegisterComponent,
		AccountActivateComponent,
		ForgotPasswordComponent,
		AccountComponent,
		AdminAccountComponent,
		EmployerAccountComponent,
		TestimonialsComponent,
		JobCategoriesComponent,
		JobListingComponent,
		JobDetailsComponent,
		AboutComponent,
		ContactComponent,
		EmployersComponent,
		EmployerDetailComponent,
		NewsComponent,
		NewsDetailComponent,
		NotfoundComponent,
		TermsComponent,
		LogoutComponent,
		PostJobComponent,
		PostNewsComponent,
		EditJobDetailComponent,
		CandidateResumeComponent,
		FaqComponent,
		HowItWorksComponent,
		JobCategoryDetailsComponent,
		ExpresspayComponent,
		ResearchComponent,
		ExpresspayAppraisalComponent,
		AdminEditJobPostComponent,
		ChatMessengerApplicantComponent,
		//CeiboShare
	],
	imports: [
		BrowserModule,
		RouterModule.forRoot(appRoutes),
		HttpModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FormsModule,
		AccountModule,
		EmployerAccountModule,
		AdminAccountModule,
		NgxSocialShareModule,
		MagicSocialShareModule,
		NgxSocialButtonModule,
		AngularEditorModule,
		//AngularCountdownTimerModule,
		//NgxFloatButtonModule
	],
	providers: [AppSettingsUnilynq,AlertsPromptsLoaders, 
		ApiService, SharedResources,
		//CookieService,
		{provide:SocialServiceConfig,
		useFactory: getAuthServiceConfigs},
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

export function getAuthServiceConfigs() {
    let config = new SocialServiceConfig()
        .addFacebook("1480852902106860")
        .addLinkedIn("789g2uvnxhwxmq");
    return config;
}

