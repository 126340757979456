import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from '../../helpers';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';
import {SocialService} from "ngx-social-button";

declare let $:any;
declare let toastr:any;

@Component({
	selector: 'app-job-listing',
	templateUrl: './job-listing.component.html',
	styleUrls: ['./job-listing.component.css']
})

export class JobListingComponent implements OnInit {

	//public repURL:'https://test.jobmatchgh.com'

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";
	savedJobs:any = [];

	_jobSearch: string = "";

	jobs:any = [];
	companies:any = [];
	selected_job:any = null;

	_filter:any = {
		newest: true,
        career_levels: [],
		job_types: [],
		experiences: [],
		genders: [],
		qualifications: [],
		from_date: '',
		to_date: '',
		min_salary: 0,
		max_salary: 0,
        page: 1,
		load: 25,
		search_by: 1
	};

	qualification:any = {};
	gender:any = {};
	experience:any = {};
	jobType:any = {};
	
	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
    TotalRequests:any = 1;
	
	shareObj={
		href:"http://localhost:4200",
		hashtag: "#JobMatch"
	};

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService,
		private socialAuthService: SocialService
	) { }

	ngOnInit() {
		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if(Id=='Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getMyJobs();
		});

		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this.savedJobs = this._resources._savedJobs;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
		});

		this._resources.SavedJobs.subscribe(res => {
			this.savedJobs = this._resources._savedJobs;
			this.RestructJobs();
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.getMyJobs();
	}

	OpenAlertSettings() {
		$('#job_alert_modal').modal('toggle');
	}

	getMyJobs() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/jobs/v1/searchJob', success: (data) => {
				if (data != null) {
					

					this.jobs = data.Jobs;
					this.companies = data.Companies;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.jobs.length;// (this._filter.page * this._filter.load);
					
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		
		this.jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.fav = 'far fa-heart';

			if(j.job_type == 'Part-time')j.klass = 'part-time';
			else if(j.job_type == 'Full-time')j.klass = 'full-time';
			else if(j.job_type == 'Internship')j.klass = 'temporary';
			else j.klass = 'freelance';

			var c = this.companies.find(x=>x.id == j.company_id);
			if(c!=null){
				j.location = c.location;
				j.address = c.address;
				j.companyName = c.name;
			}

			var fav = this.savedJobs.find(x=>x.job_id == j.id);
			if(fav!=null){
				j.fav = 'fas fa-heart text-danger';
			}
		});
	}

	SetOptions(){

		this._filter.job_types = [];

		if(this.jobType.fulltime) this._filter.job_types.push('Full-time');
		if(this.jobType.parttime) this._filter.job_types.push('Part-time');
		if(this.jobType.internship) this._filter.job_types.push('Internship');
		if(this.jobType.freelance) this._filter.job_types.push('Freelance');



		this._filter.experience = [];

		if(this.experience.nr) this._filter.experiences.push('None Required');
		if(this.experience.m6) this._filter.experiences.push('6 Months');
		if(this.experience.m9) this._filter.experiences.push('9 Months');
		if(this.experience.y1) this._filter.experiences.push('1 Year');
		if(this.experience.y2) this._filter.experiences.push('2 Years');
		if(this.experience.y3) this._filter.experiences.push('3 Years');
		if(this.experience.y4) this._filter.experiences.push('4 Years');
		if(this.experience.y5) this._filter.experiences.push('5 Years');



		this._filter.genders = [];

		if(this.gender.m) this._filter.genders.push('Male');
		if(this.gender.f) this._filter.genders.push('Female');
		if(this.gender.mf) this._filter.genders.push('Male or Female');



		this._filter.qualifications = [];

		if(this.qualification.bece) this._filter.qualifications.push('BECE Certificate');
		if(this.qualification.wassce) this._filter.qualifications.push('WASSCE Certificate');
		if(this.qualification.hnd) this._filter.qualifications.push('Diploma or HND');
		if(this.qualification.bd) this._filter.qualifications.push('Bachelors Degree');
		if(this.qualification.md) this._filter.qualifications.push('Masters Degree');
		if(this.qualification.phd) this._filter.qualifications.push('Doctorate Degree (PhD)');


		
		this._filter.NOFILTER = true;

		if(this._filter.from_date!='' && this._filter.from_date!=null)this._filter.NOFILTER = false;
		if(this._filter.to_date!='' && this._filter.to_date!=null)this._filter.NOFILTER = false;

		if(this._filter.job_types.length>0)this._filter.NOFILTER = false;
		if(this._filter.qualifications.length>0)this._filter.NOFILTER = false;
		if(this._filter.experiences.length>0)this._filter.NOFILTER = false;
		if(this._filter.genders.length>0)this._filter.NOFILTER = false;

		if(this._filter.min_salary>0)this._filter.NOFILTER = false;
		if(this._filter.max_salary>0)this._filter.NOFILTER = false;
	}

	Next(){
		console.log('filter',this._filter);
		console.log('pages',this.TotalPages);
        if(this._filter.page<this.TotalPages){
            this._filter.page+=1;
            this.getMyJobs();
        }
    }

    Prev(){
        if(this._filter.page>1){
            this._filter.page-=1;
            this.getMyJobs();
        }       
	}
	
	ApplyFilter(){
        this._filter.page = 1;
		this.getMyJobs();    
	}
	
	UpdateFavourites(_job){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to update your favourites.')
			this._router.navigate(['/login']);
			
			return;
		}

		this._api.POST({
			load: true,
			body: _job,
			api_route: 'api/favourites/v1/update', success: (data) => {
				if (data != null) {
					
					this._resources.UpdateSavedJobs.next({});
					this.savedJobs = data;

					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}
}
