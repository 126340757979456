import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';

declare let $:any;
declare let toastr:any;

@Component({
	selector: 'app-access',
	templateUrl: './access.component.html',
	styleUrls: ['./access.component.css']
})

export class AccessComponent implements OnInit {

	access:any = {
		AgentRequests: [],
		JobGiverRequests: [],
		bg_agent: 'bg-primary',
		bg_job_giver: 'bg-dark',
		text_agent: 'text-dark',
		text_job_giver: 'text-primary',
	};

	user:any = {
		is_agent: false,
		is_job_giver: false
	}

	job_giver:any = {};
	agent:any = {};


	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.getUserAccessDetail();
	}

	getUserAccessDetail() {

		this._api.GET({
			load: true,
			api_route: 'api/user/v2/access_features', success: (data) => {
				if (data != null) {
					this.access.AgentRequests = data.AgentRequests;
					this.access.JobGiverRequests = data.JobGiverRequests;
					this.user = data.User;
					this.RestructData();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructData(){
		if(this.user.is_agent){
			this.access.bg_agent = 'bg-success';
			this.access.text_agent = 'text-dark';
		}

		if(this.user.is_job_giver){
			this.access.bg_job_giver = 'bg-info';
			this.access.text_job_giver = 'text-dark';
		}

		this.access.AgentRequests.forEach(j => {
			j._last_modified = Helpers.fromNow(j.last_modified);
			j._date = Helpers.longDateTime(j.last_modified);
			j._status = this.GetJobStatus(j.status);
			j.status = j.status;
			j.klass = this.GetJobStatusClass(j.status);
			
			var c = this.user;
			if(c!=null){
				j.gender = c.gender;
				j.image = c.image;
				j.firstName = c.firstName;
				j.lastName = c.lastName;
				j.email = c.email;
			}
		});

		this.access.JobGiverRequests.forEach(j => {
			j._last_modified = Helpers.fromNow(j.last_modified);
			j._date = Helpers.longDateTime(j.last_modified);
			j._status = this.GetJobStatus(j.status);
			j.status = j.status;
			j.klass = this.GetJobStatusClass(j.status);
			
			var c = this.user;
			if(c!=null){
				j.gender = c.gender;
				j.image = c.image;
				j.firstName = c.firstName;
				j.lastName = c.lastName;
				j.email = c.email;
			}
		});
	}


	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Approved';
		if(status_id == 3) status = 'Denied';
		if(status_id == 4) status = 'Passive Denial'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'badge-success';
		if(status_id == 3) status = 'badge-danger';
		if(status_id == 4) status = 'badge-primary'; 

		return status;
	}

	openJobGiverRequest(){
		$('#job_giver_request_modal').modal('toggle');
	}

	sendJobGiverRequest(){
		$('#job_giver_request_modal').modal('toggle');

		toastr.clear();
		toastr.info('sending feature request, please wait...');
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/v2/job_giver_apply',
			body: this.job_giver,
			success: (data)=>{
				this.access.JobGiverRequests.push(data);
				this.RestructData();

				toastr.clear();
				toastr.success('request has been sent successfully');
			},
			error: (error)=>{
				
			}
		});
	}

	openAgentRequest(){
		$('#agent_request_modal').modal('toggle');
	}

	sendAgentRequest(){
		$('#agent_request_modal').modal('toggle');

		toastr.clear();
		toastr.info('sending feature request, please wait...');
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/v2/agent_apply',
			body: this.agent,
			success: (data)=>{
				this.access.AgentRequests.push(data);
				this.RestructData();

				toastr.clear();
				toastr.success('request has been sent successfully');
			},
			error: (error)=>{
				
			}
		});
	}


	confirmDeleteAgentRequest(_agentRequest){
        var options = {
            title: "Remove Agent Request",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deleteAgentRequestPermanently(_agentRequest);
				}
			}
        });
    }


    deleteAgentRequestPermanently(_agentRequest){
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/v2/delete_agent_request',
			body: _agentRequest,
			success: (data)=>{
				this.access.AgentRequests = this.access.AgentRequests.filter(x=>x.id!=_agentRequest.id);
				
				this.alertService.alertType("Agent Feature Request Removed",
					'You have successfully removed feature request.', "success");
			},
			error: (error)=>{
				
			}
		});
	}


	confirmDeleteRecruiterRequest(_recruiter){
        var options = {
            title: "Remove Recruiter Request",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deleteRecruiterRequestPermanently(_recruiter);
				}
			}
        });
    }


    deleteRecruiterRequestPermanently(_recruiter){
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/v2/delete_job_giver_request',
			body: _recruiter,
			success: (data)=>{
				this.access.JobGiverRequests = this.access.JobGiverRequests.filter(x=>x.id!=_recruiter.id);
				
				this.alertService.alertType("Recruiter Feature Request Removed",
					'You have successfully removed feature request.', "success");
			},
			error: (error)=>{
				
			}
		});
	}
}
