import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../_services/api.service';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';

@Component({
	selector: 'app-account-activate',
	templateUrl: './account-activate.component.html',
	styleUrls: ['./account-activate.component.css']
})
export class AccountActivateComponent implements OnInit {
	user: any = {
		id: ''
	};

	_USER:any = {};
	Activated:boolean = false;

	constructor(
		private _api: ApiService,
		private route: ActivatedRoute,
		private alertService: AlertsPromptsLoaders
	) { }

	ngOnInit() {
		this.user.id = this.route.snapshot.queryParams['ref'] || '';

		this._api.POST({
			api_route: 'api/Account/Activate',
			body: this.user,
			load: true,
			success: (data) => {
				this.Activated = true;
				
				this._USER = data;
				this.alertService.alertType("Account Activated Successfully",
					'Kindly proceed to login in to your account.', "success");
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

}
