import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Routes, RouterModule, Router,ActivatedRoute } from '@angular/router';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { CompaniesComponent } from './companies/companies.component';
import { AgentsComponent } from './agents/agents.component';
import { RecruitersComponent } from './recruiters/recruiters.component';
import { AppraisalsComponent } from './appraisals/appraisals.component';
import { CategoriesComponent } from './categories/categories.component';
import { RolesComponent } from './roles/roles.component';
import { PaymentsComponent } from './payments/payments.component';
import { JobStatusComponent } from './job-status/job-status.component';

import { SharedResources } from '../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { Helpers } from '../../helpers';
import { ApiService } from '../../_services/api.service';
import { UsersComponent } from './users/users.component';
import { Subscription } from 'rxjs';

declare let $:any;
declare let toastr:any;

@Component({
	selector: 'app-account-admin',
	templateUrl: './account_admin.component.html',
	styleUrls: ['./account_admin.component.css']
})
export class AdminAccountComponent implements OnInit, OnDestroy {

	activeCategories = ['Dashboard'];
	activePage:any = 'Dashboard';

	company_id:any = 0;

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	ID:any = 0;
	bag:any = null;
	Company:any = {};
	company_jobs:any = [];

	other_jobs:any = [];

	user_modules:any = []; 

	my:any = {
		pin: ''
	};

	pinConfirmed:boolean = false;

	Access:any = {
		dashboard: false,
		agents: false,
		recruiters: false,
		roles: false,
		categories: false,
		appraisals: false,
		payments: false,
		comapnies: false,
		jobs:false,
		userAccounts:false
	};

	@ViewChild('componentcontainer', { read: ViewContainerRef })
	entry: ViewContainerRef;

	subscriptions:Subscription[] = [];

	constructor(
		private resolver: ComponentFactoryResolver,
		public appset: AppSettingsUnilynq,
		private _router: Router,
		private alertService: AlertsPromptsLoaders,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;

		this._resources.UpdateAuthentication.next({});
		var subscription = this._resources.AuthenticationStatus.subscribe(res=>{
			
			if(!this._resources._isAuthenticated){
				this._router.navigate(['/login']);
				return;
			}

			if(this._resources._user==null)return;

			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			this.getUserModules();
		});

		this.subscriptions.push(subscription);
		
    	if(this.user!=null){
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		$('#confirm_password_modal').modal('toggle');
	}

	ngOnDestroy(){
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}

	InitPage(){
		this.activePage=this.route.snapshot.paramMap.get("page");
		
		if(this.activePage == 'agents'){
			this.CreateComponentAgents();
		} else if(this.activePage == 'recruiters'){
			this.CreateComponentRecruiters();
		} else if(this.activePage == 'other-companies'){
			this.CreateComponentCandidates();
		} else if(this.activePage == 'appraisals'){
			this.CreateComponentAppraisals();
		} else if(this.activePage == 'categories'){
			this.CreateComponentCategories();
		} else if(this.activePage == 'roles'){
			this.CreateComponentRoles();
		} else if(this.activePage == 'payments'){
			this.CreateComponentPayments();
		} else if(this.activePage == 'jobs'){
			this.CreateComponentJobStatus();
		} else if(this.activePage == 'user-accounts'){
			this.CreateComponentUserAccounts();
		} else {
			this.createComponentDashboard();
		}
		this.getUserModules();
		//this.getEmployerDetail();	
	}

	ReturnActiveClass(cate){
        for(var i=0;i<this.activeCategories.length;i++){
            if(this.activeCategories[i]==cate) return 'active';
        }
        return '';
    }

	createComponentDashboard() {
		this.activeCategories = ['Dashboard'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(DashboardNewComponent);
		//const factory = this.resolver.resolveComponentFactory(DashboardComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentAgents() {
		this.activeCategories = ['Agents'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(AgentsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentRecruiters(){
		this.activeCategories = ['Recruiters'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(RecruitersComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentAppraisals(){
		this.activeCategories = ['Appraisals'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(AppraisalsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentCategories(){
		this.activeCategories = ['Categories'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(CategoriesComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentRoles(){
		this.activeCategories = ['Roles'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(RolesComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentPayments(){
		this.activeCategories = ['Payments'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(PaymentsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentJobStatus(){
		this.activeCategories = ['JobStatus'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(JobStatusComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentUserAccounts() {
		this.activeCategories = ['UserAccounts'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(UsersComponent);
		const componentRef = this.entry.createComponent(factory);
	}
	
	CreateComponentCandidates() {
		this.activeCategories = ['other-companies'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(CompaniesComponent);
		const componentRef = this.entry.createComponent(factory);
	}


	UploadImage(){
		
		var api = "api/user/company/v1/change_image/"+this.company_id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Company Dashboard Files',
            e: '#m-dropzone-one-author',
            pe: '#honeycomb-author',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Dashboard-Company-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
				toastr.success('File upload successful');
				
				if(self.Company!=null){
					self.Company.image = res.image;
				}
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Dashboard-Company-modal').modal('toggle');
            }
        });
	}

	getEmployerDetail() {
		//var job_id = this._route.snapshot.queryParams['ref'] || '0';
		var employer_id = this.route.snapshot.paramMap.get("company_id");;

		var URL = 'api/company/search/v1/detail/'+employer_id;
		if(this._resources._isAuthenticated){
			URL = 'api/company/search/v1/detail_auth/'+employer_id;
		}

		this._api.GET({
			load: true,
			showError: true,
			api_route: URL, success: (data) => {
				this.bag = data;
				if (data != null) {
					this.Company = data.Company;
					this.company_jobs = data.Job;
					this.other_jobs = data.OtherJobs;
					if(this.Company!=null){
						this.ID = this.Company.id;
						this.Company._date = Helpers.longShortDate(this.Company.date);
					}
					this.RestructJobs();
				}
			},
			error: (error) => {

			}
		});
	}

	RestructJobs(){
		
		this.company_jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.fav = 'far fa-heart';

			if(j.job_type == 'Part-time')j.klass = 'part-time';
			else if(j.job_type == 'Full-time')j.klass = 'full-time';
			else if(j.job_type == 'Internship')j.klass = 'temporary';
			else j.klass = 'freelance';
		});
	}

	ConfirmPassword(){
		this._api.POST({
			load: true,
			body: this.my,
			api_route: 'api/user/v2/confirm_password', success: (data) => {
				if (data != null) {
					toastr.clear();
					toastr.success('Password confirmed!');

					$('#confirm_password_modal').modal('toggle');
					this.pinConfirmed = true;
					this.InitPage();
				}
			},
			error: (error) => {
				toastr.clear();
				toastr.error('Invalid user password, try again!');
			}
		});
	}

	openProfile(){
		$('#confirm_password_modal').modal('toggle');
		this._router.navigate(['/account/profile']);
	}

	getUserModules() {

		this._api.GET({
			api_route: 'api/user/v2/assigned_roles', 
			success: (data) => {
				if (data != null) {
					this.user_modules = data;

					if(this.user.access_level != 979 && data.length==0){
						toastr.info('You need admin privileges to access the page.');
						this._router.navigate(['/account/access']);
						this._resources.AlertPageChange.next({});
					};

					this.RestructModules();
				}
			},
			error: (error) => {
			}
		});
	}

	OpenUserModules(){
		this.getUserModules();
		$('#check_user_modules_modal').modal('toggle');
	}

	RestructModules(){
		if(this.user.access_level==979){
			this.Access = {
				dashboard: true,
				agents: true,
				recruiters: true,
				roles: true,
				categories:true,
				appraisals: true,
				companies:true,
				payments:true,
				jobs:true,
				userAccounts:true,
			};
		}else{
			this.user_modules.forEach(mod => {
				if(mod.module == 'Manage Dashboard')this.Access.dashboard = true;
				if(mod.module == 'Manage Agents')this.Access.agents = true;
				if(mod.module == 'Manage Job Creators')this.Access.recruiters = true;
				if(mod.module == 'Manage Roles')this.Access.roles = true;
				if(mod.module == 'Manage Categories & Skills')this.Access.categories = true;
				if(mod.module == 'Manage Appraisal Requests')this.Access.appraisals = true;
				if(mod.module == 'Manage Application Payments')this.Access.payments = true;
				if(mod.module == 'Manage Jobs (Candidates & Statuses)')this.Access.jobs = true;
				if(mod.module == 'Manage User Accounts')this.Access.userAccounts = true;

			});
		}
	}
}
