import { Component, OnInit } from '@angular/core';
import { SharedResources } from '../../_services/shared.resources';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  isAuthenticated: boolean = false;
	user: any = null;

	constructor(
		private _resources: SharedResources
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		console.log('Authentication', this.isAuthenticated);
		console.log('user', this.user);
	}

}
