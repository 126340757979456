import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppSettingsUnilynq} from '../../_services/app-settings.service';
import { Headers, Http, RequestOptions, Response, ResponseContentType } from "@angular/http";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { ApiService } from '../../_services/api.service';
import { SharedResources } from '../../_services/shared.resources';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {

  subscribeData:any=<any>{};
  _filter: any ={
    EMAIL: 'martha.teye99@gmail.com',
    b_19cc12fa20447a00d62730be0_07b32fd092: ""};
  
  resp:any =[];
  

  constructor(
    private subscribeService:AppSettingsUnilynq,
    private http:Http,
    private _api: ApiService,
		private _router: Router,
		public appset: AppSettingsUnilynq,
		private httpClient:HttpClient,
		private _resources: SharedResources
  ) { }

  ngOnInit() {
  }
  subscribe(_filter){
    let headerss = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
  
      //Helpers.setLoading(true);
  
      let b = 'EMAIL=' + "martha.teye999@gmail.com" + '&b_19cc12fa20447a00d62730be0_07b32fd092=' + '';
      this.http.post(this.appset.mailChimpEndpoint, b, { headers: headerss }).pipe(map((response) => response.json())).subscribe(data => {
        console.log("new", data);
        //Helpers.setLoading(false);
      }, error => {
        
        //this.alertService.alertType("Authentication Failed","Invalid Username or Password!","error");
        console.log('ERROR', error);
        //Helpers.setLoading(false);
      });
  
      }

}
