import { Component, OnInit } from '@angular/core';
import { SharedResources } from '../../../_services/shared.resources';
import { ApiService } from '../../../_services/api.service';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';

@Component({
	selector: 'app-my-resume',
	templateUrl: './my-resume.component.html',
	styleUrls: ['./my-resume.component.css']
})
export class MyResumeComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";
	education: any = [];
	awards: any = [];
	workexperience: any = [];
	skills: any =[];
	resume: any = {};
	edu: any = [];
	wrk: any = [];
	awd: any = [];
	skl: any =[];


	constructor(private _resources: SharedResources,
	public appset: AppSettingsUnilynq,
	private _api:ApiService,
	private alertService:AlertsPromptsLoaders){ }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;
			
			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.getResume();
		
	}

	getResume(){
		this._api.GET({
			api_route: 'api/resume/v1/resume', success: (data)=>{
				if (data.myresume!=null){
					this.resume = data.myresume;
					this.ParseResume();	
				}
			},
			error: (error)=>{
			}
		});
	}

	ParseResume(){
		this.education = JSON.parse(this.resume.education);
		console.log(this.education);
		this.awards = JSON.parse(this.resume.awards);
		this.workexperience = JSON.parse(this.resume.workexperience);
		this.skills = JSON.parse(this.resume.skills);
	}

	


}
