import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';

import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
  selector: 'app-agent-appraisal',
  templateUrl: './agent-appraisal.component.html',
  styleUrls: ['./agent-appraisal.component.css']
})
export class AgentAppraisalComponent implements OnInit {

	company:any = {};
	regions:any = [];
	cities:any = [];

	companies:any = [];
	selected_company:any = null;

	isNew:boolean = true;

	_companySearch:any = '';
	categories: any = [];

	company_id:any = 0;

	_filter:any = {
		newest: true,
        status_ids: [],
		genders: [],
		search: '',
		start_date: '',
		end_date: '',
        page: 1,
		load: 15,
		search_by: 1
	};

	agent_filter:any = {
		newest: true,
        email: '',
        page: 1,
		load: 250,
		search_by: 1
	};

	status_ids:any = {
		pending: true,
		assigned: true,
		completed: true,
	};

	agents:any = [];
	responseCounts:any = [];


	appraisals:any = [];
	jobs:any = [];
	responses:any = [];
	users:any = [];

	selected_appraisal:any = null;

	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
	TotalRequests:any = 1;

	statuses:any = [{id: 1, name: 'Pending'},{id: 2, name: 'Assigned'},{id: 3, name: 'Appraisal Complete'}];

	summary:any = {
		Pending: 0,
		Approved: 0,
		Denied: 0,
		PassiveDenial: 0
	};

	firstLoad:boolean = true;
	skills: any = [];
	prev_skills:any = [];
	selected_category: any = {
		skills: []
	};
	response:any = {
		currency: 'GHS',
		min_salary: 0,
		max_salary: 0,
		categoryId: 0,
		category: '',
		skills: []
	};

	_appraisalSearch:string = '';

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {

		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if(Id=='Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getAppraisals();
		});

		this._resources.UpdateAuthentication.next({});
		this.getCategory();

		this.getAppraisalSummary();
		this.getAppraisals();
		

		var self = this;
		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			self.response.categoryId = Id;
			if(!self.firstLoad){
				self.ReloadSkills(Id);
			}
		});

		$('#skill_select').on('change', (e) => {
			var SSs = $(e.target).val();
			self.response.skills = SSs;
		});

		$('#currency').on('change', (e) => {
			var Currency = $(e.target).val();
			self.response.currency = Currency;
		});
	}

	
	getMyAgents(){

	}
	confirmPayment(){

	}
	getAppraisalSummary() {

		this._api.GET({
			api_route: 'api/agent/appraisal/v1/summary', success: (data) => {
				if (data != null) {
					this.summary = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getAppraisals() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/agent/appraisal/v1/requests', success: (data) => {
				if (data != null) {
					
					this.appraisals = data.Requests;
					this.responses = data.AppraisalResponses;
					this.jobs = data.Jobs;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.appraisals.length;// (this._filter.page * this._filter.load);
					
					this.RestructAppraisals();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructAppraisals(){
		
		this.appraisals.forEach(j => {
			
			j._date = Helpers.longDateTime(j.date);
			j._last_modified = Helpers.fromNow(j.last_modified);
			j._status = this.GetJobStatus(j.status);
			j.klass = this.GetJobStatusClass(j.status);
			j.job = this.jobs.find(x=>x.id == j.job_id);
			j.response = this.responses.find(x=>x.appraisal_id == j.id);
			j._response = {};
			j._skills = [];
			j.categoryName = '';

			if(j.response!=null){
				j.response._last_modified = Helpers.fromNow(j.response.last_modified);
				this.ReturnResponseSkills(j.response);
				
				j._skills = j.response._skills;
				j.categoryName = j.response.categoryName;
				j._response = j.response._response;
				//j._min_salary = Helpers.formatMoney(j.response._response.min_salary,0,'.',',');
				//j._max_salary = Helpers.formatMoney(j.response._response.max_salary,0,'.',',');
				//j.currency = j.response._response.currency;
				//j.description = j.response._response.description;
			}
			if(j.job!=null){
				j.jobTitle = j.job.jobTitle;
				j.description = j.job.description;
				j.companyName = j.job.companyName;

				this.JobRestruct(j.job);
			}
		});
	}

	ReturnResponseSkills(AppraisalResponse){
		
		var categoryName = '';
		var _skills = [];
		var response = JSON.parse(AppraisalResponse.response);
		AppraisalResponse._response = response;
		var category = this.categories.find(x => x.id == response.categoryId);

		var skill_ids = response.skills;
		if(category!=null){
			categoryName = category.categoryName;
			
			if(skill_ids!=null){
				skill_ids.forEach(skill_id => {
					var skill = category.skills.find(x=>x.id == skill_id);
					if(skill!=null){
						_skills.push(skill);
					}
				});
			}
		}

		AppraisalResponse.categoryName = categoryName;
		AppraisalResponse._skills = _skills;
	}

	JobRestruct(_job){
		_job.descriptions = _job.description.split('/n');
		_job._date = Helpers.fromNow(_job.created_at);
		_job._expire_at = Helpers.longShortDate(_job.expire_at);
		_job._additions = JSON.parse(_job.additional_notes);
		_job._skills = JSON.parse(_job.skillId);
		_job._expires = Helpers.fromNow(_job.expire_at);

		_job._min = Helpers.formatMoney(_job.min_salary,0,'.',',');
		_job._max = Helpers.formatMoney(_job.max_salary,0,'.',',');
	}

	SetOptions(){

		this._filter.status_ids = [];

		if(this.status_ids.pending) this._filter.status_ids.push(1);
		if(this.status_ids.assigned) this._filter.status_ids.push(2);
		if(this.status_ids.completed) this._filter.status_ids.push(3);

		this._filter.NOFILTER = true;
		if(this._filter.start_date!='' && this._filter.end_date!=null)this._filter.NOFILTER = false;

		if(this._filter.status_ids.length>0)this._filter.NOFILTER = false;
		
	}

	Next(){
        if(this._filter.page<this.TotalPages){
            this._filter.page+=1;
            this.getAppraisals();
        }
    }

    Prev(){
        if(this._filter.page>1){
            this._filter.page-=1;
            this.getAppraisals();
        }       
	}
	
	ApplyFilter(){
        this._filter.page = 1;
		this.getAppraisals();    
	}

	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Assigned';
		if(status_id == 3) status = 'Completed'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'bg-dark';
		if(status_id == 3) status = 'badge-info'; 

		return status;
	}


	getCategory() {
		this._api.GET({
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
					this.RestructAppraisals();
				}
			},
			error: (error) => {
			}
		});
	}

	OpenAppraisalResponse(_R){
		var _appraisal = _R.response, job = _R.job;

		this.selected_appraisal = _appraisal;
		this.selected_appraisal.job = job;
		this.SetSkills();

		var j = JSON.parse(_appraisal.response);
		this.response.min_salary = j.min_salary;
		this.response.max_salary = j.max_salary;
		this.response.currency = j.currency;
		this.response.description = j.description;

		this.response.category = j.category;
		this.response.skills = j.skills;

		this.firstLoad = true;

		$('#currency').val(this.response.currency).trigger('change');
		$('#minimount').val(this.response.min_salary);
		$('#maximount').val(this.response.max_salary);
		$('#catSelect').val(j.categoryId).trigger('change');
		
		if(j.categoryId==null){	
			this.ReloadSkills(_appraisal.job.categoryId);
		}else{
			this.ReloadSkills(j.categoryId);
		}
		
		$('#edit_appraisal_modal').modal('toggle');
	}

	SetSkills(){
		this.prev_skills = [];
		var category = this.categories.find(x => x.id == this.selected_appraisal.job.categoryId);
		var j = this.selected_appraisal.job;
		var skill_ids = JSON.parse(j.skillId);
		if(category!=null){
			skill_ids.forEach(skill_id => {
				var skill = category.skills.find(x=>x.id == skill_id);
				if(skill!=null){
					this.prev_skills.push(skill);
				}
			});
		}
	}

	OpenSelection() {
		
	}


	ReloadSkills(id) {
		var self = this;
		self.response.categoryId = id;
		
		var _skills = [];

		var category = this.categories.find(x => x.id == id);
		if (category != null) {
			
			self.selected_category = category;
			self.response.category = category.categoryName;

			category.skills.forEach(_skill => {
				_skills.push({
					id: _skill.id,
					text: _skill.skillName
				});
			});

			if(!self.firstLoad){
				$('#skill_select').select2('destroy');
				$('#skill_select').empty().trigger("change");
			}
			
			setTimeout(function () {

				
				$("#skill_select").select2({
					placeholder: 'select skill',
					data: _skills
				});
				
				//$("#skill_select").val([]).trigger('change');
				
				if(self.firstLoad){
					self.firstLoad = false;
					$("#skill_select").val(self.response.skills).trigger('change');
				}else{
					$("#skill_select").val([]).trigger('change');	
				}
			}, 500);
		}
	}

	SaveAppraisalResponse(){
		toastr.clear();
		toastr.info('changes saved successfully');

		this.selected_appraisal.response = JSON.stringify(this.response);

		this._api.POST({
			load: true,
			body: this.selected_appraisal,
			showError: true,
			api_route: 'api/agent/appraisal/v1/respond', success: (data) => {
				if (data != null) {
					var _res = this.responses.find(x=>x.id == data.id);
					if(_res!=null){
						_res.response = data.response;
						_res.last_modified = data.last_modified;
						this.RestructAppraisals();
					}
					toastr.clear();
					toastr.success('Changes saved successfully');
				}
			},
			error: (error) => {
			}
		});
	}

}
