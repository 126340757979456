import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../helpers';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { Router } from '@angular/router';
import { ApiService } from '../../_services/api.service';

declare let toastr:any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  password: string = "";
	email: string = "";

	isAuthenticated: boolean = false;
	user: any = null;

	constructor(
		private _api: ApiService,
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private resources: SharedResources
	) { }

	ngOnInit() {
		this.isAuthenticated = this.resources._isAuthenticated;
		this.user = this.resources._user;

		this.resources.UpdateAuthentication.next({});
		
		console.log('Authentication', this.isAuthenticated);
		console.log('user', this.user);
	}

	login() {

		if (this.email == '') {
			toastr.clear();
			toastr.warning('Email cannot be empty or null!');
			return;
		}

		var body = {
			email: this.email
		};
		var url = 'api/user/v2/forgot_password';
		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: body,
			success: (data)=>{
				
				toastr.clear();
				toastr.success('Password Reset is successful. Kindly check your email');

				this.alertService.alertType("Check your email",
					'Kindly Check your email for your login account information.', "success");
			},
			error: (error)=>{
				
			}
		});

	}

}
