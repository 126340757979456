import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../../helpers';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
  selector: 'app-employer-detail',
  templateUrl: './employer-detail.component.html',
  styleUrls: ['./employer-detail.component.css']
})
export class EmployerDetailComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	Company:any = {};
	company_jobs:any = [];

	other_jobs:any = [];

	message:any = {};

	bag:any = null;
	_company:any = null;
	similar_jobs:any = [];
	_job:any = null;
	_applicant:any = null;
	_saved:any = 'far fa-heart';
	descriptions:any = [];

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _route: ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			this.getEmployerDetail();
		});

		this.getEmployerDetail();
	}

	getEmployerDetail() {
		//var job_id = this._route.snapshot.queryParams['ref'] || '0';
		var employer_id =this._route.snapshot.paramMap.get("id");
		if(!employer_id){
			this._router.navigate(['/home']);
		}

		var URL = 'api/company/search/v1/detail/'+employer_id;
		if(this._resources._isAuthenticated){
			URL = 'api/company/search/v1/detail_auth/'+employer_id;
		}

		this._api.GET({
			load: true,
			showError: true,
			api_route: URL, success: (data) => {
				this.bag = data;
				if (data != null) {
					this.Company = data.Company;
					this.company_jobs = data.Job;
					this.other_jobs = data.OtherJobs;
					if(this.Company!=null){
						this.Company._date = Helpers.longShortDate(this.Company.date);
					}
					this.RestructJobs();
				}
			},
			error: (error) => {

			}
		});
	}

	RestructData(){
		this.descriptions = this._job.description.split('/n');
		this._job._date = Helpers.fromNow(this._job.created_at);
		this._job._expire_at = Helpers.longShortDate(this._job.expire_at);
		this._job._additions = JSON.parse(this._job.additional_notes);
		this._job._skills = JSON.parse(this._job.skillId);
		this._job._expires = Helpers.fromNow(this._job.expire_at);

		this._job._min = Helpers.formatMoney(this._job.min_salary,0,'.',',');
		this._job._max = Helpers.formatMoney(this._job.max_salary,0,'.',',');

		var c = this.bag.Company;
		this._company = c;
		if(c !=null){
			this._job.location = c.location;
			this._job.address = c.address;
			this._job.companyName = c.name;
			this._job.phone = c.phone;
		}
	}

	RestructJobs(){
		
		this.company_jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.fav = 'far fa-heart';

			if(j.job_type == 'Part-time')j.klass = 'part-time';
			else if(j.job_type == 'Full-time')j.klass = 'full-time';
			else if(j.job_type == 'Internship')j.klass = 'temporary';
			else j.klass = 'freelance';
		});
	}

	confirmJobApplication(){
        var options = {
            title: "Apply for Job",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Apply!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			
			if(e!=null){
				if(e==true){
					self.ApplyForJob();
				}
			}
        });
    }

	ApplyForJob(){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to apply for the Job.')
			this._router.navigate(['/login']);
			
			return;
		}

		this._api.POST({
			load: true,
			showError: true,
			body: this._job,
			api_route: 'api/apply/v1/new', success: (data) => {
				this._applicant = data;
				this.alertService.alertType("Job Applied Successfully",
					'You have been added to the applicants for the selected Job Post.', "success");
			},
			error: (error) => {

			}
		});
	}

	UpdateFavourites(_job){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to update your favourites.')
			this._router.navigate(['/login']);
			
			return;
		}

		this._api.POST({
			load: true,
			body: _job,
			api_route: 'api/favourites/v1/update', success: (data) => {
				if (data != null) {
					

					this._resources.UpdateSavedJobs.next({});
					this.getEmployerDetail();
				}
			},
			error: (error) => {
			}
		});
	}

	SendEmail(){
		if(this.message.name =='' || this.message.name ==null){
			toastr.clear();
			toastr.warning('Name cannot be empty or null');
			return;
		}

		if(this.message.email =='' || this.message.email ==null){
			toastr.clear();
			toastr.warning('Email cannot be empty or null');
			return;
		}

		if(this.message.subject == '' || this.message.subject ==null){
			toastr.clear();
			toastr.warning('Subject cannot be empty or null');
			return;
		}

		if(this.message.message == '' || this.message.message ==null){
			toastr.clear();
			toastr.warning('Message cannot be empty or null');
			return;
		}

		this.message.company_id = this.Company.id;

		this._api.POST({
			load: true,
			showError: true,
			body: this.message,
			api_route: 'api/company/search/v1/send_message', success: (data) => {
				this._applicant = data;
				this.alertService.alertType("Email Sent",
					'Your message has been sent successfully to the employer.', "success");
			},
			error: (error) => {

			}
		});
	}
}
