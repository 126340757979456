import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-post-job',
	templateUrl: './post-job.component.html',
	styleUrls: ['./post-job.component.css']
})
export class PostJobComponent implements OnInit {

	JOB_POST_SUCCESSFUL: boolean = false;
	_job: any = null;

	job: any = {
		currency: "GHS",
		gender: 'Male',
		job_type: 'Full-time',
		career_level: 'Entry Level',
		experience: '6 Months',
		qualification: 'BECE Certificate',
		skillId: '[]',
		alert: true,
		other: ''
	};
	jobs: any = {};
	isNew: boolean = false;

	categories: any = [];
	catnskills: any = {};
	skills: any = [];
	mySkills: any = [];
	categoryId: number = 0;


	selected_category: any = {
		skills: []
	};

	companies:any = [];
	_company:any = {};

	_additions:any = [];
	_note:any = '';

	temp_files:any = [];

	constructor(
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	showDescription(){
		//console.log(this.job.description);
	}

	ngOnInit() {

		this._resources.UpdateAuthentication.next({});
		this._resources.AuthenticationStatus.subscribe(res=>{
			
			if(this._resources._user==null)return;

			var user = this._resources._user;

			if(!user.is_job_giver){
				toastr.info('You need to request recruitment feature to post jobs.');
				this._router.navigate(['/account/access']);
				this._resources.AlertPageChange.next({});
			};
		});

		this.getTempFiles();
		this.getCategory();
		this.getCompanies();
		

		var self = this;
		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			self.categoryId = Id;
			self.ReloadSkills(Id);
		});

		$('#skill_select').on('change', (e) => {
			var SSs = $(e.target).val();
			self.mySkills = SSs;
			self.job.skillId = JSON.stringify(SSs);
		});

		$('#currency').on('change', (e) => {
			var Currency = $(e.target).val();
			self.job.currency = Currency;
		});

		$('#job_type').on('change', (e) => {
			var job_type = $(e.target).val();
			self.job.job_type = job_type;
		});

		$('#salary').on('change', (e) => {
			var price = $(e.target).val();
			self.job.price = price;

			self.job.min_salary = parseInt(price) - (3*0.1*price);
			self.job.max_salary = parseInt(price) + (1*0.1*price);

			$('#minimount').val(self.job.min_salary);
			$('#maximount').val(self.job.max_salary);
		});
		$('#minimount').on('change', (e) => {
			var min_salary = $(e.target).val();
			self.job.min_salary = min_salary;
		});
		$('#maximount').on('change', (e) => {
			var max_salary = $(e.target).val();
			self.job.max_salary = max_salary;

		});
		$('#paymentcode').on('change', (e) => {
			var payment_code = $(e.target).val();
			self.job.payment_code = payment_code;

		});

		$("#company_select").on("change", function () {
			var Id = $(this).val();
			self.CompanySelected(Id);
		});


		$('#career_select').on('change', (e) => {
			var career = $(e.target).val();
			self.job.career_level = career;
		});

		$('#experience_select').on('change', (e) => {
			var experience = $(e.target).val();
			self.job.experience = experience;
		});

		$('#gender_select').on('change', (e) => {
			var gender = $(e.target).val();
			self.job.gender = gender;
		});

		$('#qualification_select').on('change', (e) => {
			var qualification = $(e.target).val();
			self.job.qualification = qualification;
		});


	}

	CompanySelected(Id){
		var self = this;
		var company = this.companies.find(x=> x.id == Id);
		if(company!=null){
			this.job.company_id = Id;

			var lat_lng = [0,0];

			if(company.coordinates!=null){
				if(company.coordinates.split(',').length>0){
					lat_lng = company.coordinates.split(',');
				}
			}

			this._company = {
				id: company.id,
				name: company.name,
				description: company.description,
				address: company.address,
				location: company.location,
				region: company.region,
				lat: lat_lng[0],
				lng: lat_lng[1],
			}

			$('#region').val(company.region);
			$('#location').val(company.location);
			$('#address').val(company.address);
			$('#lat').val(self._company.lat);
			$('#lng').val(self._company.lng);
		}
	}

	getCategory() {
		this._api.GET({
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
					if (data.length > 0) {
						this.job.categoryId = data[0].id;
						this.ReloadSkills(data[0].id);
					}
				}
			},
			error: (error) => {
			}
		});
	}

	getTempFiles() {
		this._api.GET({
			api_route: 'api/job/v1/temp_files', success: (data) => {
				if (data != null) {
					this.temp_files = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getCompanies() {
		this._api.GET({
			api_route: 'api/user/company/v1/all', success: (data) => {
				if (data != null) {
					this.companies = data;
					if(data.length>0){
						this.CompanySelected(data[0].id);
						
					}else{
						this.companyUnavailable();
						//this.alertService.alertType("No Comapnies Found",
						//	'To post a job, you need to add at least 1 company to your account.', "warning");
					}
				}
			},
			error: (error) => {
			}
		});
	}

	companyUnavailable(){
        var options = {
            title: "No company found",
            text: "To post a job, you need to add 1 company at least to your account. ",
            icon: "warning",
			buttons: {
				
				  confirm: {
					text: "Create Company Now!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			self._router.navigate(['/account/companies']);
        });
    }

	OpenSelection() {
		console.log('OpenSelection()', this.selected_category);
	}

	ReloadSkills(id) {
		var self = this;
		self.job.categoryId = id;
		//this.selected_category = null;
		var _skills = [];

		var category = this.categories.find(x => x.id == id);
		if (category != null) {
			console.log('Hit FM', category);
			self.selected_category = category;

			category.skills.forEach(_skill => {
				_skills.push({
					id: _skill.id,
					text: _skill.skillName
				});
			});

			$('#skill_select').select2('destroy');
			$('#skill_select').empty().trigger("change");
			setTimeout(function () {
				console.log('Founder', self.selected_category);

				$("#skill_select").select2({
					placeholder: 'select skill',
					data: _skills
				});
				$("#skill_select").val([]).trigger('change');
			}, 500);
		}
	}

	AddNote(){
		this._additions.push({message: this._note});
		this.RekeyAdditions();
		this._note = '';
	}

	RemoveNote(note){
		this._additions = this._additions.filter(x=> x.id != note.id);
		this.RekeyAdditions();
	}

	RekeyAdditions(){
		for (let i = 0; i < this._additions.length; i++) {
			const note = this._additions[i];
			note.id = (i+1);
		}
	}

	RemoveFile(file){
		var body = {
			image: file
		};
		var url = 'api/job/v1/delete_temp_file';
		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: body,
			success: (data)=>{
				this.temp_files = data;
				toastr.clear();
				toastr.success('File successfully removed');
			},
			error: (error)=>{
				
			}
		});
	}

	saveJob() {
		if (this.job.jobTitle == "" || this.job.jobTitle == null) {
			toastr.clear();
			toastr.error('job title cannot be empty');
			return;
		}

		if (this.job.description == "" || this.job.description == null) {
			toastr.clear();
			toastr.error('Short Description cannot be empty');
			return;
		}


		this.job.expire_at = $('#job_expires').val();

		if (this.job.expire_at == "" || this.job.expire_at == null) {
			toastr.clear();
			toastr.error('Please select due date');
			return;
		}

		if (this.job.job_type == "" || this.job.job_type == null) {
			toastr.clear();
			toastr.error('Choose Job Type cannot be empty');
			return;
		}

		if (this.job.price == "" || this.job.price == null) {
			toastr.clear();
			toastr.error('Average Salary cannot be empty');
			return;
		}

		/*if (this.job.min_salary <= 0 || this.job.max_salary <= 0) {
			toastr.clear();
			toastr.error('Please indicate Minimum and Maximum Salary');
			return;
		}*/

		if (this.mySkills.length == 0) {
			toastr.clear();
			toastr.error('Select at least one skill.');
			return;
		}

		if (!this.job.agreed) {
			toastr.clear();
			toastr.error('Please agree to our Terms and Conditions.');
			return;
		}

		this.job.additional_notes = JSON.stringify(this._additions);

		toastr.clear();
		toastr.info('Creating a new Job, please wait...');

		console.log('Job',this.job);

		var url = 'api/job/v1/post/'+this.job.alert;
		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.job,
			success: (data)=>{
				this._job = data;
				this.JOB_POST_SUCCESSFUL = true;
				$('#myTabContent').hide();
				//$('#add_company_modal').modal('toggle');
				this.job = {};
				this.alertService.alertType("Job Post Successfully",
					'You have successfully posted a job.', "success");
			},
			error: (error)=>{
				
			}
		});
	}
	//Changed today
	saveJobNoAlert() {
		if (this.job.jobTitle == "" || this.job.jobTitle == null) {
			toastr.clear();
			toastr.error('job title cannot be empty');
			return;
		}

		if (this.job.description == "" || this.job.description == null) {
			toastr.clear();
			toastr.error('Short Description cannot be empty');
			return;
		}


		this.job.expire_at = $('#job_expires').val();

		if (this.job.expire_at == "" || this.job.expire_at == null) {
			toastr.clear();
			toastr.error('Please select due date');
			return;
		}

		if (this.job.job_type == "" || this.job.job_type == null) {
			toastr.clear();
			toastr.error('Choose Job Type cannot be empty');
			return;
		}

		if (this.job.price == "" || this.job.price == null) {
			toastr.clear();
			toastr.error('Average Salary cannot be empty');
			return;
		}

		/*if (this.job.min_salary <= 0 || this.job.max_salary <= 0) {
			toastr.clear();
			toastr.error('Please indicate Minimum and Maximum Salary');
			return;
		}*/

		if (this.mySkills.length == 0) {
			toastr.clear();
			toastr.error('Select at least one skill.');
			return;
		}

		if (!this.job.agreed) {
			toastr.clear();
			toastr.error('Please agree to our Terms and Conditions.');
			return;
		}

		this.job.additional_notes = JSON.stringify(this._additions);

		toastr.clear();
		toastr.info('Creating a new Job, please wait...');

		console.log('Job',this.job);

		var url = 'api/job/v1/postNoAlert';
		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.job,
			success: (data)=>{
				this._job = data;
				this.JOB_POST_SUCCESSFUL = true;
				$('#myTabContent').hide();
				//$('#add_company_modal').modal('toggle');
				this.job = {};
				this.alertService.alertType("Job Post Successfully",
					'You have successfully posted a job.', "success");
			},
			error: (error)=>{
				
			}
		});
	}

	saveChanges(){
		
	}
	UpdateStatus(_job){
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/job/v1/update_status',
			body: _job,
			success: (data)=>{
				
				_job.enabled = !_job.enabled;

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				toastr.success('changes saved successfully, Ignore Error Message');
			}
		});
	}

	UploadFiles(){
		
		var api = "api/job/v1/upload_temp_image";
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Job Post Temp Files',
            e: '#m-dropzone-one-jp',
            pe: '#honeycomb-jp',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Your-JobPosts-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
				toastr.success('File upload successful');
				self.temp_files = res;
				//self.user.cv = res.cv;
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 2.0MB and in the following extensions (.pdf, .docx, .png, .jpeg).","error");
            },
            i:function (err){
                $('#UploadFile-Your-JobPosts-modal').modal('toggle');
            }
        });
	}
}
