import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';
import { Helpers } from '../../../helpers';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-my-jobs',
	templateUrl: './my-jobs.component.html',
	styleUrls: ['./my-jobs.component.css']
})
export class MyJobsComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	_jobSearch: string = "";

	applications:any = [];
	jobs:any = [];
	companies:any = [];
	selected_job:any = null;

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
		) { }

	ngOnInit() {
		
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;

		
		this.getMyJobs();
	}

	getMyJobs() {
		this._api.GET({
			api_route: 'api/apply/v1/all_jobs', success: (data) => {
				if (data != null) {
					this.applications = data.Applications;
					this.jobs = data.Jobs;
					this.companies = data.Companies;
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		this.applications.forEach(a => {

			var j = this.jobs.find(x=>x.id == a.job_id);
			if(j!=null){
				j.status = a.status;
				j._date = Helpers.fromNow(j.created_at);
				j._expire_at = Helpers.longShortDate(j.expire_at);

				var c = this.companies.find(x=>x.id == j.company_id);
				if(c!=null){
					j.location = c.location;
					j.address = c.address;
					j.companyName = c.name;
				}
			}
		});
	}

}
