import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions, Response, ResponseContentType } from "@angular/http";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";


@Injectable()
export class AppSettingsUnilynq {
    

    constructor(private http: HttpClient) {

    }
    public mailChimpEndpoint: string = 'https://jobmatchgh.us7.list-manage.com/subscribe/post?u=19cc12fa20447a00d62730be0&amp;id=07b32fd092';

    //public HURL: string = "https://localhost:44310/";
    public isLocalResolver: boolean = false;
    public LocalResolver: string = '';
    public LocalResolverName:string = '';

    public HURL:string = "https://jobmatchapi.zlitch.com:5200/";

    public ExpresspaySandbox:boolean = false;

    subscribeToList(data) {
        const params = new HttpParams()
          .set('EMAIL', data.email)
          //.set('group[24413][128]', 'true')
          .set('b_19cc12fa20447a00d62730be0_07b32fd092', '');
        const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;
        return this.http.post(mailChimpUrl, 'c')
        
    }
    getDropZoneHeaders() {
        if (this.isLocalResolver) {
            return {
                'Authorization': "bearer " + this.getjwt(),
                'Wresolver': this.LocalResolver
            };
        } else {
            return {
                'Authorization': "bearer " + this.getjwt()
            };
        }
    }

    getDownloadHeaders() {
        let _headers = new Headers({ 'Authorization': 'Bearer ' + this.getjwt() });
        _headers.append('Content-Type', 'application/json');
        if (this.isLocalResolver)
            _headers.append('Wresolver', this.LocalResolver);

        return _headers;
    }

    getDownloadCommonHeaders() {
        let _headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.getjwt(),
            'Content-Type': 'application/json'
        });
        

        return _headers;
    }

    getjwt() {
        // create authorization header with jwt token
        if(this.isLocalResolver){
            let currentUser = JSON.parse(localStorage.getItem('delegateUser'));
            if (currentUser && currentUser.token) {
                return currentUser.token;
            }
        }else{
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser && currentUser.token) {
                return currentUser.token;
            }
        }
        
    }

    jwt() {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        
        return new RequestOptions({ headers: headers });

    }

    jwtJSON() {
        // create authorization header with jwt token
        if(this.isLocalResolver){
            let currentUser = JSON.parse(localStorage.getItem('delegateUser'));
            if (currentUser && currentUser.token) {
                let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
                headers.append('Content-Type', 'application/json');
                
                return new RequestOptions({ headers: headers });
            }
        }else{
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser && currentUser.token) {
                let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
                headers.append('Content-Type', 'application/json');
                
                return new RequestOptions({ headers: headers });
            }
        }
        
    }

    jwtBlob() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
            headers.append('Content-Type', 'application/json');
            headers.append('responseType', 'arraybuffer');

            return new RequestOptions({ headers: headers });
        }
    }
}