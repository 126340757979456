import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
	selector: 'app-admin-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

	users:any = [];
	_user:any = {
		email: ''
	};

	selected_user:any = null;

	_user_search:string = "";

	constructor(
		private http: Http,
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.GetUsers();
	}

	/*GetUsers(){
		this._api.GET({
			//body: this._user,
			load:true,
			//api_route: 'api/admin/roles/v3/find', success: (data) => {
			api_route: 'api/adminroles/v2/all_users', success: (data) => {
				if (data != null) {
					this.users = data;
					console.log('my', this.users)
				}
			},
			error: (error) => {
			}
		});
	}*/
	GetUsers(){
		this._api.POST({
			body: this._user,
			api_route: 'api/admin/roles/v3/find', success: (data) => {
				if (data != null) {
					this.users = data;
				}
			},
			error: (error) => {
			}
		});
	}
	select(user) {
		this._api.GET({
			load: true,
			api_route: 'api/admin/delegate/v1/request_access/'+user.id, success: (data) => {
				if (data != null) {
					this.selected_user = data;
					this.completeLogin(data);
				}
			},
			error: (error) => {
				console.log('Error',error);
			}
		});
	}

	completeLogin(_user) {

		let headerss = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		Helpers.setLoading(true);


		let b = 'username=' + _user.email + '&password=' + _user.pin + '&grant_type=password';
		this.http.post(this.appset.HURL + 'token', b, { headers: headerss }).pipe(map((response) => response.json())).subscribe(data => {
			
			Helpers.setLoading(false);
			// login successful if there's a jwt token in the response
			let user: any = data;

			user.token = user.access_token;
			if (user && user.token) {
				
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem('delegateUser', JSON.stringify(user));

				this.appset.isLocalResolver = true;
				this.appset.LocalResolver = _user.email;
				this.appset.LocalResolverName = _user.firstName + " "+ _user.lastName;
				
				this.resources.UpdateAuthentication.next({});
				this._router.navigate(['/account/profile']);
			}
		}, error => {
			
			this.alertService.alertType("Authentication Failed","Invalid Username or Password!","error");
			console.log('ERROR', error);
			Helpers.setLoading(false);
		});

	}
}
