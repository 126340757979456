import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';
import { Helpers } from '../../../helpers';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-jobs',
	templateUrl: './jobs.component.html',
	styleUrls: ['./jobs.component.css']
})

export class JobsComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	_jobSearch: string = "";

	jobs:any = [];
	companies:any = [];
	selected_job:any = null;

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
		) { }

	ngOnInit() {
		
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;

		this._resources.UpdateAuthentication.next({});
		this._resources.AuthenticationStatus.subscribe(res=>{
			if(!this._resources._isAuthenticated) return;
			if(this._resources._user==null)return;

			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			if(!this.user.is_job_giver){
				toastr.info('You need to request recruitment feature to post jobs.');
				this._router.navigate(['/account/access']);
				this._resources.AlertPageChange.next({});
			};
		});

		this.getMyJobs();
	}

	getMyJobs() {
		this._api.GET({
			api_route: 'api/job/v1/all', success: (data) => {
				if (data != null) {
					this.jobs = data.Jobs;
					this.companies = data.Companies;
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		this.jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);

			var c = this.companies.find(x=>x.id == j.company_id);
			if(c!=null){
				j.location = c.location;
				j.address = c.address;
				j.companyName = c.name;
			}
		});
	}

	UploadImage(theJob){
		this.selected_job = theJob;
		var api = "api/job/v1/change_job_image/"+theJob.id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Job Files',
            e: '#m-dropzone-one-author',
            pe: '#honeycomb-author',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Job-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
                toastr.success('File upload successful');
				var job = self.jobs.find(x=>x.id == res.id);
				if(job!=null){
					job.image = res.image;
				}
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Job-modal').modal('toggle');
            }
        });
	}

	UpdateStatus(_job){
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/job/v1/update_status',
			body: _job,
			success: (data)=>{
				
				_job.enabled = !_job.enabled;

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				
			}
		});
	}
}
