import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from './helpers';


declare let POTENZA: any;
declare let $:any;


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'jm-user';

	constructor(private _router: Router,
		) {

	}

	ngOnInit() {
		this._router.events.subscribe((route) => {
			if (route instanceof NavigationStart) {
				Helpers.setLoading(true);
			}
			if (route instanceof NavigationEnd) {
				(<any>POTENZA).Init();
				//Helpers.movePageTop();
				Helpers.setLoading(false);
				//$('#back-to-top').click();
			}
		});

	}
}
