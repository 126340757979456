import { Component, OnInit } from '@angular/core';
//import * as GoogleNewsRss from "google-news-rss";
//import * as converter from 'xml-js';
import { NgForm } from '@angular/forms';
import { AppSettingsUnilynq} from '../../_services/app-settings.service';
import { Headers, Http, RequestOptions, Response, ResponseContentType } from "@angular/http";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { ApiService } from '../../_services/api.service';
import { SharedResources } from '../../_services/shared.resources';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import {  HttpEvent,HttpEventType, HttpRequest } from '@angular/common/http';


//declare var require;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  subscribeData:any=<any>{};
  _filter: any ={
    EMAIL: 'martha.teye99@gmail.com',
    b_19cc12fa20447a00d62730be0_07b32fd092: ""};
  
  resp:any =[];
  constructor(//private googleNews: GoogleNewsRss
    private subscribeService:AppSettingsUnilynq,
    private http:Http,
    private _api: ApiService,
		private _router: Router,
		public appset: AppSettingsUnilynq,
		private httpClient:HttpClient,
		private _resources: SharedResources
    ) { }

  ngOnInit() {
    var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
        i.src = "https://cdn.curator.io/published/ca9f6c99-26b7-40db-8098-c11a8cdf917d.js";
        e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);

  }
  /*subscribe(_filter){
      this._api.POST2({
      load: true,
      showError: true,
      body: this._filter,
      success: (data)=>{
                
      },
      error: (error)=>{
        
      } 
    })    
  }*/
  subscribe(){
  let headerss = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		//Helpers.setLoading(true);

		let b = 'EMAIL=' + "martha.teye999@gmail.com" + '&b_19cc12fa20447a00d62730be0_07b32fd092=' + '';
		this.http.post(this.appset.mailChimpEndpoint, b, { headers: headerss }).pipe(map((response) => response.json())).subscribe(data => {
			console.log("new", data);
      //Helpers.setLoading(false);
    }, error => {
			
			//this.alertService.alertType("Authentication Failed","Invalid Username or Password!","error");
			console.log('ERROR', error);
			//Helpers.setLoading(false);
		});

    }

  
}