import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { Router } from '@angular/router';

import { Subscription, interval } from 'rxjs';
import { SharedResources } from '../../_services/shared.resources';

declare let toastr:any;
declare let $:any;

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit,AfterViewInit {

	user: any = {
		Name: '',
		Email: '',
		Password: '',
		ConfirmPassword: '',
		RegistrationType: '',
		JobGiverType: 'Individual',
		agreed: false
	};
	//someDate: Date;
	private subscription: Subscription;
  
    public dateNow = new Date();
    public dDay = new Date('Mar 15 2021 00:00:00');
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute  = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    public hoursToDday;
    public daysToDday;

	public countries:any = [];
	public regions:any = [];


    private getTimeDifference () {
        this.timeDifference = this.dDay.getTime() - new  Date().getTime();
        this.allocateTimeUnits(this.timeDifference);
    }

  private allocateTimeUnits (timeDifference) {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

	constructor(
		private _api: ApiService,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources
	) { 
		this.countries = _resources.countries;
		this.regions = _resources.regions;
	}

	ngOnInit() {
		//this.someDate = new Date( Date.now() + (14 * 60 + 12) * 1000 );
		this.subscription = interval(1000)
           .subscribe(x => { this.getTimeDifference(); });
	}

	ngAfterViewInit(){
		var self = this;
		$('#country_select').on('change', (e) => {
			var country = $(e.target).val();
			$('#regionElement').show();
			if(country!="GH"){
				$('#regionElement').hide();
			}
			self.user.Country = country;
		});
		
		$('#region_select').on('change', (e) => {
			var region = $(e.target).val();
			self.user.Region = region;
		});

		$('#registration_select').on('change', (e) => {
			var registration_user_type = $(e.target).val();
			$('#jobGiverTypeElement').show();
			if(registration_user_type!="GIVER"){
				$('#jobGiverTypeElement').hide();
			}
			self.user.RegistrationType = registration_user_type;
		});

		$('#registration_giver').on('change', (e) => {
			var giver_type = $(e.target).val();
			self.user.JobGiverType = giver_type;
		});

		$('#country_select').val('GH').trigger("change");
		$('#registration_select').val('GIVER').trigger("change");
		$('#region_select').val('Greater Accra Region').trigger("change");
	}

	signup() {
		
		if (this.user.Name == '') {
			toastr.clear();
			toastr.warning('Name cannot be empty or null!');
			return;
		}

		if (this.user.Email == '') {
			toastr.clear();
			toastr.warning('Please enter your email');
			return;
		}

		if (!this.user.Email.includes('@')) {
			toastr.clear();
			toastr.warning('Invalid Email');
			return;
		}

		if (this.user.Password != this.user.ConfirmPassword) {
			toastr.clear();
			toastr.warning('Passwords do NOT match!');
			return;
		}

		if (!this.user.agreed) {
			toastr.clear();
			toastr.warning('Accept our terms and conditions policy');
			return;
		}

		this._api.POST({
			api_route: 'api/Account/Register',
			body: this.user,
			load: true,
			success: (data)=>{
				console.log('registered data',data);
				this.alertService.alertType("Account Created Successfully", 
				'Kindly check your email and activate your account.', "success");
				this._router.navigate(['/login']);
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}
}
