import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Routes, RouterModule, Router,ActivatedRoute } from '@angular/router';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CompaniesComponent } from './companies/companies.component';
import { JobsComponent } from './jobs/jobs.component';
//import { CandidatesComponent } from './candidates/candidates.component';

import { SharedResources } from '../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { Helpers } from '../../helpers';
import { ApiService } from '../../_services/api.service';

declare let $:any;
declare let toastr:any;

@Component({
	selector: 'app-account-employer',
	templateUrl: './account_employer.component.html',
	styleUrls: ['./account_employer.component.css']
})
export class EmployerAccountComponent implements OnInit {

	activeCategories = ['Dashboard'];
	activePage:any = 'Dashboard';

	company_id:any = 0;

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	ID:any = 0;
	bag:any = null;
	Company:any = {};
	company_jobs:any = [];

	other_jobs:any = [];

	@ViewChild('componentcontainer', { read: ViewContainerRef })
	entry: ViewContainerRef;

	constructor(
		private resolver: ComponentFactoryResolver,
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private route:ActivatedRoute,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		$("#UploadFile-Dashboard-Company-modal").on("shown.bs.modal", function() {
			$('#back-to-top').click();
		});

		this.InitPage();
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
	
		this._resources.UpdateAuthentication.next({});
		this._resources.AuthenticationStatus.subscribe(res=>{
			if(!this._resources._isAuthenticated){
				this._router.navigate(['/login']);
				return;
			}
			
			if(!this._resources._isAuthenticated) return;
			if(this._resources._user==null)return;

			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			if(!this.user.is_job_giver){
				toastr.info('You need to request recruitment feature to post jobs.');
				this._router.navigate(['/account/access']);
				this._resources.AlertPageChange.next({});
			};
		});

		this._resources.AlertCompanyChange.subscribe(x=>{
			this.InitPage();
		});
		
    	if(this.user!=null){
			this.name = this.user.firstName + " " + this.user.lastName;
		}
	}

	InitPage(){
		this.activePage=this.route.snapshot.paramMap.get("page");

		this.company_id=this.route.snapshot.paramMap.get("company_id");
		
		if(this.activePage == 'profile'){
			this.CreateComponentProfile();
		} else if(this.activePage == 'other-companies'){
			this.CreateComponentCandidates();
		} else if(this.activePage == 'manage-jobs'){
			this.CreateComponentManageJobs();
		} else if(this.activePage == 'gallery'){
			this.CreateComponentGallery();
		} else {
			this.createComponentDashboard();
		}
		this.getEmployerDetail();	
	}

	ReturnActiveClass(cate){
        for(var i=0;i<this.activeCategories.length;i++){
            if(this.activeCategories[i]==cate) return 'active';
        }
        return '';
    }

	createComponentDashboard() {
		this.activeCategories = ['Dashboard'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(DashboardComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentProfile() {
		this.activeCategories = ['Profile'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(ProfileComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentGallery() {
		this.activeCategories = ['Gallery'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(GalleryComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentCandidates() {
		this.activeCategories = ['other-companies'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(CompaniesComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentManageJobs() {
		this.activeCategories = ['Jobs'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(JobsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	UploadImage(){
		
		var api = "api/user/company/v1/change_image/"+this.company_id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Company Dashboard Files',
            e: '#m-dropzone-one-author',
            pe: '#honeycomb-author',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Dashboard-Company-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
				toastr.success('File upload successful');
				
				if(self.Company!=null){
					self.Company.image = res.image;
				}
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Dashboard-Company-modal').modal('toggle');
            }
        });
	}

	getEmployerDetail() {
		//var job_id = this._route.snapshot.queryParams['ref'] || '0';
		var employer_id = this.route.snapshot.paramMap.get("company_id");;

		var URL = 'api/company/search/v1/detail/'+employer_id;
		if(this._resources._isAuthenticated){
			URL = 'api/company/search/v1/detail_auth/'+employer_id;
		}

		this._api.GET({
			load: true,
			showError: true,
			api_route: URL, success: (data) => {
				this.bag = data;
				if (data != null) {
					this.Company = data.Company;
					this.company_jobs = data.Job;
					this.other_jobs = data.OtherJobs;
					if(this.Company!=null){
						this.ID = this.Company.id;
						this.Company._date = Helpers.longShortDate(this.Company.date);
					}
					this.RestructJobs();
				}
			},
			error: (error) => {

			}
		});
	}

	RestructJobs(){
		
		this.company_jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.fav = 'far fa-heart';

			if(j.job_type == 'Part-time')j.klass = 'part-time';
			else if(j.job_type == 'Full-time')j.klass = 'full-time';
			else if(j.job_type == 'Internship')j.klass = 'temporary';
			else j.klass = 'freelance';
		});
	}
}
