import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';
import { Helpers } from '../../../helpers';
import { reduce } from 'rxjs/operators';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-jobs',
	templateUrl: './jobs.component.html',
	styleUrls: ['./jobs.component.css']
})

export class JobsComponent implements OnInit, AfterViewInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	company_id:any = 0;

	_jobSearch: string = "";
	_applicantSearch:string = "";

	jobs:any = [];
	appraisals:any = [];
	company:any = null;

	selected_job:any = null;
	applicants:any = [];
	users:any = [];
	search_users:any = [];

	user_applicants:any = [];

	statuses:any = [{id: 1, name: 'Pending'},{id: 2, name: 'Shortlist'},{id: 3, name: 'Awarded'},{id: 4, name: 'Denied'},{id: 5, name: 'Passive Denial'}];

	stages:any = [{id: 1, name: 'Accepting Applications'},{id: 2, name: 'Reviewing Applications'},{id: 3, name: 'Job Match Complete'}];

	status:any = {
		pending: true,
		shortlisted:true,
		awarded: true,
		denied: true,
		passive_denial:true
	};

	totals:any = {
		pending: 0,
		shortlisted:0,
		awarded: 0,
		denied: 0,
	};

	categories:any = [];
	responses:any = [];
	AllResponses:any = [];

	additionalTransactionFees:any = 0;
	totalJobPostSalary:any = 0;
	expresspayPercentageCharge:any = 0;
	
	_additionalTransactionFees:any = 0;
	_totalJobPostSalary:any = 0;

	additionalTransactionFeesAppraisal:any = 0;
	totalAppraisal:any = 0;

	_additionalTransactionFeesAppraisal:any = 0;
	_totalAppraisal:any = 0;

	//created today
	_user:any = {
		email: '',
		other: '[]'
	};

	selected_users:any[] = [];

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
		) { }

	ngOnInit() {
		
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.company_id=this.route.snapshot.paramMap.get("company_id");

		this.getCategory();
		this.getCompanyJobs();
	}

	ngAfterViewInit(){
		$('#user_modal').on('hidden.bs.modal', function () {
			$('#job_applicants_modal').modal('toggle');
		})
	}

	getCategory() {
		this._api.GET({
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getCompanyJobs() {
		
		this.company_id=this.route.snapshot.paramMap.get("company_id");

		this._api.GET({
			api_route: 'api/company/job/v1/all/'+this.company_id, success: (data) => {
				if (data != null) {
					this.jobs = data.Jobs;
					this.company = data.Company;
					this.appraisals = data.Appraisals;
					this.responses = data.AppraisalResponses;
					this.expresspayPercentageCharge = data.ExpresspayPercentageCharge;
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		this.jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.is_appraised = false;
			j.appraisal = null;
			j._price = Helpers.formatMoney(j.price,0,'.',',');
			//j._min = Helpers.formatMoney(j.min_salary,0,'.',',');
			//j._max = Helpers.formatMoney(j.max_salary,0,'.',',');

			var c = this.company;
			if(c!=null){
				j.location = c.location;
				j.address = c.address;
				j.companyName = c.name;
			}

			var appr = this.appraisals.find(x=>x.job_id == j.id);
			if(appr!=null){
				j.appraisal = appr;
				j.is_appraised = true;
			}
		});
	}

	ViewResponse(_job){
		var j = _job;
		
		this.SetSkills(_job);

		var appr = this.appraisals.find(x=>x.job_id == j.id);
		if(appr==null){
			toastr.warning('No Appraisal Found!');
			return;
		}
		var _R:any = {
			from: 'JobMatch Support Desk',
			response: appr.response,
			klass: 'text-info'
		};

		var appraisal_responses = [_R];

		var _responses = this.responses.filter(x=>x.appraisal_id == appr.id);

		var AGENT_COUNT = 1;
		_responses.forEach(res=>{
			appraisal_responses.push({
				from: 'Agent #'+AGENT_COUNT,
				response: res.response,
				klass: 'text-danger',
			});
			AGENT_COUNT++;
		});

		appraisal_responses.forEach(res => {
			this.ReturnResponseSkills(res);
			if (res._response.price!=null){
				res._response._price = Helpers.formatMoney(res._response.price,0,'.',',');
			}
			/*changed today
			if(res._response.min_salary!=null){
				res._response._min = Helpers.formatMoney(res._response.min_salary,0,'.',',');
			}

			if(res._response.max_salary!=null){
				res._response._max = Helpers.formatMoney(res._response.max_salary,0,'.',',');
			}*/
		});

		this.selected_job = _job;
		this.AllResponses = appraisal_responses;

		$('#edit_appraisal_modal').modal('toggle');
	}

	SetSkills(_job){
		_job.prev_skills = [];
		var category = this.categories.find(x => x.id == _job.categoryId);
		var j = _job;
		var skill_ids = JSON.parse(j.skillId);
		if(category!=null){
			skill_ids.forEach(skill_id => {
				var skill = category.skills.find(x=>x.id == skill_id);
				if(skill!=null){
					_job.prev_skills.push(skill);
				}
			});
		}
	}

	ReturnResponseSkills(AppraisalResponse){
		
		var categoryName = '';
		var _skills = [];
		var response = JSON.parse(AppraisalResponse.response);
		AppraisalResponse._response = response;
		var category = this.categories.find(x => x.id == response.categoryId);

		var skill_ids = response.skills;
		if(category!=null){
			categoryName = category.categoryName;
			
			if(skill_ids!=null){
				skill_ids.forEach(skill_id => {
					var skill = category.skills.find(x=>x.id == skill_id);
					if(skill!=null){
						_skills.push(skill);
					}
				});
			}
		}

		AppraisalResponse.categoryName = categoryName;
		AppraisalResponse._skills = _skills;
	}

	RecalculateApplicantsMatch(job_id){
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/company/job/v1/calculate_match/'+job_id, success: (data) => {
				if (data != null) {
					
					this.selected_job = data.Job;
					this.applicants = data.Applicants;
					this.users = data.Users;
					
					this.RestructApplicants();
					$('#job_applicants_modal').modal('toggle');
					
				}
			},
			error: (error) => {
			}
		});
	}

	getJobApplicants(job_id) {
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/company/job/v1/applicants/'+job_id, 
			success: (data) => {
				if (data != null) {
					
					this.selected_job = data.Job;
					this.applicants = data.Applicants;
					this.users = data.Users;
					
					this.RestructApplicants();
					$('#job_applicants_modal').modal('toggle');
					
				}
			},
			error: (error) => {
			}
		});
	}

	RestructApplicants(){
		let user_ids:number[] = [];
		this.user_applicants = [];
		this.totals = {
			pending: 0,
			shortlisted:0,
			awarded: 0,
			denied: 0,
		};
		
		this.applicants.forEach(a => {
			user_ids.push(a.user_id);
			
			var _j = this.users.find(x=>x.id == a.user_id);
			if(_j!=null){
				
				var j:any = {
					firstName: _j.firstName,
					lastName: _j.lastName,
					email: _j.email,
					phone: _j.phone,
					image: _j.image
				};
				j._date = Helpers.fromNow(j.date);
				j.__date = Helpers.longShortDate(j.date);

				j._last_modified = Helpers.fromNow(j.last_modified);
				j.__last_modified = Helpers.longShortDate(j.last_modified);

				j.id = a.id;
				j._status = this.GetJobStatus(a.status);
				j.status = a.status;
				j.klass = this.GetJobStatusClass(a.status);
				j.job_id = a.job_id;
				j.match = a.match;
				j.user_id = a.user_id;
				j.applicant_message = a.applicant_message;
				j.employer_message = a.employer_message;

				if(a.status == 1){ 
					this.totals.pending+=1;
					if(this.status.pending) this.user_applicants.push(j);
				}

				if(a.status == 2){ 
					this.totals.shortlisted+=1;
					if(this.status.shortlisted) this.user_applicants.push(j);
				}
				
				if(a.status == 3){ 
					this.totals.awarded+=1;
					if(this.status.awarded) this.user_applicants.push(j);
				}

				if(a.status == 5 || a.status == 4){ 
					this.totals.denied+=1;
					if(this.status.denied) this.user_applicants.push(j);
				}
			}
		});

		this._user.other = JSON.stringify(user_ids);
	}

	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Shortlisted';
		if(status_id == 3) status = 'Awarded';
		if(status_id == 4) status = 'Denied';
		if(status_id == 5) status = 'Passive Denial'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'badge-info';
		if(status_id == 3) status = 'badge-success';
		if(status_id == 4) status = 'badge-danger';
		if(status_id == 5) status = 'badge-primary'; 

		return status;
	}

	UploadImage(theJob){
		this.selected_job = theJob;
		var api = "api/job/v1/change_job_image/"+theJob.id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Job Files',
            e: '#m-dropzone-one-job-post',
            pe: '#honeycomb-job-post',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Job-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
                toastr.success('File upload successful');
				var job = self.jobs.find(x=>x.id == res.id);
				if(job!=null){
					job.image = res.image;
				}
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Job-modal').modal('toggle');
            }
        });
	}

	UpdateStatus(_job){
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/job/v1/update_status',
			body: _job,
			success: (data)=>{
				
				_job.enabled = !_job.enabled;

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				
			}
		});
	}

	ChangeApplicantStatus(_applicant){
		toastr.clear();
		toastr.info('Saving Changes please wait');
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/company/job/v1/change_applicant_status',
			body: _applicant,
			success: (data)=>{

				var app = this.applicants.find(x=>x.id == _applicant.id);
				if(app!=null){
					app.status = _applicant.status;
					app.employer_message = _applicant.employer_message;
				}
				_applicant._status = this.GetJobStatus(_applicant.status);
				_applicant.klass = this.GetJobStatusClass(_applicant.status);

				this.RestructApplicants();

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				
			}
		});
	}

	ChangeApplicantStage(_job){
		toastr.clear();
		toastr.info('Saving Changes, please wait');
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/job/v1/update_stage',
			body: _job,
			success: (data)=>{

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				
			}
		});
	}

	OpenAppraisal(_job){
		this.selected_job = _job;

		$('#buy_appraisal_package_modal').modal('toggle');
	}

	BuyAppraisalPackage(){

		$('#buy_appraisal_package_modal').modal('toggle');
		
		toastr.clear();
		toastr.info('Requesting Appraisal, please wait');
		
		var _job = this.selected_job;

		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/appraisal/v1/request',
			body: _job,
			success: (data)=>{
				this.appraisals.push(data);
				_job.appraisal = data;
				_job.is_appraised = true;

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				
			}
		});
	}

	RecalculateJobTotal(){
		this.totalJobPostSalary = (this.selected_job.price * this.selected_job.total_awardees) / (1-this.expresspayPercentageCharge);
		this.additionalTransactionFees = this.totalJobPostSalary * this.expresspayPercentageCharge;

		this._additionalTransactionFees = Helpers.formatMoney(this.additionalTransactionFees,2,'.',',');
		this._totalJobPostSalary = Helpers.formatMoney(this.totalJobPostSalary,2,'.',',');
	}

	JobPaymentOnline(_job){
		this.selected_job = _job;
		if(_job.price <=0){
			toastr.clear();
			toastr.warning('Kindly edit the price for the job. Price cannot be zero or less');
			return;
		}
		this.RecalculateJobTotal();

		$('#job_post_payment_online_modal').modal('toggle');
	}


	GetPaymentToken(){

		$('#job_post_payment_online_modal').modal('toggle');
		
		toastr.clear();
		toastr.info('Connecting to payment portal, please wait');
		
		var j = this.selected_job;
		var ta = j.total_awardees;
		var sbx = this.appset.ExpresspaySandbox;

		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/payment/v1/job/token/'+j.id+'/'+ta+'/'+sbx,
			success: (data)=>{
				console.log('Express pay infomation',data);
				
				if(data.token_status == 1){
					var checkout_link = 'https://expresspaygh.com/api/checkout.php?token='+data.token;
					//this._router.navigate([checkout_link]);

					toastr.clear();
					toastr.success('changes saved successfully');

					var newWindow = window.open(checkout_link, '_blank');
					newWindow.focus();
				}else{
					toastr.clear();
					toastr.error(data.token_status_text);
				}
			},
			error: (error)=>{
				
			}
		});
	}

	RecalculateAppraisalTotal(){
		this.totalAppraisal = (this.selected_job.appraisal.amount_paid) / (1-this.expresspayPercentageCharge);
		this.additionalTransactionFeesAppraisal = this.totalAppraisal * this.expresspayPercentageCharge;

		this._additionalTransactionFeesAppraisal = Helpers.formatMoney(this.additionalTransactionFeesAppraisal,2,'.',',');
		this._totalAppraisal = Helpers.formatMoney(this.totalAppraisal,2,'.',',');
	}

	AppraisalPaymentOnline(_job){
		this.selected_job = _job;
		this.RecalculateAppraisalTotal();

		$('#appraisal_payment_online_modal').modal('toggle');
	}

	GetAppraisalPaymentToken(){

		$('#appraisal_payment_online_modal').modal('toggle');
		
		toastr.clear();
		toastr.info('Connecting to payment portal, please wait');
		
		var a = this.selected_job.appraisal;
		var sbx = this.appset.ExpresspaySandbox;

		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/payment/v1/appraisal/token/'+a.id+'/'+sbx,
			success: (data)=>{
				console.log('Express pay appraisal',data);
				
				if(data.token_status == 1){
					var checkout_link = 'https://expresspaygh.com/api/checkout.php?token='+data.token;
					//this._router.navigate([checkout_link]);
					toastr.clear();
					toastr.success('changes saved successfully');
					var newWindow = window.open(checkout_link, '_blank');
					newWindow.focus();
					
				}else{
					toastr.clear();
					toastr.error(data.token_status_text);
				}

				
			},
			error: (error)=>{
				
			}
		});
	}

	//created today
	GetUsers(){
		this._api.POST({
			body: this._user,
			api_route: 'api/admin/jobs/v1/find', success: (data) => {
				if (data != null) {
					this.search_users = data;
				}
			},
			error: (error) => {
			}
		});
	}

	SelectUser(){
		$('#job_applicants_modal').modal('toggle');
		this.GetUsers();
		setTimeout(() => {
			$('#user_modal').modal('toggle');	
		}, 500);
	}

	ToggleSelection(user){
		if(user.selected){
			var existing_user = this.selected_users.find(x=>x.user_id == user.id);
			if(existing_user == null){
				this.selected_users.push({user_id: user.id, job_id:this.selected_job.id});
			}
		}else{
			this.selected_users = this.selected_users.filter(x=>x.user_id != user.id);
		}
	}

	AddUsers(){
		$('#user_modal').modal('toggle');

		this._api.POST({
			load: true,
			showError: true,
			body: this.selected_users,
			api_route: 'api/admin/jobs/v1/new_applicant', 
			success: (data) => {
				if (data != null) {
					this.selected_users = [];
					
					data.Applicants.forEach(appl => {
						this.applicants.push(appl);
					});
					
					data.Users.forEach(user => {
						this.users.push(user);
					});
					this.RestructApplicants();
				}
			},
			error: (error) => {
			}
		});
	}

	RemoveApplicant(_applicant){
		this._api.POST({
			load: true,
			showError: true,
			body: _applicant,
			api_route: 'api/admin/jobs/v1/remove_applicant', 
			success: (data) => {
				if (data != null) {
					this.applicants = this.applicants.filter(x=>x.id!=_applicant.id);
					this.RestructApplicants();
				}
			},
			error: (error) => {
			}
		});
	}
}
