import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';

import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
	selector: 'app-roles',
	templateUrl: './roles.component.html',
	styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

	view_type:number = 1;
	modules:any = [];
	_modules:any = [];

	user_roles:any = [];

	UserAdmins:any = [];
	Admins:any = [];

	users:any = [];
	_user:any = {
		email: ''
	};

	selected_user:any = null;
	
	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.getModules();
		this.getAdminUsers();
	}

	ChangeView(view){
		this.view_type = view;
	}

	getModules() {

		this._api.GET({
			api_route: 'api/admin/roles/v3/modules', 
			success: (data) => {
				if (data != null) {
					this._modules = data.modules;
					this.RestructModules();
				}
			},
			error: (error) => {
			}
		});
	}

	getAdminUsers() {

		this._api.GET({
			api_route: 'api/admin/roles/v3/all_admins', 
			success: (data) => {
				if (data != null) {
					this.UserAdmins = data.UserAdmins;
					this.Admins = data.Admins;
				}
			},
			error: (error) => {
			}
		});
	}

	RestructModules(){
		this.modules = [];
		
		var m_count = 1;

		this._modules.forEach(mod => {

			var role:any = {
				_id: m_count,
				module: mod,
				read_data: false,
				add_data: false,
				update_data: false,
				delete_data: false,
			};

			var user_role = this.user_roles.find(x=>x.module == mod);
			if(user_role!=null){
				role.add_data = user_role.add_data;
				role.read_data = user_role.read_data;
				role.update_data = user_role.update_data;
				role.delete_data = user_role.delete_data;

				role.user_id = this.selected_user.id;
			}
			this.modules.push(role);

			m_count++;
		});
	}

	GetUsers(){
		this._api.POST({
			body: this._user,
			api_route: 'api/admin/roles/v3/find', success: (data) => {
				if (data != null) {
					this.users = data;
				}
			},
			error: (error) => {
			}
		});
	}

	SelectUser(){
		this.GetUsers();
		$('#user_modal').modal('toggle');
	}

	select(user){
		$('#user_modal').modal('toggle');
		this.selected_user = user;
		if(this.view_type == 1){
			this.getUserModules();
			
		}else{
			this.confirmSuperAdmin();
		}
		
	}

	ManageRoles(user){
		this.selected_user = user;
		this.getUserModules();
	}

	getUserModules() {

		this._api.GET({
			load:true,
			showError:true,
			api_route: 'api/admin/roles/v3/modules/'+this.selected_user.id, 
			success: (data) => {
				if (data != null) {
					this.user_roles = data;
					this.RestructModules();

					setTimeout(() => {
						$('#user_modules_modal').modal('toggle');
						console.log('After Modules', this.modules);	
					}, 1000);
				}
			},
			error: (error) => {
			}
		});
	}

	SaveRoleChanges(){
		var body = [];

		this.modules.forEach(m => {
			m.user_id = this.selected_user.id;
			body.push(m);
		});

		if(body.length == 0){
			return;
		}

		toastr.clear();
		toastr.info('Saving changes, please wait...');

		this._api.POST({
			load:true,
			showError:true,
			body: body,
			api_route: 'api/admin/roles/v3/allow', 
			success: (data) => {
				toastr.clear();
				toastr.success('Changes have been saved successfully');
				this.getAdminUsers();
			},
			error: (error) => {

			}
		});
	}

	confirmSuperAdmin(){
        var options = {
            title: "Convert "+this.selected_user.email+"'s Account into Super Admin",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Proceed!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.MakeSuperAdmin();
				}
			}
        });
    }

	MakeSuperAdmin(){
		var body = this.selected_user;

		toastr.clear();
		toastr.info('Saving changes, please wait...');

		this._api.POST({
			load:true,
			showError:true,
			body: body,
			api_route: 'api/admin/roles/v3/super_admin', 
			success: (data) => {
				toastr.clear();
				toastr.success('Changes have been saved successfully');
				this.getAdminUsers();
			},
			error: (error) => {

			}
		});
	}

	confirmRemoveSuperAdmin(user){
        var options = {
            title: "Remove "+user.email+"'s Account from Super Admins",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove User!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.RemoveSuperAdmin(user);
				}
			}
        });
    }

	RemoveSuperAdmin(user){
		var body = user;

		toastr.clear();
		toastr.info('Saving changes, please wait...');

		this._api.POST({
			load:true,
			showError:true,
			body: body,
			api_route: 'api/admin/roles/v3/remove_super_admin', 
			success: (data) => {
				toastr.clear();
				toastr.success('Changes have been saved successfully');
				this.getAdminUsers();
			},
			error: (error) => {

			}
		});
	}
}
