import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../../helpers';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-job-details',
	templateUrl: './job-details.component.html',
	styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	bag:any = null;
	_company:any = null;
	similar_jobs:any = [];
	Attachments:any = [];
	_job:any = null;
	_applicant:any = null;
	_saved:any = 'far fa-heart';
	descriptions:any = [];

	PercentageMatch:any = 0;

	DownloadToken:any = '';

	constructor(
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _route: ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			this.getJobDetail();
		});

		this.getJobDetail();
	}

	getJobDetail() {
		//var job_id = this._route.snapshot.queryParams['ref'] || '0';
		var job_id =this._route.snapshot.paramMap.get("id");
		if(!job_id){
			this._router.navigate(['/home']);
		}

		var URL = 'api/jobs/v1/detail/'+job_id;
		if(this._resources._isAuthenticated){
			URL = 'api/jobs/v1/detail_auth/'+job_id;
		}

		this._api.GET({
			load: true,
			showError: true,
			api_route: URL, success: (data) => {
				this.bag = data;
				if (data != null) {
					this.PercentageMatch = data.PercentageMatch;
					this._job = data.Job;
					console.log(this._job);
					this._applicant = data.Applicant;
					this.similar_jobs = data.OtherJobs;
					this.Attachments = data.Attachments;
					this.DownloadToken = data.DownloadToken;
					
					if(data.Saved){
						this._saved = 'fas fa-heart text-danger';
					}

					if(this._job!=null){
						this.RestructData();
					}
				}
			},
			error: (error) => {

			}
		});
	}

	RestructData(){
		this.descriptions = this._job.description.split('/n');
		this._job._date = Helpers.fromNow(this._job.created_at);
		this._job._expire_at = Helpers.longShortDate(this._job.expire_at);
		this._job._additions = JSON.parse(this._job.additional_notes);
		this._job._skills = JSON.parse(this._job.skillId);
		this._job._expires = Helpers.fromNow(this._job.expire_at);

		this._job._min = Helpers.formatMoney(this._job.min_salary,0,'.',',');
		this._job._max = Helpers.formatMoney(this._job.max_salary,0,'.',',');

		var c = this.bag.Company;
		this._company = c;
		if(c !=null){
			this._job.location = c.location;
			this._job.address = c.address;
			this._job.companyName = c.name;
			this._job.phone = c.phone;
		}
	}

	confirmJobApplication(){
        var options = {
            title: "Apply for Job",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Apply!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			
			if(e!=null){
				if(e==true){
					self.ApplyForJob();
				}
			}
        });
    }

	ApplyForJob(){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to apply for the Job.')
			this._router.navigate(['/login']);
			
			return;
		}

		this._api.POST({
			load: true,
			showError: true,
			body: this._job,
			api_route: 'api/apply/v1/new', success: (data) => {
				this._applicant = data;
				this.alertService.alertType("Job Applied Successfully",
					'You have been added to the applicants for the selected Job Post.', "success");
			},
			error: (error) => {

			}
		});
	}

	UpdateFavourites(_job){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to update your favourites.')
			this._router.navigate(['/login']);
			
			return;
		}

		this._api.POST({
			load: true,
			body: _job,
			api_route: 'api/favourites/v1/update', success: (data) => {
				if (data != null) {
					

					this._resources.UpdateSavedJobs.next({});
					this.getJobDetail();
				}
			},
			error: (error) => {
			}
		});
	}
}
