import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';

import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
	selector: 'app-appraisals',
	templateUrl: './appraisals.component.html',
	styleUrls: ['./appraisals.component.css']
})
export class AppraisalsComponent implements OnInit {

	company:any = {};
	regions:any = [];
	cities:any = [];

	companies:any = [];
	selected_company:any = null;

	isNew:boolean = true;

	_companySearch:any = '';
	categories: any = [];

	company_id:any = 0;

	_filter:any = {
		newest: true,
        status_ids: [],
		genders: [],
		search: '',
		start_date: '',
		end_date: '',
        page: 1,
		load: 15,
		search_by: 1
	};

	agent_filter:any = {
		newest: true,
        email: '',
        page: 1,
		load: 250,
		search_by: 1
	};

	status_ids:any = {
		pending: true,
		assigned: true,
		completed: true,
	};

	agents:any = [];
	responseCounts:any = [];


	appraisals:any = [];
	jobs:any = [];
	responses:any = [];
	users:any = [];

	selected_appraisal:any = null;

	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
	TotalRequests:any = 1;

	statuses:any = [{id: 1, name: 'Pending'},{id: 2, name: 'Assigned'},{id: 3, name: 'Appraisal Complete'}];

	summary:any = {
		Pending: 0,
		Approved: 0,
		Denied: 0,
		PassiveDenial: 0
	};
	_appraisalSearch:any = '';

	firstLoad:boolean = false;
	skills: any = [];
	prev_skills:any = [];
	selected_category: any = {
		skills: []
	};
	response:any = {
		currency: 'GHS',
		min_salary: 0,
		max_salary: 0,
		categoryId: 0,
		category: '',
		skills: []
	};

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {

		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if(Id=='Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getAppraisals();
		});

		this._resources.UpdateAuthentication.next({});

		this.getAppraisalSummary();
		this.getAppraisals();
		this.getCategory();

		var self = this;
		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			self.response.categoryId = Id;
			if(!self.firstLoad){
				self.ReloadSkills(Id);
			}
		});

		$('#skill_select').on('change', (e) => {
			var SSs = $(e.target).val();
			self.response.skills = SSs;
		});

		$('#currency').on('change', (e) => {
			var Currency = $(e.target).val();
			self.response.currency = Currency;
		});
	}

	
	getMyAgents(){

	}
	
	getAppraisalSummary() {

		this._api.GET({
			api_route: 'api/admin/appraisal/v1/summary', success: (data) => {
				if (data != null) {
					this.summary = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getAppraisals() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/admin/appraisal/v1/requests', success: (data) => {
				if (data != null) {
					

					this.users = data.Agents;
					this.appraisals = data.Requests;
					this.responses = data.AppraisalResponses;
					this.jobs = data.Jobs;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.appraisals.length;// (this._filter.page * this._filter.load);
					
					this.RestructAppraisals();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructAppraisals(){
		
		this.appraisals.forEach(j => {
			j._date = Helpers.longDateTime(j.date);
			j._last_modified = Helpers.fromNow(j.last_modified);
			j._status = this.GetJobStatus(j.status);
			j.klass = this.GetJobStatusClass(j.status);
			j.job = this.jobs.find(x=>x.id == j.job_id);
			j.agents = this.responses.filter(x=>x.appraisal_id == j.id);
			j.agents.forEach(agent => {
				agent.user = this.users.find(x=>x.id == agent.agent_user_id);
				agent._last_modified = Helpers.fromNow(agent.last_modified);
			});
			if(j.job!=null){
				this.JobRestruct(j.job);
			}
		});
	}

	JobRestruct(_job){
		_job.descriptions = _job.description.split('/n');
		_job._date = Helpers.fromNow(_job.created_at);
		_job._expire_at = Helpers.longShortDate(_job.expire_at);
		_job._additions = JSON.parse(_job.additional_notes);
		_job._skills = JSON.parse(_job.skillId);
		_job._expires = Helpers.fromNow(_job.expire_at);

		_job._min = Helpers.formatMoney(_job.min_salary,0,'.',',');
		_job._max = Helpers.formatMoney(_job.max_salary,0,'.',',');
	}

	SetOptions(){

		this._filter.status_ids = [];

		if(this.status_ids.pending) this._filter.status_ids.push(1);
		if(this.status_ids.assigned) this._filter.status_ids.push(2);
		if(this.status_ids.completed) this._filter.status_ids.push(3);

		this._filter.NOFILTER = true;
		if(this._filter.start_date!='' && this._filter.end_date!=null)this._filter.NOFILTER = false;

		if(this._filter.status_ids.length>0)this._filter.NOFILTER = false;
		
	}

	Next(){
        if(this._filter.page<this.TotalPages){
            this._filter.page+=1;
            this.getAppraisals();
        }
    }

    Prev(){
        if(this._filter.page>1){
            this._filter.page-=1;
            this.getAppraisals();
        }       
	}
	
	ApplyFilter(){
        this._filter.page = 1;
		this.getAppraisals();    
	}

	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Assigned';
		if(status_id == 3) status = 'Completed'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'bg-dark';
		if(status_id == 3) status = 'badge-info'; 

		return status;
	}

	ChangeApplicantStatus(_applicant){
		toastr.clear();
		toastr.info('Saving Changes please wait');
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/admin/agents/v1/change_applicant_status',
			body: _applicant,
			success: (data)=>{

				
				var app = this.agents.find(x=>x.id == _applicant.id);
				if(app!=null){
					app.status = _applicant.status;
					app.employer_message = _applicant.employer_message;
				}
				_applicant._status = this.GetJobStatus(_applicant.status);
				_applicant.klass = this.GetJobStatusClass(_applicant.status);

				this.RestructAppraisals();

				toastr.clear();
				toastr.success('changes saved successfully');
				this.getAppraisalSummary();
			},
			error: (error)=>{
				
			}
		});
	}

	ManageAgents(_appraisal){
		this.selected_appraisal = _appraisal;
		//this.selected_job = _appraisal.job;
		this.getAgents();
		$('#agents_list_modal').modal('toggle');
	}

	getAgents() {
		this._api.POST({
			load: true,
			body: this.agent_filter,
			api_route: 'api/admin/appraisal/v1/agents', success: (data) => {
				if (data != null) {
					this.agents = data.Requests;
					this.responseCounts = data.AgentAppraisalCount;
					
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.agents.length;// (this._filter.page * this._filter.load);
					
					this.RestructAgents();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructAgents(){
		
		this.agents.forEach(j => {
			j.counts = 0;
			j.assigned = false;
			j._status = this.GetJobStatus(j.status);
			j.klass = this.GetJobStatusClass(j.status);
			
			var appraisalCount = this.responseCounts.find(x=>x.agent_user_id == j.id);
			if(appraisalCount!=null){
				j.counts = appraisalCount.Total;
			}

			var response = this.responses.find(x=>x.agent_user_id == j.id && x.appraisal_id == this.selected_appraisal.id);
			if(response!=null){
				j.assigned = true;
			}
		});
	}

	RemoveAgentShort(_appraise,_user){
		this.selected_appraisal = _appraise;
		this.confirmRemoveAgentFromAppraisal(_user);
	}

	confirmRemoveAgentFromAppraisal(_response){
        var options = {
            title: "Remove "+_response.user.email,
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove Agent!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.RemoveAgentFromAppraisal(_response);
				}
			}
        });
	}
	
	RemoveAgentFromAppraisal(_response){
		this._api.POST({
			load: true,
			body: _response,
			api_route: 'api/admin/appraisal/v1/remove', success: (data) => {
				if (data != null) {
					
					this.responses = this.responses.filter(x=>x.id!=_response.id);
					this.selected_appraisal.agents = this.selected_appraisal.agents.filter(x=>x.id!=_response.id);
					this.RestructAgents();
				}
			},
			error: (error) => {
			}
		});
	}

	AddAgentToAppraisal(_user){
		var _response = {
			agent_user_id:_user.id,
			appraisal_id: this.selected_appraisal.id
		};

		this._api.POST({
			load: true,
			body: _response,
			api_route: 'api/admin/appraisal/v1/assign', success: (data) => {
				if (data != null) {
					data.user = _user;
					data._last_modified = Helpers.fromNow(data.last_modified);
					_user.assigned = true;
					this.responses.push(data);
					this.selected_appraisal.agents.push(data);
				}
			},
			error: (error) => {
			}
		});
	}

	RecordAppraisalPayment(_appraisal){
		this.selected_appraisal = _appraisal;
		$('#amount_paid').val(this.selected_appraisal.amount_paid);
		$('#pay_mode').val(this.selected_appraisal.mode_of_payment).trigger('change');
		$('#pay_reference').val(this.selected_appraisal.payment_token);
		$('#buy_appraisal_package_modal').modal('toggle');
	}

	confirmPayment(){

		this.selected_appraisal.amount_paid = $('#amount_paid').val();
		this.selected_appraisal.mode_of_payment = $('#pay_mode').val();
		this.selected_appraisal.payment_token = $('#pay_reference').val();

		if(this.selected_appraisal.amount_paid<=0){
			toastr.clear();
			toastr.warning('The amount paid cannot be zero or less.');
			return;
		}

		if(this.selected_appraisal.mode_of_payment=='' || this.selected_appraisal.mode_of_payment==null){
			toastr.clear();
			toastr.warning('kindly select mode of payment');
			return;
		}

		if(this.selected_appraisal.payment_token=='' || this.selected_appraisal.payment_token==null){
			toastr.clear();
			toastr.warning('please enter the reference for this payment');
			return;
		}

		var amount = $('#amount_paid').val();

        var options = {
            title: "Pay GHC "+amount + " Job Appraisal",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Record Payment!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.SavePaymentInfo();
				}
			}
        });
	}

	SavePaymentInfo(){
		toastr.clear();
		toastr.info('saving changes, please wait...');

		this._api.POST({
			load: true,
			body: this.selected_appraisal,
			showError: true,
			api_route: 'api/admin/appraisal/v1/payment', success: (data) => {
				if (data != null) {
					$('#buy_appraisal_package_modal').modal('toggle');

					var _request = this.appraisals.find(x=>x.id == data.id);
					if(_request!=null){

						toastr.clear();
						toastr.success('Payments recorded successfully');

						_request.last_modified = data.last_modified;
						_request.amount_paid = data.amount_paid;
						_request.paid = data.paid;
						_request.mode_of_payment = data.mode_of_payment;
						_request.payment_token = data.payment_token;
						this.RestructAppraisals();
					}
				}
			},
			error: (error) => {
			}
		});
	}


	getCategory() {
		this._api.GET({
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
				}
			},
			error: (error) => {
			}
		});
	}

	OpenAppraisalResponse(_appraisal){
		this.selected_appraisal = _appraisal;
		this.SetSkills();

		var j = JSON.parse(_appraisal.response);
		this.response.min_salary = j.min_salary;
		this.response.max_salary = j.max_salary;
		this.response.currency = j.currency;
		this.response.description = j.description;

		this.response.category = j.category;
		this.response.skills = j.skills;
		
		$('#currency').val(this.response.currency).trigger('change');
		$('#minimount').val(this.response.min_salary);
		$('#maximount').val(this.response.max_salary);
		$('#catSelect').val(j.categoryId).trigger('change');

		this.firstLoad = true;
		if(j.categoryId==null){
			this.ReloadSkills(_appraisal.job.categoryId);
		}else{
			this.ReloadSkills(j.categoryId);
		}
		
		
		$('#edit_appraisal_modal').modal('toggle');
	}

	SetSkills(){
		this.prev_skills = [];
		var category = this.categories.find(x => x.id == this.selected_appraisal.job.categoryId);
		var j = this.selected_appraisal.job;
		var skill_ids = JSON.parse(j.skillId);
		if(category!=null){
			skill_ids.forEach(skill_id => {
				var skill = category.skills.find(x=>x.id == skill_id);
				if(skill!=null){
					this.prev_skills.push(skill);
				}
			});
		}
	}

	OpenSelection() {
		
	}


	ReloadSkills(id) {
		var self = this;
		self.response.categoryId = id;
		
		var _skills = [];

		var category = this.categories.find(x => x.id == id);
		if (category != null) {
			
			self.selected_category = category;
			self.response.category = category.categoryName;

			category.skills.forEach(_skill => {
				_skills.push({
					id: _skill.id,
					text: _skill.skillName
				});
			});

			if(!self.firstLoad){
				$('#skill_select').select2('destroy');
				$('#skill_select').empty().trigger("change");
			}
			
			setTimeout(function () {

				
				$("#skill_select").select2({
					placeholder: 'select skill',
					data: _skills
				});
				
				//$("#skill_select").val([]).trigger('change');
				
				if(self.firstLoad){
					self.firstLoad = false;
					$("#skill_select").val(self.response.skills).trigger('change');
				}else{
					$("#skill_select").val([]).trigger('change');	
				}
			}, 500);
		}
	}

	SaveAppraisalResponse(){
		toastr.clear();
		toastr.info('changes saved successfully');

		this.selected_appraisal.response = JSON.stringify(this.response);

		this._api.POST({
			load: true,
			body: this.selected_appraisal,
			showError: true,
			api_route: 'api/admin/appraisal/v1/respond', success: (data) => {
				if (data != null) {
					toastr.clear();
					toastr.success('Changes saved successfully');
				}
			},
			error: (error) => {
			}
		});
	}
}
