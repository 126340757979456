import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordComponent } from './password/password.component';
import { ResumeComponent } from './resume/resume.component';
import { PostjobComponent } from './postjob/postjob.component';
import {FormsModule} from '@angular/forms';
import { CandidatesComponent } from './candidates/candidates.component';
import { JobsComponent } from './jobs/jobs.component';

import { MyResumeComponent } from './my-resume/my-resume.component';
import { SavedJobsComponent } from './saved-jobs/saved-jobs.component';
import { PostJobComponent } from './post-job/post-job.component';
import { CompaniesComponent } from './companies/companies.component';

import { SearchPipe } from '../../_services/search.pipe';
import { AccessComponent } from './access/access.component';
import { MyJobsComponent } from './my-jobs/my-jobs.component';
import { AgentAppraisalComponent } from './agent-appraisal/agent-appraisal.component';

const routes: Routes = [
    {
        "path": "account/:page",
        "component": AccountComponent
    },{
        "path": "my-resume",
        "component": MyResumeComponent
    }
];
@NgModule({imports: [
    CommonModule,RouterModule.forChild(routes),FormsModule
],exports: [
    RouterModule
],declarations: [
    DashboardComponent,ProfileComponent, PasswordComponent, 
    ResumeComponent, PostjobComponent, CandidatesComponent, 
    JobsComponent, MyResumeComponent, SavedJobsComponent, 
    PostJobComponent, CompaniesComponent, SearchPipe, AccessComponent,
    MyJobsComponent, AgentAppraisalComponent
],providers:[
    SearchPipe
],
entryComponents: [
    DashboardComponent, ProfileComponent, PasswordComponent, 
    ResumeComponent, PostjobComponent, CandidatesComponent,
    JobsComponent, SavedJobsComponent,PostJobComponent, 
    CompaniesComponent,AccessComponent,MyJobsComponent, AgentAppraisalComponent ]
})
export class AccountModule  {

}