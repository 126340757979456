import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { EmployerAccountComponent } from './account_employer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { GalleryComponent } from './gallery/gallery.component';

import { FormsModule } from '@angular/forms';
import { CompaniesComponent } from './companies/companies.component';
import { JobsComponent } from './jobs/jobs.component';
import { CandidatesComponent } from './candidates/candidates.component';


import { EmployerSearchPipe } from '../../_services/search.pipe';

const routes: Routes = [
    {
        "path": "employer-account/:company_id/:page",
        "component": EmployerAccountComponent
    }
];
@NgModule({imports: [
    CommonModule,RouterModule.forChild(routes),FormsModule
],exports: [
    RouterModule
],declarations: [
    DashboardComponent,ProfileComponent, GalleryComponent,
    CompaniesComponent, JobsComponent, EmployerSearchPipe, CandidatesComponent
],providers:[
    EmployerSearchPipe
],
entryComponents: [
    DashboardComponent, ProfileComponent, GalleryComponent,
    CompaniesComponent, JobsComponent, CandidatesComponent ]
})
export class EmployerAccountModule  {

}