import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { AppSettingsUnilynq } from "./app-settings.service";
import { map } from 'rxjs/operators';
import { Helpers } from '../helpers';
import { AlertsPromptsLoaders } from "./alerts.loaders";


@Injectable()
export class ApiService {
    
    constructor(
		private http: Http,
        private appset: AppSettingsUnilynq,
        private alertService: AlertsPromptsLoaders
    ) { }
    
    GET(options: any) {
        if(options.load) Helpers.setLoading(true); //Set Loading - Block UI

        this.http.get(this.appset.HURL + options.api_route, this.appset.jwtJSON()).pipe(map((response) => response.json())).subscribe(data=>{
            
            if(options.load) Helpers.setLoading(false);

            options.success(data);//Success Callback function

        },error=>{
            if(options.load) Helpers.setLoading(false);
            
            if(options.error){
                options.error(error);//Error Callback function

                if(!options.showError) return;

                if(error.status==400){
                    this.alertService.alertError(error);
                }else{
                    this.alertService.alertType("Error","Couldn't complete requested changes. " + error,"error");
                }
            }
        });
    }

    POST(options:any){
        if(options.load) Helpers.setLoading(true); //Set Loading - Block UI

        this.http.post(this.appset.HURL +  options.api_route,  options.body, this.appset.jwtJSON()).pipe(map((response) => response.json())).subscribe(data=>{
        
            if(options.load) Helpers.setLoading(false);

            if(options.success){
                options.success(data);//Success Callback function
            }
        
        },error=>{
            if(options.load) Helpers.setLoading(false);
            
            if(options.error){
                options.error(error);//Error Callback function

                if(!options.showError) return;
                
                if(error.status==400){
                    this.alertService.alertError(error);
                }else{
                    this.alertService.alertType("Error","Couldn't complete requested changes. " + error,"error");
                }
            }
        });
    }
    POST2(options:any){
        if(options.load) Helpers.setLoading(true); //Set Loading - Block UI

        this.http.post(this.appset.mailChimpEndpoint,  options.body, this.appset.jwtJSON()).pipe(map((response) => response.json())).subscribe(data=>{
        
            if(options.load) Helpers.setLoading(false);

            if(options.success){
                options.success(data);//Success Callback function
            }
        
        },error=>{
            if(options.load) Helpers.setLoading(false);
            
            if(options.error){
                options.error(error);//Error Callback function

                if(!options.showError) return;
                
                if(error.status==400){
                    this.alertService.alertError(error);
                }else{
                    this.alertService.alertType("Error","Couldn't complete requested changes. " + error,"error");
                }
            }
        });
    }
}