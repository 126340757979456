import { Component, OnInit } from '@angular/core';
import { SharedResources } from '../../_services/shared.resources';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';

declare let toastr: any;


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  isAuthenticated: boolean = false;
	user: any = null;
	message: string = "";
	subject: string = "";
	fullname: string = "";
	email: string = "";
	phone: string = "";

	contact:any = {};


	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService,

	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		console.log('Authentication', this.isAuthenticated);
		console.log('user', this.user);

	}

	sendMessage() {
		if(this.contact.name == '' || this.contact.name == null){
			toastr.clear();
			toastr.warning('Please enter your name');
			return;
		}

		if(this.contact.subject == '' || this.contact.subject == null){
			toastr.clear();
			toastr.warning('Subject field cannot be empty');
			return;
		}
		
		if(this.contact.email == '' || this.contact.email == null){
			toastr.clear();
			toastr.warning('Contact email address is required!');
			return;
		}

		if(this.contact.phone == '' || this.contact.phone == null){
			toastr.clear();
			toastr.warning('Contact phone number is required!');
			return;
		}

		if(this.contact.message == '' || this.contact.message == null){
			toastr.clear();
			toastr.warning('kindly provide content for message field!');
			return;
		}

		this._api.POST({
			load: true,
			showError: true,
			body: this.contact,
			api_route: 'api/about_app/v1/send_contact_message', success: (data) => {
				if (data != null) {
					this.contact = {};
					
					this.alertService.alertType("Message Sent",
					'You have successfully send a message to jobmatch support team. We will respond in a short while.', "success");
				}
			},
			error: (error) => {
			}
		});
	}
}
