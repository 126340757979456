import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';
import { Helpers } from '../../../helpers';
/*import { StreamChat, ChannelData, Message, User } from 'stream-chat';
import axios from 'axios';*/

declare let toastr: any;
declare let $;


@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {
  
  isAuthenticated: boolean = false;
	user: any = {};
  name: string = "";
  
  jobs:any = [];
	candidates:any = [];
	selected_job:any = null;
  /*title = 'angular-chat';
  channel: ChannelData;
  username = '';
  messages: Message[] = [];
  newMessage = '';
  channelList: ChannelData[];
  chatClient: any;
  currentUser: User;

  async joinChat() {
    const { username } = this;
    try {
      const response = await axios.post('http://localhost:4200/join', {
        username,
      });
      const { token } = response.data;
      const apiKey = response.data.api_key;

      this.chatClient = new StreamChat(apiKey);

      this.currentUser = await this.chatClient.setUser(
        {
          id: username,
          name: username,
        },
        token
      );

      const channel = this.chatClient.channel('team', 'talkshop');
      await channel.watch();
      this.channel = channel;
      this.messages = channel.state.messages;
      channel.on('message.new', event => {
        this.messages = [...this.messages, event.message];
      });

      const filter = {
        type: 'team',
        members: { $in: [`${this.currentUser.me.id}`] },
      };
      const sort = { last_message_at: -1 };

      this.channelList = await this.chatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
      });
    } catch (err) {
      console.log(err);
      return;
    }
  }

  async sendMessage({text: newMessage}) {
    if (this.newMessage.trim() === '') {
      this.newMessage = '';
      return;
    }

  }*/

  constructor( public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
    private _api: ApiService
    ) { }

  ngOnInit() {
    this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
    }
    this.getCandidates();
  }

  getCandidates() {
		this._api.GET({
			api_route: 'api/userz/v2/all', success: (data) => {
        console.log(data);
				if (data != null) {
					this.jobs = data.Jobs;
					this.candidates = data.Candidates;
					//this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

}
