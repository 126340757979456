import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Helpers} from "../../helpers";
import { SharedResources } from '../../_services/shared.resources';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _router: Router,
    private _resource:SharedResources) { }

  ngOnInit() {
    
    Helpers.setLoading(true);
    localStorage.removeItem('currentUser');

    this._resource._isAuthenticated = false;
    this._resource._user = false;

    this._resource.LogoutAuthentication.next({});
    
    // reset login status
		this._router.navigate(['/login']);
  }

}
