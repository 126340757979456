import { Component, OnInit } from '@angular/core';
import { SharedResources } from '../../../_services/shared.resources';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { map } from 'rxjs/operators';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';

declare let toastr: any;
declare let $;
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";
	ehour: number = 0;
	ewage: number = 0;
	disability: string="";
	hour: number = 0;
	wage: number = 0;

	categories:any = [];
	other_info:any = {};

	gallery:any = [];
	selected_image:any = null;

	acctname:string ="";
	acctnum:number =0;
	provider:string ="";

	constructor(
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
		) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		
		if(this._resources._user!=null)
			this.user = this._resources._user;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;

			if(this._resources._user!=null)
				this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			this.SetUserValues();
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.getCategories();

		var self = this;
		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			self.user.categoryId = Id;
		});

		$('#paySelect').on("change", function () {
			var Id = $(this).val();
			self.other_info.payment_mode = Id;
		});

		this.SetUserValues();
	}

	getCategories() {
		this._api.GET({
			api_route: 'api/category/v1/all', success: (data) => {
				if (data != null) {
					this.categories = data;
					this.SetUserValues();
				}
			},
			error: (error) => {
			}
		});
	}

	SetUserValues(){
		if(this.user==null)return;

		var self = this;
		if (this.categories.length > 0) {
			this.user.categoryId = this.categories[0].id;
		}

		this.gallery = JSON.parse(this.user.gallery);

		if(this.user.other_info!=null)
			this.other_info = JSON.parse(this.user.other_info);
		setTimeout(function() {
			if(self.user.categoryId!=null){
				$('#catSelect').val(self.user.categoryId).trigger('change');
				$('#user_dob').val(self.user.dob);
				$('#paySelect').val(self.other_info.payment_mode).trigger('change');
			}
		}, 1000);
	}

	SaveUserDetails(){
		toastr.clear();
		toastr.info('Saving user profile, please wait...');
		console.log('other info', this.other_info);
		this.user.other_info = JSON.stringify(this.other_info);
		this.user.dob = $('#user_dob').val();

		this._api.POST({
			api_route: 'api/user/v2/update',
			body: this.user,
			load: true,
			success: (data) => {
				toastr.clear();
				toastr.success('Changes have been saved successfully.');
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

	calc(){
		this.hour = this.wage/160;
		this.ehour = this.ewage/160;	
		this.other_info.average_per_hour = this.other_info.monthly_rate/160;
		this.other_info.expected_average_per_hour = this.other_info.expected_monthly_rate/160;
	}




	UploadPortfolioImage(){
		
		var api = "api/user/portfolio/v1/gallery_image";
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Company Gallery Images',
            e: '#m-dropzone-one-gallery',
            pe: '#honeycomb-gallery',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Company-Gallery-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
				toastr.success('File upload successful');
				
				self.user.gallery = res.gallery;
				self.gallery = JSON.parse(res.gallery);
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Company-Gallery-modal').modal('toggle');
            }
        });
	}

	EditGalleryImage(_image){
		this.selected_image = {
			id: _image.id,
			path: _image.path,
			description: _image.description
		};
		$('#add_company_modal').modal('toggle');
	}

	saveChanges() {
		var url = 'api/user/portfolio/v1/edit_gallery_image';

		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.selected_image,
			success: (data)=>{
				
				this.user.gallery = data.gallery;
				this.gallery = JSON.parse(data.gallery);

				$('#add_company_modal').modal('toggle');
				toastr.clear();
				toastr.success('Changes to portfolio have been saved successfuly');
			},
			error: (error)=>{
				
			}
		});
	}

	confirmDelete(_image){
        var options = {
            title: "Remove Image",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deletePermanently(_image);
				}
			}
        });
    }

    deletePermanently(_image){
		console.log('delete',_image);
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/portfolio/v1/delete_gallery_image',
			body: _image,
			success: (data)=>{

				this.user.gallery = data.gallery;
				this.gallery = JSON.parse(data.gallery);

				toastr.clear();
				toastr.success('Changes to portfolio have been saved successfuly');
			},
			error: (error)=>{
				
			}
		});
	}
}
