import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-admin-edit-job-post',
	templateUrl: './admin-edit-job-post.component.html',
	styleUrls: ['./admin-edit-job-post.component.css']
})
export class AdminEditJobPostComponent implements OnInit {

	JOB_POST_SUCCESSFUL: boolean = false;
	_job: any = null;

	job: any = {
	};
	jobs: any = {};
	isNew: boolean = false;

	categories: any = [];
	catnskills: any = {};
	skills: any = [];
	mySkills: any = [];
	categoryId: number = 0;


	selected_category: any = {
		skills: []
	};
	prev_skills:any = [];

	companies:any = [];
	_company:any = {};

	_additions:any = [];
	_note:any = '';

	firstLoad:boolean = true;

	constructor(
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _route: ActivatedRoute,
		private resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.getCategory();

		var self = this;
		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			self.categoryId = Id;
			self.ReloadSkills(Id);
		});

		$('#skill_select').on('change', (e) => {
			var SSs = $(e.target).val();
			self.mySkills = SSs;
			self.job.skillId = JSON.stringify(SSs);
		});

		$('#currency').on('change', (e) => {
			var Currency = $(e.target).val();
			self.job.currency = Currency;
		});

		$('#job_type').on('change', (e) => {
			var job_type = $(e.target).val();
			self.job.job_type = job_type;
		});

		$('#salary').on('change', (e) => {
			var price = $(e.target).val();
			self.job.price = price;

			self.job.min_salary = parseInt(price) - (3*0.1*price);
			self.job.max_salary = parseInt(price) + (1*0.1*price);

			$('#minimount').val(self.job.min_salary);
			$('#maximount').val(self.job.max_salary);
		});

		$("#company_select").on("change", function () {
			var Id = $(this).val();
			self.CompanySelected(Id);
		});


		$('#career_select').on('change', (e) => {
			var career = $(e.target).val();
			self.job.career_level = career;
		});

		$('#experience_select').on('change', (e) => {
			var experience = $(e.target).val();
			self.job.experience = experience;
		});

		$('#gender_select').on('change', (e) => {
			var gender = $(e.target).val();
			self.job.gender = gender;
		});

		$('#qualification_select').on('change', (e) => {
			var qualification = $(e.target).val();
			self.job.qualification = qualification;
		});
	}

	CompanySelected(Id){
		var self = this;
		var company = this.companies.find(x=> x.id == Id);
		if(company!=null){
			this.job.company_id = Id;

			var lat_lng = [0,0];

			if(company.coordinates!=null){
				if(company.coordinates.split(',').length>0){
					lat_lng = company.coordinates.split(',');
				}
			}

			this._company = {
				id: company.id,
				name: company.name,
				description: company.description,
				address: company.address,
				location: company.location,
				region: company.region,
				lat: lat_lng[0],
				lng: lat_lng[1],
			}

			$('#region').val(company.region);
			$('#location').val(company.location);
			$('#address').val(company.address);
			$('#lat').val(self._company.lat);
			$('#lng').val(self._company.lng);
		}
	}

	getCategory() {
		this._api.GET({
			load: true,
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
					if (data.length > 0) {
						this.job.categoryId = data[0].id;
						this.ReloadSkills(data[0].id);
						this.getJobDetail();
					}
				}
			},
			error: (error) => {
			}
		});
	}

	getCompanies() {
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/user/company/v1/all', success: (data) => {
				if (data != null) {
					this.companies = data;
					if(data.length>0){
						this.CompanySelected(data[0].id);
						this.getJobDetail();
					}else{
						this.alertService.alertType("No Comapnies Found",
							'To post a job, you need to add at least 1 company to your account.', "warning");
					}
				}
			},
			error: (error) => {
			}
		});
	}

	getJobDetail() {
		//var job_id = this._route.snapshot.queryParams['ref'] || '0';
		var job_id =this._route.snapshot.paramMap.get("id");
		if(!job_id){
			this._router.navigate(['/home']);
		}

		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/admin/jobs/v1/job/'+job_id, success: (data) => {
				if (data != null) {
					
					this.prev_skills = JSON.parse(data.Job.skillId);

					this.job = data.Job;
					this.companies = data.Companies;
					if(this.companies.length>0){
						this.CompanySelected(this.companies[0].id);
					}else{
						this.alertService.alertType("No Comapnies Found",
							'To post a job, you need to add at least 1 company to your account.', "warning");
					}
					this.SetJobValues();
				}
			},
			error: (error) => {

			}
		});
	}

	SetJobValues(){
		var self = this;
		setTimeout(function() {
			
			$('#job_type').val(self.job.job_type).trigger('change');
			$('#currency').val(self.job.currency).trigger('change');
			$('#salary').val(self.job.price).trigger('change');
			$('#minimount').val(self.job.min_salary);
			$('#maximount').val(self.job.max_salary);
			
			self.firstLoad = true;
			$('#catSelect').val(self.job.categoryId).trigger('change');
			//$('#skill_select').val(_skills).trigger('change');

			$('#career_select').val(self.job.career_level).trigger('change');
			$('#experience_select').val(self.job.experience).trigger('change');
			$('#qualification_select').val(self.job.qualification).trigger('change');
			$('#gender_select').val(self.job.gender).trigger('change');

			$('#company_select').val(self.job.company_id).trigger('change');

			self._additions = JSON.parse(self.job.additional_notes);

		}, 1000);
	}

	OpenSelection() {
		console.log('OpenSelection()', this.selected_category);
	}

	ReloadSkills(id) {
		var self = this;
		self.job.categoryId = id;
		//this.selected_category = null;
		var _skills = [];

		var category = this.categories.find(x => x.id == id);
		if (category != null) {
			console.log('Hit FM', category);
			self.selected_category = category;

			category.skills.forEach(_skill => {
				_skills.push({
					id: _skill.id,
					text: _skill.skillName
				});
			});

			$('#skill_select').select2('destroy');
			$('#skill_select').empty().trigger("change");
			setTimeout(function () {
				console.log('Founder', self.selected_category);

				$("#skill_select").select2({
					placeholder: 'select skill',
					data: _skills
				});

				if(self.firstLoad){
					self.firstLoad = false;
					$("#skill_select").val(self.prev_skills).trigger('change');
				}else{
					$("#skill_select").val([]).trigger('change');	
				}
				
			}, 500);
		}
	}

	AddNote(){
		this._additions.push({message: this._note});
		this.RekeyAdditions();
		this._note = '';
	}

	RemoveNote(note){
		this._additions = this._additions.filter(x=> x.id != note.id);
		this.RekeyAdditions();
	}

	RekeyAdditions(){
		for (let i = 0; i < this._additions.length; i++) {
			const note = this._additions[i];
			note.id = (i+1);
		}
	}

	saveJob() {
		if (this.job.jobTitle == "" || this.job.jobTitle == null) {
			toastr.clear();
			toastr.error('job title cannot be empty');
			return;
		}

		if (this.job.description == "" || this.job.description == null) {
			toastr.clear();
			toastr.error('Short Description cannot be empty');
			return;
		}


		this.job.expire_at = $('#job_expires').val();

		if (this.job.expire_at == "" || this.job.expire_at == null) {
			toastr.clear();
			toastr.error('Please select due date');
			return;
		}

		if (this.job.job_type == "" || this.job.job_type == null) {
			toastr.clear();
			toastr.error('Choose Job Type cannot be empty');
			return;
		}

		if (this.job.price == "" || this.job.price == null) {
			toastr.clear();
			toastr.error('Average Salary cannot be empty');
			return;
		}

		if (this.job.min_salary <= 0 || this.job.max_salary <= 0) {
			toastr.clear();
			toastr.error('Please indicate Minimum and Maximum Salary');
			return;
		}

		if (this.mySkills.length == 0) {
			toastr.clear();
			toastr.error('Select at least one skill.');
			return;
		}

		this.job.additional_notes = JSON.stringify(this._additions);

		toastr.clear();
		toastr.info('Saving changes, please wait...');

		var url = 'api/admin/jobs/v1/edit';
		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.job,
			success: (data)=>{
				toastr.clear();
				toastr.success('Changes have been saved successfully.');
			},
			error: (error)=>{
				
			}
		});
	}

	saveChanges(){
		
	}
}
