import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../../../helpers';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';


declare let ApexCharts:any;
declare let toastr:any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	jobs:any = [];
	applications:any = [];
	companies:any = [];
	selected_job:any = null;
	savedJobs:any = [];

	_options:any = {
		newest: true,
        page: 1,
		load: 20,
		search_by: 1
	};

	summary:any = {
		Total: 0,
		Shortlisted: 0,
		Rejected: 0
	};

	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
	TotalRequests:any = 0;
	
	company_id:any = 0;

	constructor(
		private _resources: SharedResources,
		private route:ActivatedRoute,
		public appset: AppSettingsUnilynq,
		private _router: Router,
		private _api: ApiService) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this.savedJobs = this._resources._savedJobs;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		this._resources.SavedJobs.subscribe(res => {
			this.savedJobs = this._resources._savedJobs;
			this.RestructJobs();
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.company_id=this.route.snapshot.paramMap.get("company_id");

		this.getSummary();
		
		this.getMyJobs();
	}

	Graph(){
		var self = this;
		setTimeout(function() {

			var colors = ['#001935', '#ff8a00', '#17a2b8','#28a745', '#dc3545'];

			var options = {
				series: [{
				data: [self.summary.Total, self.summary.Pending, self.summary.Shortlisted, self.summary.Awarded, self.summary.Rejected]
			  }],
				chart: {
				height: 350,
				type: 'bar',
				events: {
				  click: function(chart, w, e) {
					// console.log(chart, w, e)
				  }
				}
			  },
			  colors: colors,
			  plotOptions: {
				bar: {
				  columnWidth: '45%',
				  distributed: true
				}
			  },
			  dataLabels: {
				enabled: false
			  },
			  legend: {
				show: false
			  },
			  xaxis: {
				categories: [ "Applications", "Pending", "Shortlisted",  "Awarded", "Declined"],
				labels: {
				  style: {
					colors: colors,
					fontSize: '12px'
				  }
				}
			  }
			  };
	
			var chart = new ApexCharts(
				document.querySelector("#chart"),
				options
			);
	
			chart.render();
		}, 1000);
	}

	getSummary() {
		var url = 'api/dashboard/company/v1/summary/'+this.company_id;

		this._api.GET({
			load: true,
			api_route: url, 
			success: (data) => {
				if (data != null) {
					this.summary = data;
					this.Graph();
				}
			},
			error: (error) => {
			}
		});
	}

	getMyJobs() {
		var url = 'api/dashboard/company/v1/posted_jobs/'+this.company_id;

		this._api.GET({
			load: true,
			api_route: url, 
			success: (data) => {
				if (data != null) {

					this.jobs = data.Jobs;
					this.companies = data.Companies;
					
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		
		this.jobs.forEach(j => {
			j._date = Helpers.fromNow(j.created_at);
			j._expire_at = Helpers.longShortDate(j.expire_at);
			j.fav = 'far fa-heart';

			if(j.job_type == 'Part-time')j.klass = 'part-time';
			else if(j.job_type == 'Full-time')j.klass = 'full-time';
			else if(j.job_type == 'Internship')j.klass = 'temporary';
			else j.klass = 'freelance';

			var c = this.companies.find(x=>x.id == j.company_id);
			if(c!=null){
				j.location = c.location;
				j.address = c.address;
				j.companyName = c.name;
			}

			// var fav = this.savedJobs.find(x=>x.job_id == j.id);
			// if(fav!=null){
			// 	j.fav = 'fas fa-heart text-danger';
			// }
		});
	}

	Next(){
        if(this._options.page<this.TotalPages){
            this._options.page+=1;
            this.getMyJobs();
        }
    }

    Prev(){
        if(this._options.page>1){
            this._options.page-=1;
            this.getMyJobs();
        }       
	}
	
	UpdateFavourites(_job){
		if(!this._resources._isAuthenticated){
			this._resources._returnUrl = this._router.url;
			toastr.clear();
			toastr.warning('Please login to update your favourites.')
			this._router.navigate(['/login']);
			
			return;
		}

		this._api.POST({
			load: true,
			body: _job,
			api_route: 'api/favourites/v1/update', success: (data) => {
				if (data != null) {
					
					this._resources.UpdateSavedJobs.next({});
					this.savedJobs = data;

					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}
}
