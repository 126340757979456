import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
	selector: 'app-companies',
	templateUrl: './companies.component.html',
	styleUrls: ['./companies.component.css']
})

export class CompaniesComponent implements OnInit {

	company:any = {};
	regions:any = [];
	cities:any = [];
	countries:any = [];

	companies:any = [];
	selected_company:any = null;

	isNew:boolean = true;

	_companySearch:any = '';
	categories: any = [];

	user:any = {};

	constructor(
		private http: Http,
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
	) { 

		this.countries = _resources.countries;
		this.regions = _resources.regions;
	}

	ngOnInit() {

		this._resources.UpdateAuthentication.next({});
		this._resources.AuthenticationStatus.subscribe(res=>{
			if(!this._resources._isAuthenticated) return;
			if(this._resources._user==null)return;

			this.user = this._resources._user;
			if(!this.user.is_job_giver){
				toastr.info('You need to request recruitment feature to post jobs.');
				this._router.navigate(['/account/access']);
				this._resources.AlertPageChange.next({});
			};
		});

		this.getCompanies();
		//this.getRegionsAndCities();
		this.getCategory();

		var self = this;

		$('#country_select').on('change', (e) => {
			var country = $(e.target).val();
			$('#regionElement').show();
			if(country!="GH"){
				$('#regionElement').hide();
			}
			self.company.country = country;
		});

		$('#registration_giver').on('change', (e) => {
			var giver_type = $(e.target).val();
			self.company.job_giver_type = giver_type;
		});
		
		// $('#region_select').on('change', (e) => {
		// 	var region = $(e.target).val();
		// 	self.user.Region = region;
		// });

		$("#company_region").on("change", function () {
			var Id = $(this).val();
			self.company.region = Id;
			self.ReloadCities(Id);
		});

		$('#company_location').on('change', (e) => {
			var SSs = $(e.target).val();
			self.company.location = SSs;
		});

		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			self.company.category = Id;
		});
	}

	AddNewCompany(){
		this.isNew = true;
		$('#add_company_modal').modal('toggle');

		$('#country_select').val('GH').trigger("change");
		$('#company_region').val('Greater Accra Region').trigger("change");
		$('#registration_giver').val('SME').trigger("change");
	}

	getRegionsAndCities() {
		this._api.GET({
			api_route: 'api/user/company/v1/regions', success: (data) => {
				if (data != null) {
					this.regions = data.Regions;
					this.cities = data.Cities;

					this.ReloadCities(data.Regions[0].region1);
				}
			},
			error: (error) => {
			}
		});
	}

	getCompanies() {
		this._api.GET({
			api_route: 'api/user/company/v1/all', success: (data) => {
				if (data != null) {
					this.companies = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getCategory() {
		this._api.GET({
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
					if (data.length > 0) {
						this.company.category = data[0].categoryName;
					}
				}
			},
			error: (error) => {
			}
		});
	}

	ReloadCities(region,my_city = ''){
		var self = this;
		//this.selected_category = null;
		var _cities = [];

		var cities = this.cities.filter(x=>x.region == region);
		cities.forEach(_city => {
			_cities.push({
				id: _city.name,
				text: _city.name
			});

			if(my_city!=null){
				my_city = _city.name;
			}
		});

		
		setTimeout(function(){
			$('#company_location').select2('destroy');
			$('#company_location').empty().trigger("change");
			
			$("#company_location").select2({ 
				placeholder: 'Select Location',
				data: _cities
			});
			$("#company_location").val(my_city).trigger('change');
		}, 1000);
	}

	EditCompany(company){
		var lat_lng = [0,0];

		if(company.coordinates!=null){
			if(company.coordinates.split(',').length>0){
				lat_lng = company.coordinates.split(',');
			}
		}

		this.company = {
			id: company.id,
			name: company.name,
			description: company.description,
			address: company.address,
			location: company.location,
			region: company.region,
			lat: lat_lng[0],
			lng: lat_lng[1],
			website: company.website,
			email: company.email,
			phone: company.phone,
			team_size: company.team_size,
			category: company.category,
			other: company.other,
			country: company.country,
			job_giver_type: company.job_giver_type
		};
		this.isNew = false;
		$('#add_company_modal').modal('toggle');
		$("#company_region").val(company.region).trigger('change');
		this.ReloadCities(company.region,company.location);
		
	}


	saveChanges() {
		var url = 'api/user/company/v1/new';
		if(!this.isNew) url = 'api/user/company/v1/edit';

		this.company.coordinates = this.company.lat + ','+this.company.lng;

		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.company,
			success: (data)=>{
				if(this.isNew){
					this.companies.push(data);
				}
				$('#add_company_modal').modal('toggle');
				
				this.company = {};
				this.ReloadCities(this.regions[0].region1);
				if (this.categories.length > 0) {
					this.company.category = this.categories[0].categoryName;
				}

				this.alertService.alertType("Changes Successfully",
					'You have successfully updated your company\'s info.', "success");
			},
			error: (error)=>{
				
			}
		});
	}

	confirmDelete(_company){
        var options = {
            title: "Remove "+_company.name,
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deletePermanently(_company);
				}
			}
        });
    }


    deletePermanently(_clas){
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/company/v1/delete',
			body: this.company,
			success: (data)=>{
				if(this.isNew){
					this.companies.push(data);
				}
				$('#add_company_modal').modal('toggle');
				this.company = {};
				this.alertService.alertType("Changes Successfully",
					'You have successfully updated your company\'s info.', "success");
			},
			error: (error)=>{
				
			}
		});
	}
	
	UploadImage(_company){
		this.selected_company = _company;
		var api = "api/user/company/v1/change_image/"+_company.id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Company Files',
            e: '#m-dropzone-one-author',
            pe: '#honeycomb-author',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Company-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
                toastr.success('File upload successful');
				var company = self.companies.find(x=>x.id == res.id);
				if(company!=null){
					company.image = res.image;
				}
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Company-modal').modal('toggle');
            }
        });
	}
}
