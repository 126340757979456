import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';

import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

	company:any = {};
	regions:any = [];
	cities:any = [];

	companies:any = [];
	selected_company:any = null;

	isNew:boolean = true;

	_companySearch:any = '';
	categories: any = [];

	company_id:any = 0;

	_filter:any = {
		newest: true,
        status_ids: [],
		min_amount: 0,
		max_amount: 0,
		paid_type: 1,
		search: '',
		start_date: '',
		end_date: '',
        page: 1,
		load: 15,
		search_by: 1
	};

	agent_filter:any = {
		newest: true,
        email: '',
        page: 1,
		load: 250,
		search_by: 1
	};

	status_ids:any = {
		pending: true,
		approved: true,
		declined: true,
		error: true,
	};

	agents:any = [];
	responseCounts:any = [];


	payments:any = [];
	jobs:any = [];

	selected_appraisal:any = null;

	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
	TotalRequests:any = 1;

	statuses:any = [{id: 1, name: 'Accepting Applications'},{id: 2, name: 'Reviewing'},{id: 3, name: 'Match Found'}];

	summary:any = {
		Pending: 0,
		Approved: 0,
		Denied: 0,
		PassiveDenial: 0
	};
	_jobSearch:any = '';

	firstLoad:boolean = false;
	skills: any = [];
	prev_skills:any = [];
	selected_category: any = {
		skills: []
	};
	response:any = {
		currency: 'GHS',
		min_salary: 0,
		max_salary: 0,
		categoryId: 0,
		category: '',
		skills: []
	};

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {

		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if(Id=='Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getPayments();
		});

		this._resources.UpdateAuthentication.next({});

		this.getPaymentSummary();
		this.getPayments();
	}


	getPaymentSummary() {

		this._api.GET({
			api_route: 'api/admin/payments/v1/summary', success: (data) => {
				if (data != null) {
					this.summary = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getPayments() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/admin/payments/v1/history', success: (data) => {
				if (data != null) {
					
					this.payments = data.Requests;
					this.jobs = data.Jobs;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.payments.length;// (this._filter.page * this._filter.load);
					
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		
		this.payments.forEach(pay => {
			pay._date_created = Helpers.fromNow(pay.date_created);
			pay._date = Helpers.longShortDate(pay.date_created);
			pay._amount  = Helpers.formatMoney(pay.amount,2,'.',',');
			pay.job = {};

			var j = this.jobs.find(x=>x.id == pay.job_id);
			if(j!=null){
				this.JobRestruct(j);
				pay.job = j;
				pay.jobTitle = j.jobTitle;
				pay.companyName = j.companyName;
			}
		});
	}

	JobRestruct(_job){
		_job.descriptions = _job.description.split('/n');
		_job._date = Helpers.fromNow(_job.created_at);
		_job._expire_at = Helpers.longShortDate(_job.expire_at);
		
		_job._expires = Helpers.fromNow(_job.expire_at);

		_job._price = Helpers.formatMoney(_job.price,0,'.',',');
		_job._min = Helpers.formatMoney(_job.min_salary,0,'.',',');
		_job._max = Helpers.formatMoney(_job.max_salary,0,'.',',');

		_job.payment = {};

		var p = this.payments.find(x=>x.job_id == _job.id);
		if(p!=null){
			_job.payment = p;
		}
	}

	SetOptions(){

		this._filter.load = $('#load_select').val();
		this._filter.status_ids = [];

		if(this.status_ids.pending) {
			this._filter.status_ids.push(0);
			this._filter.status_ids.push(4);
		}
		if(this.status_ids.approved) this._filter.status_ids.push(1);
		if(this.status_ids.declined) this._filter.status_ids.push(2);
		if(this.status_ids.error) this._filter.status_ids.push(3);

		this._filter.NOFILTER = true;
		if(this._filter.start_date!='' && this._filter.end_date!=null)this._filter.NOFILTER = false;

		if(this._filter.status_ids.length>0)this._filter.NOFILTER = false;
		if(this._filter.min_amount!=0 || this._filter.max_amount!=0)this._filter.NOFILTER = false;
		
	}

	Next(){
        if(this._filter.page<this.TotalPages){
            this._filter.page+=1;
            this.getPayments();
        }
    }

    Prev(){
        if(this._filter.page>1){
            this._filter.page-=1;
            this.getPayments();
        }       
	}
	
	ApplyFilter(){
        this._filter.page = 1;
		this.getPayments();    
	}

	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Assigned';
		if(status_id == 3) status = 'Completed'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'bg-dark';
		if(status_id == 3) status = 'badge-info'; 

		return status;
	}

	getJobPosts(){
		
	}

}
