import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';

import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.css']
})
export class JobStatusComponent implements OnInit {


	_filter:any = {
		newest: true,
        status_ids: [],
		min_amount: 0,
		max_amount: 0,
		paid_type: 1,
		search: '',
		start_date: '',
		end_date: '',
        page: 1,
		load: 15,
		search_by: 1
	};


	status_ids:any = {
		accepting: true,
		reviewing: true,
		completed: true,
	};

	users_awarded:any = [];
	applicants_awarded:any = [];

	applicants:any = [];
	user_applicants:any = [];

	jobs:any = [];
	users:any = [];

	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
	TotalRequests:any = 1;

	statuses:any = [{id: 1, name: 'Accepting Applications'},{id: 2, name: 'Reviewing'},{id: 3, name: 'Match Found'}];


	selected_job:any = null;

	_applicantSearch:string = '';

	summary:any = {
		Pending: 0,
		Approved: 0,
		Denied: 0,
		PassiveDenial: 0
	};
	_jobSearch:any = '';

	
	status:any = {
		pending: true,
		shortlisted:true,
		awarded: true,
		denied: true,
		passive_denial:true
	};

	totals:any = {
		pending: 0,
		shortlisted:0,
		awarded: 0,
		denied: 0,
	};

	additionalTransactionFees:any = 0;
	totalJobPostSalary:any = 0;
	GETFUND_NHIL:any = 0;
	COVID_LEVY:any = 0;
	VAT:any = 0;
	expresspayPercentageCharge:any = 0;
	
	_additionalTransactionFees:any = 0;
	_totalJobPostSalary:any = 0;
	_totalJobPostSalaryManual:any = 0;
	_GETFUND_NHIL:any = 0;
	_COVID_LEVY:any = 0;
	_VAT:any = 0;
	

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {

		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if(Id=='Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getJobPosts();
		});

		this._resources.UpdateAuthentication.next({});

		this.getJobPostSummary();
		this.getJobPosts();
	}


	getJobPostSummary() {

		this._api.GET({
			api_route: 'api/admin/jobs/v1/summary', success: (data) => {
				if (data != null) {
					this.summary = data;
					this.expresspayPercentageCharge = data.ExpresspayPercentageCharge;
				}
			},
			error: (error) => {
			}
		});
	}

	getJobPosts() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/admin/jobs/v1/posts', success: (data) => {
				if (data != null) {
					

					this.users_awarded = data.Users;
					this.applicants_awarded = data.Applicants;
					this.jobs = data.Requests;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.jobs.length;// (this._filter.page * this._filter.load);
					
					this.RestructJobs();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructJobs(){
		
		this.jobs.forEach(j => {
			this.JobRestruct(j);
		});
	}

	JobRestruct(_job){
		_job.descriptions = _job.description.split('/n');
		_job._date = Helpers.fromNow(_job.created_at);
		_job._expire_at = Helpers.longShortDate(_job.expire_at);
		
		_job._expires = Helpers.fromNow(_job.expire_at);

		_job._price = Helpers.formatMoney(_job.price,0,'.',',');
		_job._min = Helpers.formatMoney(_job.min_salary,0,'.',',');
		_job._max = Helpers.formatMoney(_job.max_salary,0,'.',',');

		_job.applicants = this.applicants_awarded.filter(x=>x.job_id == _job.id);
		_job.applicants.forEach(applicant => {
			applicant.user = this.users_awarded.find(x=>x.id == applicant.user_id);
			applicant._last_modified = Helpers.fromNow(applicant.last_modified);
		});
	}

	SetOptions(){

		this._filter.load = $('#load_select').val();
		this._filter.status_ids = [];

		if(this.status_ids.accepting) this._filter.status_ids.push(1);
		if(this.status_ids.reviewing) this._filter.status_ids.push(2);
		if(this.status_ids.completed) this._filter.status_ids.push(3);

		this._filter.NOFILTER = true;
		if(this._filter.start_date!='' && this._filter.end_date!=null)this._filter.NOFILTER = false;

		if(this._filter.status_ids.length>0)this._filter.NOFILTER = false;
		if(this._filter.min_amount!=0 || this._filter.max_amount!=0)this._filter.NOFILTER = false;
		
	}

	Next(){
        if(this._filter.page<this.TotalPages){
            this._filter.page+=1;
            this.getJobPosts();
        }
    }

    Prev(){
        if(this._filter.page>1){
            this._filter.page-=1;
            this.getJobPosts();
        }       
	}
	
	ApplyFilter(){
        this._filter.page = 1;
		this.getJobPosts();    
	}

	/*GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Assigned';
		if(status_id == 3) status = 'Completed'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'bg-dark';
		if(status_id == 3) status = 'badge-info'; 

		return status;
	}*/

	//Job Applicants
	RecalculateApplicantsMatch(){
		var job_id = this.selected_job.id;
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/admin/jobs/v1/calculate_match/'+job_id, success: (data) => {
				if (data != null) {
					
					this.selected_job = data.Job;
					this.applicants = data.Applicants;
					this.users = data.Users;
					
					this.RestructApplicants();
					
				}
			},
			error: (error) => {
			}
		});
	}

	getJobApplicants(job_id) {
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/admin/jobs/v1/applicants/'+job_id, 
			success: (data) => {
				if (data != null) {
					
					this.selected_job = data.Job;
					this.applicants = data.Applicants;
					this.users = data.Users;
					
					this.RestructApplicants();
					$('#job_applicants_modal').modal('toggle');
					
				}
			},
			error: (error) => {
			}
		});
	}

	RestructApplicants(){
		
		this.user_applicants = [];
		this.totals = {
			pending: 0,
			shortlisted:0,
			awarded: 0,
			denied: 0,
		};

		this.applicants.forEach(a => {

			var _j = this.users.find(x=>x.id == a.user_id);
			if(_j!=null){
				var j:any = {
					firstName: _j.firstName,
					lastName: _j.lastName,
					email: _j.email,
					phone: _j.phone,
					image: _j.image
				};
				j._date = Helpers.fromNow(j.date);
				j.__date = Helpers.longShortDate(j.date);

				j._last_modified = Helpers.fromNow(j.last_modified);
				j.__last_modified = Helpers.longShortDate(j.last_modified);

				j.id = a.id;
				j._status = this.GetJobStatus(a.status);
				j.status = a.status;
				j.klass = this.GetJobStatusClass(a.status);
				j.job_id = a.job_id;
				j.match = a.match;
				j.user_id = a.user_id;
				j.applicant_message = a.applicant_message;
				j.employer_message = a.employer_message;

				if(a.status == 1){ 
					this.totals.pending+=1;
					if(this.status.pending) this.user_applicants.push(j);
				}

				if(a.status == 2){ 
					this.totals.shortlisted+=1;
					if(this.status.shortlisted) this.user_applicants.push(j);
				}
				
				if(a.status == 3){ 
					this.totals.awarded+=1;
					if(this.status.awarded) this.user_applicants.push(j);
				}

				if(a.status == 5 || a.status == 4){ 
					this.totals.denied+=1;
					if(this.status.denied) this.user_applicants.push(j);
				}
			}
		});

	}

	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Shortlisted';
		if(status_id == 3) status = 'Awarded';
		if(status_id == 4) status = 'Denied';
		if(status_id == 5) status = 'Passive Denial'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'badge-info';
		if(status_id == 3) status = 'badge-success';
		if(status_id == 4) status = 'badge-danger';
		if(status_id == 5) status = 'badge-primary'; 

		return status;
	}

	UploadImage(theJob){
		this.selected_job = theJob;
		var api = "api/admin/jobs/v1/change_job_image/"+theJob.id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Job Files',
            e: '#m-dropzone-one-admin-job-post',
            pe: '#honeycomb-admin-job-post',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Job-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
                toastr.success('File upload successful');
				var job = self.jobs.find(x=>x.id == res.id);
				if(job!=null){
					job.image = res.image;
				}
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Job-modal').modal('toggle');
            }
        });
	}

	UpdateStatus(_job){
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/admin/jobs/v1/update_status',
			body: _job,
			success: (data)=>{
				
				_job.enabled = !_job.enabled;

				toastr.clear();
				toastr.success('changes saved successfully');
			},
			error: (error)=>{
				
			}
		});
	}


	RecalculateJobTotal(){
		var SubTotal = this.selected_job.price * this.selected_job.total_awardees;
		this.GETFUND_NHIL = SubTotal * 0.05;
		this.COVID_LEVY = SubTotal * 0.01;

		var MidSub = SubTotal + this.GETFUND_NHIL + this.COVID_LEVY;

		this.VAT = MidSub * 0.125;

		var grandTotal = MidSub + this.VAT;

		this.totalJobPostSalary = (grandTotal) / (1-this.expresspayPercentageCharge);
		this.additionalTransactionFees = this.totalJobPostSalary * this.expresspayPercentageCharge;

		this._additionalTransactionFees = Helpers.formatMoney(this.additionalTransactionFees,2,'.',',');
		this._totalJobPostSalary = Helpers.formatMoney(this.totalJobPostSalary,2,'.',',');
		this._GETFUND_NHIL = Helpers.formatMoney(this.GETFUND_NHIL,2,'.',',');
		this._VAT = Helpers.formatMoney(this.VAT,2,'.',',');
		this._COVID_LEVY = Helpers.formatMoney(this.COVID_LEVY,2,'.',',');
		this._totalJobPostSalaryManual = Helpers.formatMoney(grandTotal,2,'.',',');
	}

	JobPaymentOnline(_job){
		this.selected_job = _job;
		if(_job.price <=0){
			toastr.clear();
			toastr.warning('Kindly edit the price for the job. Price cannot be zero or less');
			return;
		}
		this.RecalculateJobTotal();

		$('#job_post_payment_online_modal').modal('toggle');
	}


	GetPaymentToken(){

		$('#job_post_payment_online_modal').modal('toggle');
		
		toastr.clear();
		toastr.info('Connecting to payment portal, please wait');
		
		var j = this.selected_job;
		var ta = j.total_awardees;
		var sbx = this.appset.ExpresspaySandbox;

		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/payment/v1/job/token/'+j.id+'/'+ta+'/'+sbx,
			success: (data)=>{
				console.log('Express pay infomation',data);
				
				if(data.token_status == 1){
					var checkout_link = 'https://expresspaygh.com/api/checkout.php?token='+data.token;
					//this._router.navigate([checkout_link]);

					toastr.clear();
					toastr.success('changes saved successfully');

					var newWindow = window.open(checkout_link, '_blank');
					newWindow.focus();
				}else{
					toastr.clear();
					toastr.error(data.token_status_text);
				}
			},
			error: (error)=>{
				
			}
		});
	}

	confirmManualPayment(){
		var options = {
            title: "Record "+ this.selected_job.jobTitle + ": job as paid",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Record Payment!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e == true){
					self.RecordManualPayment();
				}
			}
        });
    }

	RecordManualPayment(){

		$('#job_post_payment_online_modal').modal('toggle');
		
		toastr.clear();
		toastr.info('Recording payment, please wait');
		
		var j = this.selected_job;
		this._api.POST({
			load: true,
			showError: true,
			body: j,
			api_route: 'api/admin/payments/v1/complete',
			success: (data)=>{
				
				if(data && data.Job){
					let job = this.jobs.find(x=>x.id == data.Job.id);
					if(job!=null){
						job.paid = data.Job.paid;
						job.total_awardees = data.Job.total_awardees;
						job.amount_paid = data.Job.amount_paid;
					}

					toastr.clear();
					toastr.success('Payment successfully recorded');
				}
			},
			error: (error)=>{
				
			}
		});
	}
}
