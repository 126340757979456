import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from '../../helpers';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

declare let $: any;
declare let toastr: any;

@Component({
	selector: 'app-employers',
	templateUrl: './employers.component.html',
	styleUrls: ['./employers.component.css']
})
export class EmployersComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";
	savedJobs: any = [];

	_jobSearch: string = "";

	regions: any = [];
	categories: any = [];

	jobs: any = [];
	companies: any = [];
	selected_job: any = null;

	_filter: any = {
		newest: true,
		job_types: [],
		regions: [],
		categories: [],
		page: 1,
		load: 15,
		search_by: 1,
		search: ''
	};

	qualification: any = {};
	gender: any = {};
	experience: any = {};
	jobType: any = {};

	TotalPages: any = 1;
	Starting: any = 1;
	Ending: any = 1;
	TotalRequests: any = 1;

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		$(".modal").on("shown.bs.modal", function () {
			$('#back-to-top').click();
		});

		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if (Id == 'Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getEmployers();
		});

		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this.savedJobs = this._resources._savedJobs;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
		});

		this._resources.SavedJobs.subscribe(res => {
			this.savedJobs = this._resources._savedJobs;
			this.RestructCompanies();
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.getParams();
		this.getEmployers();
	}

	getParams() {
		var url = 'api/company/search/v1/params';

		this._api.GET({
			load: true,
			api_route: url, 
			success: (data) => {
				if (data != null) {
					this.regions = data.Regions;
					this.categories = data.Categories;
				}
			},
			error: (error) => {
			}
		});
	}

	OpenAlertSettings() {
		$('#job_alert_modal').modal('toggle');
	}

	getEmployers() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/company/search/v1/find', success: (data) => {
				if (data != null) {
					console.log('Companies',data);
					
					this.jobs = data.Jobs;
					this.companies = data.Companies;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;

					this.Starting = ((this._filter.page - 1) * this._filter.load) + 1;
					this.Ending = (this.Starting - 1) + this.jobs.length;// (this._filter.page * this._filter.load);

					this.RestructCompanies();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructCompanies() {

		this.companies.forEach(c => {
			
			var j = this.jobs.find(x => c.id == x.company_id);
			if (j != null) {
				c.positions = j.Total;
			}
		});
	}

	SetOptions() {

		this._filter.regions = [];

		this.regions.forEach(region => {
			if(region.selected){
				this._filter.regions.push(region.region1);
			}
		});

		this._filter.categories = [];

		this.categories.forEach(category => {
			if(category.selected){
				this._filter.categories.push(category.categoryName);
			}
		});
		
		this._filter.NOFILTER = true;

		if (this._filter.regions.length > 0) this._filter.NOFILTER = false;
		if (this._filter.categories.length > 0) this._filter.NOFILTER = false;
	}

	Next() {
		if (this._filter.page < this.TotalPages) {
			this._filter.page += 1;
			this.getEmployers();
		}
	}

	Prev() {
		if (this._filter.page > 1) {
			this._filter.page -= 1;
			this.getEmployers();
		}
	}

	ApplyFilter() {
		this._filter.page = 1;
		this.getEmployers();
	}
}
