import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { SharedResources } from '../../../_services/shared.resources';

declare let $: any;
declare let toastr:any;

@Component({
	selector: 'app-resume',
	templateUrl: './resume.component.html',
	styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";
	education: any = [];
	awards: any = [];
	workexperience: any = [];
	skills: any = ["Web development", "HR", "Data Science", "Machine Learning"];
	userSkills: any = [];
	resume: any = {};
	edu: any = [];
	wrk: any = [];
	awd: any = [];
	skl: any = [];
	years: any = [];
	coverLetter: string = "";

	selected_skill:any = null;

	constructor(private _resources: SharedResources,
		private _api: ApiService,
		private alertService: AlertsPromptsLoaders) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.InitSelectOptions();

		this.getResume();
		this.InitializeYears();
		this.getSkill();
	}

	InitSelectOptions() {
		var self = this;
		$("#skillId").on("change", function () {
			var Id = $(this).val();
			self.selected_skill = self.skills.find(x=>x.id == Id);
		});

		$("#yearFrom").on("change", function () {
			var From = $(this).val();
			self.edu.Year_from = From;
		});

		$("#yearTo").on("change", function () {
			var To = $(this).val();
			self.edu.Year_to = To;
		});
	}

	InitializeYears() {
		for (var i = 1980; i < 2022; i++) {
			this.years.push(i);


		}
	}

	getResume() {
		this._api.GET({
			api_route: 'api/resume/v1/resume', success: (data) => {
				
				if (data.myresume != null) {
					this.resume = data.myresume;
					this.ParseResume();
				}

			},
			error: (error) => {
			}
		});
	}

	getSkill() {
		this._api.GET({
			api_route: 'api/resume/v1/all_skills', success: (data) => {
				
				this.skills = data;
			},
			error: (error) => {
			}
		});
	}

	ParseResume() {
		this.education = JSON.parse(this.resume.education);
		this.awards = JSON.parse(this.resume.awards);
		this.workexperience = JSON.parse(this.resume.workexperience);
		this.userSkills = JSON.parse(this.resume.skills);

		this.userSkills.forEach(sk => {
			var s = this.skills.find(x=>x.id == sk.id);
			if(s!=null){
				sk.skillName = s.skillName;
			}
		});

		$("#cvLetter").val(this.resume.coverLetter);
	}

	saveResume() {
		this.resume.education = JSON.stringify(this.education);
		this.resume.workexperience = JSON.stringify(this.workexperience);
		this.resume.awards = JSON.stringify(this.awards);
		this.resume.skills = JSON.stringify(this.userSkills);
		this.resume.coverLetter = $("#cvLetter").val();
		
		//return;
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/resume/v1/new',
			body: this.resume,
			success: (data) => {
				//this.resume = {};
				
				this.alertService.alertType("Resume Updated Successfully",
					'You have successfully updated your resume.', "success");

				this._resources.UpdateResumeCompleted.next({});
			},
			error: (error) => {

			}
		});
	}

	AddEducation() {
		this.education.push({
			Title: this.edu.Title,
			Institution: this.edu.Institution,
			Year_from: this.edu.Year_from,
			Year_to: this.edu.Year_to,
			Description: this.edu.Description
		});
		this.edu = {};
		
		for (var i = 0; i < this.education.length; i++) {
			this.education[i].Id = (i + 1);
		}
		this.saveResume();
	}

	EditEducation(_edu){
		var self = this;

		setTimeout(function(){
			
			$("#year_from_"+_edu.Id).on("change", function () {
				var From = $(this).val();
				var edu = self.education.find(x=>x.Id == _edu.Id);
				if(edu!=null){
					edu.Year_from = From;
				}
			});

			$("#year_to_"+_edu.Id).on("change", function () {
				var To = $(this).val();
				var edu = self.education.find(x=>x.Id == _edu.Id);
				if(edu!=null){
					edu.Year_to = To;
				}
			});

			$('#year_from_'+_edu.Id).val(_edu.Year_from).trigger('change');
			$('#year_to_'+_edu.Id).val(_edu.Year_to).trigger('change');	
		}, 2000);
		
	}

	deleteEducation(_edu) {
		this.education = this.education.filter(x => x.Id != _edu.Id);
		for (var i = 0; i < this.education.length; i++) {
			this.education[i].Id = (i + 1);
		}
	}

	AddAward() {
		this.awards.push({
			Title: this.awd.Title,
			Institution: this.awd.Institution,
			Year_to: $("#award-date-to").val(),	
			Year_from: $("#award-date-from").val(),
			Description: this.awd.Description
		});
		this.awd = {};
		for (var i = 0; i < this.awards.length; i++) {
			this.awards[i].Id = (i + 1);
		}
		this.saveResume();
	}

	deleteAward(_awd) {
		this.awards = this.awards.filter(x => x.Id != _awd.Id);
		for (var i = 0; i < this.awards.length; i++) {
			this.awards[i].Id = (i + 1);
		}
	}

	AddWork() {
		this.workexperience.push({
			Title: this.wrk.Title,
			Institution: this.wrk.Institution,
			//Year_from: this.wrk.Year_from ,
			Year_to: $("#workDateYearTo").val(),	
			Year_from: $("#workDateYearFrom").val(),
			Description: this.wrk.Description
		});
		this.wrk = {};
		for (var i = 0; i < this.workexperience.length; i++) {
			this.workexperience[i].Id = (i + 1);
		}
		this.saveResume();
	}

	deleteWork(_wrk) {
		this.workexperience = this.workexperience.filter(x => x.Id != _wrk.Id);
		for (var i = 0; i < this.workexperience.length; i++) {
			this.workexperience[i].Id = (i + 1);
		}
	}

	AddSkill() {
		this.skl = $("#skillId").val();
		var s = this.selected_skill;

		var in_it = this.userSkills.find(x=>x.id == s.id);
		if(in_it!=null){
			toastr.clear();
			toastr.warning('You have already added skill to list');
			return;
		}

		var new_skill = {
			id: s.id,
			skillName: s.skillName,
			strength: 75
		};

		this.userSkills.push(new_skill);
		
		this.skl = {};
		
		this.saveResume();
	}

	deleteSkill(_skl) {
		this.userSkills = this.userSkills.filter(x => x.id != _skl.id);
		//this.saveResume();
	}

	UpdateEducation(_edu) {
		var e = this.education.find(x => x.Id == _edu.Id);
		if (e != null) {

		}
	}

	SetWorkDates(){
		var lop = $('#dateYear').val();
	}


	UploadYourCV(){
		
		var api = "api/user/v2/change_cv";
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'User CV',
            e: '#m-dropzone-one-cv',
            pe: '#honeycomb-cv',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Your-CV-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
				toastr.success('File upload successful');
				
				self.user.cv = res.cv;
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 1.0MB and in the following extensions (.pdf, .docx, .doc, .txt).","error");
            },
            i:function (err){
                $('#UploadFile-Your-CV-modal').modal('toggle');
            }
        });
	}
}
