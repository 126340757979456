import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
//declare let moment:any;
declare let $;

@Component({
	selector: 'app-postjob',
	templateUrl: './postjob.component.html',
	styleUrls: ['./postjob.component.css']
})


export class PostjobComponent implements OnInit {
	job: any = {};
	jobs: any = {};
	isNew: boolean = false;
	
	categories: any = [];
	catnskills: any = {};
	skills: any = [];
	mySkills: any = [];
	categoryId: number = 0;


	selected_category:any = {
		skills: []
	};

	constructor(
		private http: Http,
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.getCategory();
		//this.getSkill();
		//console.log($("#select2-uvw2-container").val());
		//$(".basic-select").select2();
		var self = this;
		$("#catSelect").on("change", function () {
			var Id = $(this).val();
			console.log('Changed',Id);
			self.categoryId = Id;
			self.ReloadSkills(Id);
		});

		$('#skill_select').on('change', (e) => {
			var SSs = $(e.target).val();
			console.log('Skills Selected', SSs);
		});
	}

	getCategory() {
		this._api.GET({
			api_route: 'api/job/v1/categories', success: (data) => {
				if (data != null) {
					this.categories = data;
					console.log('Categories',data);
					if(data.length>0){
						this.ReloadSkills(data[0].id);
					}
				}
			},
			error: (error) => {
			}
		});
	}

	OpenSelection(){
		console.log('OpenSelection()',this.selected_category);
	}

	ReloadSkills(id){
		var self = this;
		//this.selected_category = null;
		var _skills = [];

		var category = this.categories.find(x=>x.id == id);
		if(category!=null){
			console.log('Hit FM', category);
			self.selected_category = category;

			category.skills.forEach(_skill => {
				_skills.push({
					id: _skill.id,
					text: _skill.skillName
				});
			});

			$('#skill_select').select2('destroy');
			$('#skill_select').empty().trigger("change");
			setTimeout(function(){
				console.log('Founder', self.selected_category);
				
				$("#skill_select").select2({ 
					placeholder: 'select skill',
					data: _skills
				});
				$("#skill_select").val([]).trigger('change');
			}, 500);
		}
	}

	getSkill() {
		this._api.GET({
			api_route: 'api/user/v2/allskills', success: (data) => {
				if (data != null) {
					this.catnskills = data;
					this.skills = this.catnskills.Skills;
					console.log("hey", this.skills);
					//this.indiSkill(this.categoryId);
				}

			},
			error: (error) => {
			}
		});
	}

	indiSkill(ca) {
		this.skills.forEach(item => {
			if (item.categoryId == ca)
				this.mySkills.push(item);
		});
		console.log("mySkills", this.mySkills);

	}

	onChange(deviceValue) {
		console.log(deviceValue);
		console.log($("#select2-uvw2-container").val);
	}



	saveJob() {
		if (this.job.jobTitle == "" || this.job.jobTitle == null) {
			toastr.clear();
			toastr.error('job title cannot be empty');
			return;
		}

		if (this.job.description == "" || this.job.description == null) {
			toastr.clear();
			toastr.error('Short Description cannot be empty');
			return;
		}

		this.job.categoryID = JSON.stringify($('#category_tags').tagsinput('items'));
		this.job.categoryID = JSON.stringify($('#skill_tags').tagsinput('items'));

		toastr.clear();
		toastr.info('Creating a new Job, please wait...');
		var which = 'api/user/job/v1/new';
		if (!this.isNew) {
			which = 'api/user/job/v1/edit';
		}
		this.http.post(this.appset.HURL + which, this.job, this.appset.jwtJSON())
			.pipe(map((response) => response.json())).subscribe(data => {

				//$('#job-modal').modal('toggle');
				this.job = {};

				if (this.isNew) {
					toastr.clear();
					toastr.success('New job Created successfully');


					this.alertService.alertType("New job Added",
						"You have successfully added " + data.jobTitle +
						" to your job catalog" +
						".", "success");

					this.jobs.push(data);
				} else {
					toastr.clear();
					toastr.success('job updated successfully');

					for (let i = 0; i < this.jobs.length; i++) {
						const job = this.jobs[i];
						if (job.id == data.id) {

							job.jobTitle = data.jobTitle;
							job.description = data.description;
							job.lastModified = data.lastModified;
							job.expire_at = data.expire_at;
							job.price = data.price;
							job.skillId = data.skillId;
							job.enabled = data.enabled;
							job.image = data.image;
							job.video = data.video;
							job.categoryId = data.categoryId;
							job.other = data.other;
							break;
						}
					}

					this.alertService.alertType("job Updated Successfully",
						data.name +
						" job has been updated successfully.", "success");
				}
			}, error => {
				Helpers.setLoading(false);
				//this.
				console.log('EROR', error);
				if (error.status == 400) {
					this.alertService.alertError(error);
				} else {
					this.alertService.alertType("Error", "Couldn't complete requested changes. " + error, "error");
				}
			});
	}


}
