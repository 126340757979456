import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
  selector: 'app-post-news',
  templateUrl: './post-news.component.html',
  styleUrls: ['./post-news.component.css']
})
export class PostNewsComponent implements OnInit {

  FEED_POST_SUCCESSFUL: boolean = false;
	_feed: any = null;

	feed: any = {
		feedTitle: "GHS",
		description: 'Male',
		feed_posted: 1000,
		job_type: 'Full-time',
		career_level: 'Entry Level',
		experience: '6 Months',
		qualification: 'BECE Certificate',
		skillId: '[]'
	};

  constructor( private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private resources: SharedResources,
		private _api: ApiService) { }

  ngOnInit() {
  }
  saveJob() {
		if (this.feed.feedTitle == "" || this.feed.feedTitle == null) {
			toastr.clear();
			toastr.error('job title cannot be empty');
			return;
		}

		if (this.feed.description == "" || this.feed.description == null) {
			toastr.clear();
			toastr.error('Short Description cannot be empty');
			return;
		}


		this.feed.posted_at = $('#feed_posted').val();

		if (this.feed.posted_at == "" || this.feed.posted_at == null) {
			toastr.clear();
			toastr.error('Please select due date');
			return;
		}

		if (this.feed.job_type == "" || this.feed.job_type == null) {
			toastr.clear();
			toastr.error('Choose Job Type cannot be empty');
			return;
		}


		toastr.clear();
		toastr.info('Creating a new feed, please wait...');

		console.log('feed',this.feed);

		var url = 'api/feed/v1/post';
		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.feed,
			success: (data)=>{
				this._feed = data;
				this.FEED_POST_SUCCESSFUL = true;
				this.feed = {};
				this.alertService.alertType("Job Post Successfully",
					'You have successfully posted a job.', "success");
			},
			error: (error)=>{
				
			}
		});
	}


}
