import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminAccountComponent } from './account_admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { FormsModule } from '@angular/forms';
import { CompaniesComponent } from './companies/companies.component';

import { AgentsComponent } from './agents/agents.component';

import { AdminSearchPipe } from '../../_services/search.pipe';
import { RecruitersComponent } from './recruiters/recruiters.component';
import { AppraisalsComponent } from './appraisals/appraisals.component';
import { CategoriesComponent } from './categories/categories.component';
import { RolesComponent } from './roles/roles.component';
import { PaymentsComponent } from './payments/payments.component';
import { JobStatusComponent } from './job-status/job-status.component';
import { UsersComponent } from './users/users.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';

const routes: Routes = [
    {
        "path": "admin-account/:page",
        "component": AdminAccountComponent
    }
];
@NgModule({imports: [
    CommonModule,RouterModule.forChild(routes),FormsModule
],exports: [
    RouterModule
],declarations: [
    DashboardComponent, CompaniesComponent, 
    AgentsComponent, AdminSearchPipe, RecruitersComponent,
    AppraisalsComponent, CategoriesComponent,RolesComponent,
    PaymentsComponent, JobStatusComponent, UsersComponent,
    DashboardNewComponent
],providers:[
    AdminSearchPipe
],
entryComponents: [
    DashboardComponent, CompaniesComponent, AgentsComponent,
    RecruitersComponent, AppraisalsComponent,CategoriesComponent,
    RolesComponent, PaymentsComponent, JobStatusComponent, UsersComponent,
    DashboardNewComponent 
]
})
export class AdminAccountModule  {

}