import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

	password: any = {};

	constructor(
		private _api: ApiService,
		private _resources: SharedResources,
		private alertService: AlertsPromptsLoaders
	) { }

	ngOnInit() {
	}

	ChangePassword() {
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/v2/ChangePassword',
			body: this.password,
			success: (data)=>{

				this.password = {};
				this.alertService.alertType("Password Updated Successfully",
					'You have successfully updated your account password.', "success");

			},
			error: (error)=>{
				
			}
		});
	}
}
