import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';


declare let toastr: any;
declare let $;

@Component({
	selector: 'app-admin-agents',
	templateUrl: './agents.component.html',
	styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

	company:any = {};
	regions:any = [];
	cities:any = [];

	companies:any = [];
	selected_company:any = null;

	isNew:boolean = true;

	_companySearch:any = '';
	categories: any = [];

	company_id:any = 0;

	_filter:any = {
		newest: true,
        status_ids: [],
		genders: [],
		search: '',
		start_date: '',
		end_date: '',
        page: 1,
		load: 15,
		search_by: 1
	};

	status_ids:any = {
		pending: true,
		approved: true,
		denied: true,
		passive_denial: true
	};

	agents:any = [];
	users:any = [];

	TotalPages:any = 1;
	Starting:any = 1;
	Ending:any = 1;
	TotalRequests:any = 1;

	statuses:any = [{id: 1, name: 'Pending'},{id: 2, name: 'Approved'},{id: 3, name: 'Denied'},{id: 4, name: 'Passive Denial'}];

	summary:any = {
		Pending: 0,
		Approved: 0,
		Denied: 0,
		PassiveDenial: 0
	};

	constructor(
		private http: Http,
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {

		var self = this;
		$("#sort_select").on("change", function () {
			var Id = $(this).val();
			if(Id=='Newest') self._filter.newest = true;
			else self._filter.newest = false;

			self.getMyAgents();
		});

		this._resources.UpdateAuthentication.next({});

		this.getAgentsSummary();
		this.getMyAgents();
	}

	getAgentsSummary() {

		this._api.GET({
			api_route: 'api/admin/agents/v1/summary', success: (data) => {
				if (data != null) {
					this.summary = data;
				}
			},
			error: (error) => {
			}
		});
	}

	getMyAgents() {
		this.SetOptions();

		this._api.POST({
			load: true,
			body: this._filter,
			api_route: 'api/admin/agents/v1/applicants', success: (data) => {
				if (data != null) {
					

					this.agents = data.Requests;
					this.users = data.Users;
					this.TotalPages = data.TotalPages;
					this.TotalRequests = data.TotalRequests;
					
					this.Starting = ((this._filter.page-1) * this._filter.load) + 1;
					this.Ending = (this.Starting-1) + this.agents.length;// (this._filter.page * this._filter.load);
					
					this.RestructAgents();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructAgents(){
		
		this.agents.forEach(j => {
			j._last_modified = Helpers.fromNow(j.last_modified);
			j._status = this.GetJobStatus(j.status);
			j.status = j.status;
			j.klass = this.GetJobStatusClass(j.status);
			
			var c = this.users.find(x=>x.id == j.user_id);
			if(c!=null){
				j.gender = c.gender;
				j.image = c.image;
				j.firstName = c.firstName;
				j.lastName = c.lastName;
				j.email = c.email;

				
			}
		});
	}

	SetOptions(){

		this._filter.status_ids = [];

		if(this.status_ids.pending) this._filter.status_ids.push(1);
		if(this.status_ids.approved) this._filter.status_ids.push(2);
		if(this.status_ids.denied) this._filter.status_ids.push(3);
		if(this.status_ids.passive_denial) this._filter.status_ids.push(4);

		this._filter.NOFILTER = true;
		if(this._filter.start_date!='' && this._filter.end_date!=null)this._filter.NOFILTER = false;

		if(this._filter.status_ids.length>0)this._filter.NOFILTER = false;
		
	}

	Next(){
        if(this._filter.page<this.TotalPages){
            this._filter.page+=1;
            this.getMyAgents();
        }
    }

    Prev(){
        if(this._filter.page>1){
            this._filter.page-=1;
            this.getMyAgents();
        }       
	}
	
	ApplyFilter(){
        this._filter.page = 1;
		this.getMyAgents();    
	}

	GetJobStatus(status_id){
		status = 'Pending';
		if(status_id == 2) status = 'Approved';
		if(status_id == 3) status = 'Denied';
		if(status_id == 4) status = 'Passive Denial'; 

		return status;
	}

	GetJobStatusClass(status_id){
		status = 'badge-warning';
		if(status_id == 2) status = 'badge-success';
		if(status_id == 3) status = 'badge-danger';
		if(status_id == 4) status = 'badge-primary'; 

		return status;
	}

	ChangeApplicantStatus(_applicant){
		toastr.clear();
		toastr.info('Saving Changes please wait');
		
		this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/admin/agents/v1/change_applicant_status',
			body: _applicant,
			success: (data)=>{

				
				var app = this.agents.find(x=>x.id == _applicant.id);
				if(app!=null){
					app.status = _applicant.status;
					app.employer_message = _applicant.employer_message;
				}
				_applicant._status = this.GetJobStatus(_applicant.status);
				_applicant.klass = this.GetJobStatusClass(_applicant.status);

				this.RestructAgents();

				toastr.clear();
				toastr.success('changes saved successfully');
				this.getAgentsSummary();
			},
			error: (error)=>{
				
			}
		});
	}
}
