import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = null;

	summary:any = {};

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService,
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		
		this.getAboutDetails();
	}

	getAboutDetails() {
		
		this._api.GET({
			load: true,
			api_route: 'api/about_app/v1/summary', 
			success: (data) => {
				
				if (data != null) {
					this.summary = data;
					console.log(this.summary)
				}
			},
			error: (error) => {
			}
		});
	}
}
