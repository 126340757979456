import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

	categories:any = [];
	_cat:any = {};

	selected_category:any = null;

	_skillSearch:string = '';
	_categorySearch:string = '';

	skills:any = [];
	_skill:any = {};

	constructor(
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.getCategories();
	}

	getCategories() {
		this._api.GET({
			api_route: 'api/admin/category/v1/all', success: (data) => {
				if (data != null) {
					this.categories = data;
				}
			},
			error: (error) => {
			}
		});
	}

	AddNewCategory(){

		if(this._cat.categoryName=='' || this._cat.categoryName==null){
			toastr.clear();
			toastr.warning('Please enter the category name');
			return;
		}

		toastr.clear();
		toastr.info('Adding new category, please wait...');
		console.log('Category', this._cat);

		this._api.POST({
			api_route: 'api/admin/category/v1/new',
			body: this._cat,
			load: true,
			success: (data) => {
				this._cat = {};

				this.categories.push(data);

				toastr.clear();
				toastr.success('New Category has been added successfully.');
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

	Edit(_cat,val){
		_cat.edit = val;
	}

	SaveCategory(_cat){

		if(_cat.categoryName=='' || _cat.categoryName==null){
			toastr.clear();
			toastr.warning('Please enter the category name');
			return;
		}

		toastr.clear();
		toastr.info('saving changes on category, please wait...');

		this._api.POST({
			api_route: 'api/admin/category/v1/edit',
			body: _cat,
			load: true,
			success: (data) => {

				toastr.clear();
				toastr.success('Changes have been saved successfully.');
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

	confirmDelete(_cat){
        var options = {
            title: "Remove Category",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deletePermanently(_cat);
				}
			}
        });
    }

    deletePermanently(_cat){
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/admin/category/v1/delete',
			body: _cat,
			success: (data)=>{

				this.categories = this.categories.filter(x=> x.id != data.id);

				toastr.clear();
				toastr.success(data.categoryName +' have been removed successfuly');
			},
			error: (error)=>{
				
			}
		});
	}

	EditSkills(_cat){
		this.selected_category = _cat;

		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/admin/category/v1/skills/'+_cat.id, 
			success: (data) => {
				this.skills = data;
				$('#category_skills_modal').modal('toggle');
			},
			error: (error) => {
			}
		});
	}

	AddNewSkill(){

		if(this._skill.skillName=='' || this._skill.skillName==null){
			toastr.clear();
			toastr.warning('Please enter the skill name');
			return;
		}

		this._skill.categoryId = this.selected_category.id;

		toastr.clear();
		toastr.info('Adding new skill, please wait...');

		this._api.POST({
			api_route: 'api/admin/category/v1/new_skill',
			body: this._skill,
			load: true,
			success: (data) => {
				this._skill = {};

				this.skills.push(data);

				toastr.clear();
				toastr.success('New Skill has been added successfully.');
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

	SaveSkill(_skill){

		if(_skill.skillName!='' && _skill.skillName!=null){
			toastr.clear();
			toastr.warning('Please enter the skill name');
			return;
		}

		toastr.clear();
		toastr.info('saving changes on skill, please wait...');

		this._api.POST({
			api_route: 'api/admin/category/v1/edit_skill',
			body: _skill,
			load: true,
			success: (data) => {

				toastr.clear();
				toastr.success('Changes have been saved successfully.');
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

	confirmDeleteSkill(_skill){
        var options = {
            title: "Remove Skill",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deletePermanentlySkill(_skill);
				}
			}
        });
    }

    deletePermanentlySkill(_skill){
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/admin/category/v1/delete_skill',
			body: _skill,
			success: (data)=>{

				this.skills = this.skills.filter(x=> x.id != data.id);

				toastr.clear();
				toastr.success(data.skillName +' have been removed successfuly');
			},
			error: (error)=>{
				
			}
		});
	}
}
