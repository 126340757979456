import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';

@Component({
  selector: 'app-expresspay-appraisal',
  templateUrl: './expresspay-appraisal.component.html',
  styleUrls: ['./expresspay-appraisal.component.css']
})
export class ExpresspayAppraisalComponent implements OnInit {

	Activated:boolean = false;
	token:any = '';
	order_id:any = '';

	payment:any = null;

	constructor(
		private _api: ApiService,
		private route: ActivatedRoute,
		private alertService: AlertsPromptsLoaders
	) { }

	ngOnInit() {
		this.order_id = this.route.snapshot.queryParams['order-id'] || '';
		this.token = this.route.snapshot.queryParams['token'] || '';

		var body = {
			reference_id: this.order_id,
			token: this.token,
		};

		this._api.POST({
			api_route: 'api/payment/v1/appraisal/update',
			body: body,
			load: true,
			success: (data) => {
				this.Activated = true;
				this.payment = data;
				
				console.log('payment Data',data);
				// this.alertService.alertType("Account Activated Successfully",
				// 	'Kindly proceed to login in to your account.', "success");
			},
			error: (error) => {
				console.log('error', error);
			},
			showError: true
		});
	}

}
