import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { Agent } from 'http';


declare let toastr: any;
declare let $;
declare let ApexCharts:any;

@Component({
	selector: 'app-admin-dashboard-new',
	templateUrl: './dashboard-new.component.html',
	styleUrls: ['./dashboard-new.component.css']
})
export class DashboardNewComponent implements OnInit {

	users:any = [];
	_user:any = {
		email: ''
	};

	selected_user:any = null;

	varAll:any=[]
	varMale:any = []
	varFemale:any = []

	constructor(
		private http: Http,
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.GetDashboardInfo();
		this.RefreshDashboardData();
	}

	GetDashboardInfo(){
		this._api.GET({
			
			api_route: 'api/admin/dashboard/v1/info', success: (data) => {
				console.log('Info',data);
				data.forEach(dash => {
					var _data = JSON.parse(dash.data);
					console.log('Info',dash.title,_data);
				});
			},
			error: (error) => {
			}
		});
	}

	RefreshDashboardData(){
		this.setDashboardAgeDistribution();
		this.setDashboardApplicants();
		this.setDashboardJobStatuses();
		this.setDashboardSkills();
		this.setDashboardUserRegistration();
	}

	setDashboardSkills(){
		this._api.GET({
			api_route: 'api/admin/dashboard/v1/skills', success: (data) => {
				console.log('Skills',data);
			},
			error: (error) => {
			}
		});
	}

	setDashboardAgeDistribution(){
		this._api.GET({
			api_route: 'api/admin/dashboard/v1/age_distribution', success: (data) => {
				console.log('age',data);
				
			},
			error: (error) => {
			}
		});
	}

	setDashboardUserRegistration(){
		this._api.GET({
			api_route: 'api/admin/dashboard/v1/user_registration', success: (data) => {
				console.log('user',data);
				this.varAll=data
				this.varAll.forEach((data) => 
				console.log(this.varAll.female))
				
			},
			error: (error) => {
			}
		});
	}

	setDashboardApplicants(){
		this._api.GET({
			api_route: 'api/admin/dashboard/v1/applicants', success: (data) => {
				console.log('applicants',data);
			},
			error: (error) => {
			}
		});
	}

	setDashboardJobStatuses(){
		this._api.GET({
			api_route: 'api/admin/dashboard/v1/jobs', success: (data) => {
				console.log('jobs',data);
			},
			error: (error) => {
			}
		});
	}


	Chart2(){
		var self = this;
		
		setTimeout(function() {

			var options = {
				series: [{
					name: 'All',
					type: 'column',
					data: self.varAll,
				  }, {
					name: 'Male',
					type: 'line',
					data: self.varMale
				  }, {
					name: 'Female',
					type: 'line',
					data: self.varFemale,
					enabled: false,
				  }],
					chart: {
					height: 350,
					type: 'line',
				  },
				  stroke: {
					width: [0, 4, 4]
				  },
				  title: {
					text: 'Gender Representation'
				  },
				  dataLabels: {
					enabled: true,
					enabledOnSeries: [1]
				  },
				  labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
				  xaxis: {
					type: 'datetime'
				  },
				  yaxis: [{
					title: {
					  text: 'All',
					},
				  
				  }
				  /*, {
					opposite: true,
					title: {
					  text: 'Male'
					}
				  }*/]
				  };
	
			var chart = new ApexCharts(
				document.querySelector("#chart3"),
				options
			);
			chart.render();
		}, 1000);
	

	}
	Chart3(){
		setTimeout(function() {
		var options2 = {
			series: [44, 55, 13, 43, 22],
			chart: {
			width: 380,
			type: 'pie',
		  },
		  labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
		  responsive: [{
			breakpoint: 480,
			options: {
			  chart: {
				width: 200
			  },
			  legend: {
				position: 'bottom'
			  }
			}
		  }]
		  };
		  var chart = new ApexCharts(
			document.querySelector("#chart4"),
			options2
		);

		chart.render();
		}, 1000);
	}
}




