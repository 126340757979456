import { Component, OnInit } from '@angular/core';

declare let $;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
    id: number =0;
    name: string="";
    email: string="";
    phone?: string="";
    message: string="";

  constructor() { }

  ngOnInit() {
  }
  

}
