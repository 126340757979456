import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { SharedResources } from '../../_services/shared.resources';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isAuthenticated: boolean = false;
	user: any = null;

	categories:any = [];
	skills: any = [];
	tech:any =[];
	busadmin:any=[];
	impact:any=[];
	diaspora:any=[];
	

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _resources: SharedResources,
		private _api: ApiService,
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		console.log('Authentication', this.isAuthenticated);
		console.log('user', this.user);

		this.getCategories();
		this.getSkill();

		
	}
	getSkill() {
		this._api.GET({
			api_route: 'api/resume/v1/all_skills', success: (data) => {
				//console.log('skills', data);
				this.skills = data;
				//var cat_skills= this.skills.categoryId[1];
				//console.log(cat_skills);
				
				this.skills.forEach(s=>{
					if (s.categoryId == 1){
						s.categoryId= "Technology"
						this.tech.push(s);
						//console.log(this.tech)
					}
					if (s.categoryId == 2){
						s.categoryId= "Business and Admin"
						this.busadmin.push(s);
						//console.log(this.skills)
					}
					if (s.categoryId == 3){
						s.categoryId= "Business Sourcing"
						this.diaspora.push(s);
						console.log(this.diaspora)
					}
					if (s.categoryId == 4){
						s.categoryId= "Impact Sourcing"
						this.impact.push(s);
						//console.log(this.skills)
					}
				}) 
				
			},
			error: (error) => {
			}
		});
	}

	getCategories() {
		
		this._api.GET({
			load: true,
			api_route: 'api/category/v1/summary', 
			success: (data) => {
				
				if (data != null) {
					this.categories = data;
					this.RestructCategories();
					console.log(this.categories);
				}
			},
			error: (error) => {
			}
		});
	}

	RestructCategories(){
		this.categories.forEach(cat => {
			if(cat.categoryName == "Business Sourcing")cat.image = '/assets/images/category/digital.jpg';
			if(cat.categoryName == "Admin, Business & Management")cat.image = '/assets/images/category/busadmin.jpg';
			if(cat.categoryName == "Technology")cat.image = '/assets/images/category/technology.jpg';
			if(cat.categoryName == "Impact Sourcing")cat.image = '/assets/images/category/impact.jpg';
		});
	}

	//OpenCategory(category){
	//	this._resources._selectedCategory = category;
	//	this._router.navigate(['/job-category-search']);
	//}
	OpenCategory(category){
		this._resources._selectedCategory = category;
		this._router.navigate(['/job-categories']);
	}
}
