import { Component, OnInit } from '@angular/core';
import { Helpers } from '../../helpers';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SharedResources } from '../../_services/shared.resources';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { Router } from '@angular/router';
import {SocialService} from "ngx-social-button";
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ApiService } from '../../_services/api.service';

import { Subscription, interval } from 'rxjs';


declare let toastr:any;
declare let $:any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	password: string = "";
	email: string = "";

	isAuthenticated: boolean = false;
	user: any = null;

	constructor(
		private http: Http,
		private _api: ApiService,
		private _http: HttpClient,
		private appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private resources: SharedResources,
		private socialAuthService: SocialService
	) { }

		//start here
	private subscription: Subscription;
  
    public dateNow = new Date();
    public dDay = new Date('Mar 15 2021 00:00:00');
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute  = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    public hoursToDday;
    public daysToDday;


    private getTimeDifference () {
        this.timeDifference = this.dDay.getTime() - new  Date().getTime();
        this.allocateTimeUnits(this.timeDifference);
    }

	private allocateTimeUnits (timeDifference) {
			this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
			this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
			this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
			this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
	}
	//End Here

	

	ngOnInit() {
		this.isAuthenticated = this.resources._isAuthenticated;
		this.user = this.resources._user;

		this.resources.UpdateAuthentication.next({});
		//this.registerReCaptchaCallback();
		this.addScript();

		this.subscription = interval(1000)
           .subscribe(x => { this.getTimeDifference(); });
	  }
	
	//   registerReCaptchaCallback() {
	// 	window.reCaptchaLoad = () => {
	// 	  const config = {
	// 		...this.config,
	// 		'sitekey': this.key,
	// 		'callback': this.onSuccess.bind(this),
	// 		'expired-callback': this.onExpired.bind(this)
	// 	  };
	// 	  this.widgetId = this.render(this.element.nativeElement, config);
	// 	};
	//   }
	
	//   private render( element : HTMLElement, config ) : number {
	// 	return grecaptcha.render(element, config);
	//   }
	
	  addScript() {
		let script = document.createElement('script');
		//const lang = this.lang ? '&hl=' + this.lang : ''; -- ?onload=reCaptchaLoad&render=explicit${lang}
		script.src = `https://www.google.com/recaptcha/api.js`;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
	  }

	completeLogin() {

		let headerss = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		Helpers.setLoading(true);


		let b = 'username=' + this.email + '&password=' + this.password + '&grant_type=password';
		this.http.post(this.appset.HURL + 'token', b, { headers: headerss }).pipe(map((response) => response.json())).subscribe(data => {
			
			Helpers.setLoading(false);
			// login successful if there's a jwt token in the response
			let user: any = data;

			user.token = user.access_token;
			if (user && user.token) {
				
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem('currentUser', JSON.stringify(user));

				
				this.resources.UpdateAuthentication.next({});
				this._router.navigate(['/account/profile']);
			}
		}, error => {
			
			this.alertService.alertType("Authentication Failed","Invalid Username or Password!","error");
			console.log('ERROR', error);
			Helpers.setLoading(false);
		});

	}

	login(){
		if (this.email == '') {
			toastr.clear();
			toastr.warning('Email cannot be empty or null!');
			return;
		}

		if (this.password == '') {
			toastr.clear();
			toastr.warning('Please enter your password');
			return;
		}

		var recaptcha = $('[name="g-recaptcha-response"]').val();//$('#g-recaptcha-response').val();
		if (recaptcha == '' || recaptcha == null) {
			toastr.clear();
			toastr.warning('Are you a robot?');
			return;
		}

		Helpers.setLoading(true);

		var body = {
			message: recaptcha
		};

		this._api.POST({
			load: true,
			body: body,
			api_route: 'api/recaptcha/v1/verify', success: (data) => {
				
				var res = JSON.parse(data);

				if(res.success){
					this.completeLogin();
				}
			},
			error: (error) => {
			}
		});

		/*let body = new URLSearchParams();
		body.set('secret', this.appset.gRecaptchaSecret);
		body.set('response', recaptcha);

		let options = {
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
		};

		let b = 'secret=' + this.appset.gRecaptchaSecret + '&response=' + recaptcha ;
		
	this._http.post('https://www.google.com/recaptcha/api/siteverify?'+b,{}, options).subscribe(data => {
			
			Helpers.setLoading(false);
			
			

		}, error => {
			
			this.alertService.alertType("Google Recaptcha Authentication Failed","Recaptcha Verification Error","error");
			console.log('ERROR', error);
			Helpers.setLoading(false);
		});*/
	}

	signOut(){
		if(this.socialAuthService.isSocialLoggedIn()){
			this.socialAuthService.signOut().catch((err)=>{
 
			});
		}
	}
	
	getSocialUser(socialUser){
        console.log(socialUser);
    }
 
}
