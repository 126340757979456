import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../../../helpers';
import { AlertsPromptsLoaders } from '../../../_services/alerts.loaders';
import { ApiService } from '../../../_services/api.service';
import { AppSettingsUnilynq } from '../../../_services/app-settings.service';
import { SharedResources } from '../../../_services/shared.resources';


declare let ApexCharts:any;
declare let toastr:any;
declare let $:any;

@Component({
	selector: 'app-company-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	_companySearch:any = '';
	company:any = {};
	gallery:any = [];
	
	company_id:any = 0;

	selected_image:any = null;

	constructor(
		private _resources: SharedResources,
		private route:ActivatedRoute,
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private _api: ApiService) { }

	ngOnInit() {

		$("#UploadFile-Company-Gallery-modal").on("shown.bs.modal", function() {
			$('#back-to-top').click();
		});

		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;

		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.company_id=this.route.snapshot.paramMap.get("company_id");
		this.getCompany();
	}

	getCompany() {
		this._api.GET({
			api_route: 'api/user/company/v1/single/'+this.company_id, success: (data) => {
				if (data != null) {
					this.company = data;
					this.gallery = JSON.parse(data.gallery);

					var lat_lng = [0,0];

					if(this.company.coordinates!=null){
						if(this.company.coordinates.split(',').length>0){
							lat_lng = this.company.coordinates.split(',');
							this.company.lat = lat_lng[0];
							this.company.lng = lat_lng[1];
						}
					}
				}
			},
			error: (error) => {
			}
		});
	}

	UploadImage(){
		
		var api = "api/user/company/v1/gallery_image/"+this.company_id;
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'Company Gallery Images',
            e: '#m-dropzone-one-gallery',
            pe: '#honeycomb-gallery',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading File, please wait');
                $('#UploadFile-Company-Gallery-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
				toastr.success('File upload successful');
				
				self.company.gallery = res.gallery;
				self.gallery = JSON.parse(res.gallery);
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Company-Gallery-modal').modal('toggle');
            }
        });
	}

	EditGalleryImage(_image){
		this.selected_image = {
			id: _image.id,
			path: _image.path,
			description: _image.description
		};
		$('#add_company_modal').modal('toggle');
	}

	saveChanges() {
		var url = 'api/user/company/v1/edit_gallery_image/'+this.company_id;

		this._api.POST({
			load: true,
			showError: true,
			api_route: url,
			body: this.selected_image,
			success: (data)=>{
				
				this.company.gallery = data.gallery;
				this.gallery = JSON.parse(data.gallery);

				$('#add_company_modal').modal('toggle');
				toastr.clear();
				toastr.success('Changes to gallery have been saved successfuly');
			},
			error: (error)=>{
				
			}
		});
	}

	confirmDelete(_image){
        var options = {
            title: "Remove Image",
            text: "Are you sure? You won't be able to revert this!",
            icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
    				visible: true,
				},
				  confirm: {
					text: "Yes, Remove it!",
					value: true,
    				visible: true,
				}
			}
        };
        var self = this;
        this.alertService.confirm(options,function(e){
			if(e!=null){
				if(e==true){
					self.deletePermanently(_image);
				}
			}
        });
    }

    deletePermanently(_image){
		console.log('delete',_image);
        this._api.POST({
			load: true,
			showError: true,
			api_route: 'api/user/company/v1/delete_gallery_image/'+this.company_id,
			body: _image,
			success: (data)=>{

				this.company.gallery = data.gallery;
				this.gallery = JSON.parse(data.gallery);

				toastr.clear();
				toastr.success('Changes to gallery have been saved successfuly');
			},
			error: (error)=>{
				
			}
		});
	}
}
