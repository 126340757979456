import { Injectable } from "@angular/core";
import swal from 'sweetalert';

declare let mApp: any;
declare let $: any;


@Injectable()
export class AlertsPromptsLoaders {
    blockUI(ele: string, options: any) {
        mApp.block(ele, options);
    }

    unblockUI(ele: string) {
        mApp.unblock(ele);
    }

    alert(text: string) {
        swal(text);
        setTimeout(function() {
            $('body').css({ 'padding-right': '0px' })
        }, 100);
    }

    alertText(title: string, text: string) {
        swal(title, text);
    }

    alertType(title: string, text: string, type: string) {
        swal(title, text, type);
        setTimeout(function() {
            $('body').css({ 'padding-right': '0px' })
        }, 100);
    }

    alertError(error: any) {
        var body = JSON.parse(error._body);
        if (body != null) {
            
            var title: string = 'Error!', text: string = 'Reason: ' + body.Message;
            
            if(body.ModelState){
                console.log('R',body.ModelState);
                /*var state = JSON.parse(body.ModelState);
                state = state.split("{").join(" ");
                state = state.split("}").join(" ");
                state = state.split("[").join(" ");
                state = state.split("]").join(" ");

                text = 'Reason: ' + state;*/
            }

            swal(title, text, 'error');
        } else {
            swal('Error!', 'Sorry, an error occured. ' + error, 'error');
        }
    }

    alertOptions(options: any) {
        swal(options);
        setTimeout(function() {
            $('body').css({ 'padding-right': '0px' })
        }, 100);
    }

    confirm(options: any, callback: any) {
        if (options == null) {
            options = {
                title: "Are you sure",
                text: "You won't to proceed with the selected action!",
                type: "warning",
                showCancelButton: !0,
                confirmButtonText: "Yes, Proceed!"
            };
        }
        swal(options).then(callback);
        setTimeout(function() {
            $('body').css({ 'padding-right': '0px' })
        }, 100);
    }
}