import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Routes, RouterModule, Router,ActivatedRoute } from '@angular/router';
import { AppSettingsUnilynq } from '../../_services/app-settings.service';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordComponent } from './password/password.component';
import { ResumeComponent } from './resume/resume.component';
import { PostjobComponent } from './postjob/postjob.component';
import { PostJobComponent } from './post-job/post-job.component';
import { AccessComponent } from './access/access.component';
import { MyJobsComponent } from './my-jobs/my-jobs.component';
import { AgentAppraisalComponent } from './agent-appraisal/agent-appraisal.component';

import { CandidatesComponent } from './candidates/candidates.component';
import { JobsComponent } from './jobs/jobs.component';
import { SavedJobsComponent } from './saved-jobs/saved-jobs.component';
import { SharedResources } from '../../_services/shared.resources';
import { CompaniesComponent } from './companies/companies.component';
import { AlertsPromptsLoaders } from '../../_services/alerts.loaders';
import { ApiService } from '../../_services/api.service';

declare let $:any;
declare let toastr:any;

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
	activeCategories = ['Profile'];
	activePage:any = 'Profile';

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	percentageComplete:number = 0;

	user_modules:any = [];

	@ViewChild('componentcontainer', { read: ViewContainerRef })
	entry: ViewContainerRef;

	constructor(
		private resolver: ComponentFactoryResolver,
		public appset: AppSettingsUnilynq,
		private _router: Router,
		private alertService: AlertsPromptsLoaders,
		private route:ActivatedRoute,
		private _api: ApiService,
		private _resources: SharedResources
	) { }

	ngOnInit() {
		this.InitPage();
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this._resources.AuthenticationStatus.subscribe(res=>{
			if(!this._resources._isAuthenticated){
				this._router.navigate(['/login']);
				return;
			}

			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if(this.user!=null){
				this.name = this.user.firstName + " " + this.user.lastName;
			}
		});

		this._resources.UpdateResumeCompleted.subscribe(res=>{
			this.getResumeStatus();
		});
	
		this._resources.AlertPageChange.subscribe(res=>{
			this.InitPage();
		});
    	if(this.user!=null){
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.getResumeStatus();
		this.getUserModules();
	}

	InitPage(){
		this.activePage=this.route.snapshot.paramMap.get("page");
		
		if(this.activePage == 'profile'){
			this.CreateComponentProfile();
		}else if(this.activePage == 'password'){
			this.CreateComponentPassword();
		} else if(this.activePage == 'resume'){
			this.CreateComponentResume();
		} else if(this.activePage == 'post-job'){
			this.CreateComponentPostJob();
		} else if(this.activePage == 'candidates'){
			this.CreateComponentCandidates();
		} else if(this.activePage == 'manage-jobs'){
			this.CreateComponentManageJobs();
		} else if(this.activePage == 'my-jobs'){
			this.CreateComponentMyJobs();
		} else if(this.activePage == 'saved-jobs'){
			this.CreateComponentSavedJobs();
		} else if (this.activePage =='postjob') {
			this.CreateComponentPostaJob();
		} else if (this.activePage =='companies') {
			this.CreateComponentCompanies();
		} else if (this.activePage =='access') {
			this.createComponentAccess();
		} else if (this.activePage =='agent-appraisals') {
			this.CreateComponentAgentAppraisal();
		} else if (this.activePage =='post-a-job'){
			this.CreateComponentPostNewJob();
		}
		else {
			this.createComponentDashboard();
		}
		
	}

	ReturnActiveClass(cate){
        for(var i=0;i<this.activeCategories.length;i++){
            if(this.activeCategories[i]==cate) return 'active';
        }
        return '';
	}
	
	createComponentDashboard(){
		this.activeCategories = ['Dashboard'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(DashboardComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	createComponentAccess() {
		this.activeCategories = ['Access'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(AccessComponent);
		const componentRef = this.entry.createComponent(factory);
	}
	

	CreateComponentMyJobs() {
		this.activeCategories = ['MyJobs'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(MyJobsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentProfile() {
		this.activeCategories = ['Profile'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(ProfileComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentPassword() {
		this.activeCategories = ['Password'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(PasswordComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentResume() {
		this.activeCategories = ['Resume'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(ResumeComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentCandidates() {
		this.activeCategories = ['Candidates'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(CandidatesComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentManageJobs() {
		this.activeCategories = ['Jobs'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(JobsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentSavedJobs() {
		this.activeCategories = ['SavedJobs'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(SavedJobsComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentPostJob() {
		this.activeCategories = ['PostJob'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(PostjobComponent);
		const componentRef = this.entry.createComponent(factory);
	}

	CreateComponentPostaJob(){
		this.activeCategories = ['PostaJob'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(PostJobComponent);
		const componentRef = this.entry.createComponent(factory);
	}
	CreateComponentPostNewJob(){
		this.activeCategories =['PostNewJob'];
	}

	CreateComponentCompanies(){
		this.activeCategories = ['Companies'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(CompaniesComponent);
		const componentRef = this.entry.createComponent(factory);
	}


	CreateComponentAgentAppraisal(){
		this.activeCategories = ['AgentAppraisal'];
		this.entry.clear();
		const factory = this.resolver.resolveComponentFactory(AgentAppraisalComponent);
		const componentRef = this.entry.createComponent(factory);
	}


	UploadImage(){
		var api = "api/user/v2/change_image";
        var self = this;
        this._resources.$HeaderFileUploader.next({
            p:'User Profile',
            e: '#m-dropzone-one-author',
            pe: '#honeycomb-author',
            a: api,
            s:function (file, xhr, formdata) {
                toastr.clear();
                toastr.info('Uploading Profile Image, please wait');
                $('#UploadFile-Profile-modal').modal('toggle');
            },
            c:function(res){
                toastr.clear();
                toastr.success('File upload successful');
				self.user = res;
            },
            err:function (err){
                self.alertService.alertType("Error","An error occured while uploading image."+
                    " Ensure file is less than 300.0KB and in the following extensions (.jpg, .jpeg, .png).","error");
            },
            i:function (err){
                $('#UploadFile-Profile-modal').modal('toggle');
            }
        });
	}

	getResumeStatus(){
		this._api.GET({
			api_route: 'api/resume/v1/resume', success: (data)=>{
				console.log(data);
				if (data.myresume!=null){
					var resume = data.myresume;
					this.ParseResume(resume);	
				}
				
			},
			error: (error)=>{
			}
		});
	}

	ParseResume(resume){
		var education = JSON.parse(resume.education);
		var awards = JSON.parse(resume.awards);
		var skills = JSON.parse(resume.skills);
		var workexperience = JSON.parse(resume.workexperience);
		var coverLetter = resume.coverLetter;

		var total = 0;
		if(education.length>0) total += 20;
		if(awards.length>0) total += 20;
		if(workexperience.length>0) total += 20;
		if(skills.length>0) total += 25;
		if(coverLetter!="" && coverLetter!=null) total += 15;

		this.percentageComplete = total;
	}

	getUserModules() {

		this._api.GET({
			api_route: 'api/user/v2/assigned_roles', 
			success: (data) => {
				if (data != null) {
					this.user_modules = data;
				}
			},
			error: (error) => {
			}
		});
	}
}
