import { Component, OnInit } from '@angular/core';
import { SharedResources } from '../../_services/shared.resources';

declare let $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isAuthenticated: boolean = false;

  constructor(
    private _resources: SharedResources
  ) { }

  ngOnInit() {

    this._resources.AuthenticationStatus.subscribe(x=>{
			this.isAuthenticated = this._resources._isAuthenticated;
		});

    $('#WAButton').floatingWhatsApp({
      phone: '233557707894', //WhatsApp Business phone number
      headerTitle: 'Chat with JobMatch Helpdesk on Whatsapp!', //Popup Title
      popupMessage: 'Welcome to JobMatch, how can we help you? Type to send us a message.', //Popup Message
      showPopup: true, //Enables popup display
      buttonImage: '<img src="assets/images/WhatsApp.svg" />', //Button Image
      //headerColor: 'crimson', //Custom header color
      //backgroundColor: 'crimson', //Custom background button color
      position: "left" //Position: left | right

  });

  $('#WAButton').find('.btn').floatingWhatsApp({
    showPopup: false,
    position: "right",
    backgroundColor: 'crimson', //Custom background button color
  })
  }

}
