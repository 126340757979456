import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from 'src/app/helpers';
import { AlertsPromptsLoaders } from 'src/app/_services/alerts.loaders';
import { ApiService } from 'src/app/_services/api.service';
import { AppSettingsUnilynq } from 'src/app/_services/app-settings.service';
import { SharedResources } from 'src/app/_services/shared.resources';

declare let toastr: any;
declare let $;

@Component({
	selector: 'app-candidate-resume',
	templateUrl: './candidate-resume.component.html',
	styleUrls: ['./candidate-resume.component.css']
})
export class CandidateResumeComponent implements OnInit {

	isAuthenticated: boolean = false;
	user: any = {};
	name: string = "";

	job_id:any = 0;
	user_id:any = 0;

	Job:any = null;
	Resume:any = null;
	User:any = {};
	Applicant:any = null;

	education: any = [];
	awards: any = [];
	workexperience: any = [];
	userSkills: any =[];

	gallery:any = [];
	other_info:any = {};

	constructor(
		public appset: AppSettingsUnilynq,
		private alertService: AlertsPromptsLoaders,
		private _router: Router,
		private route:ActivatedRoute,
		private _resources: SharedResources,
		private _api: ApiService
	) { }

	ngOnInit() {
		this.isAuthenticated = this._resources._isAuthenticated;
		this.user = this._resources._user;
		this._resources.AuthenticationStatus.subscribe(res => {
			this.isAuthenticated = this._resources._isAuthenticated;
			this.user = this._resources._user;

			if (this.user != null) {
				this.name = this.user.firstName + " " + this.user.lastName;
			}
			//console.log('Resources', res);
		});

		if (this.user != null) {
			this.name = this.user.firstName + " " + this.user.lastName;
		}

		this.job_id = this.route.snapshot.paramMap.get("job_id");
		this.user_id = this.route.snapshot.paramMap.get("user_id");

		this.getCandidateProfile();
	}

	getCandidateProfile() {
		
		this._api.GET({
			load: true,
			showError: true,
			api_route: 'api/company/job/v1/resume/'+this.job_id+'/'+this.user_id, success: (data) => {
				if (data != null) {
					this.Job = data.Job;
					this.Resume = data.MyResume;
					this.User = data.User;
					
					this.Applicant = data.Applicant;

					this.RestructProfile();
				}
			},
			error: (error) => {
			}
		});
	}

	RestructProfile(){
		if(this.Resume!=null){
			this.education = JSON.parse(this.Resume.education);
			this.awards = JSON.parse(this.Resume.awards);
			this.workexperience = JSON.parse(this.Resume.workexperience);
			this.userSkills = JSON.parse(this.Resume.skills);
		}
		console.log('Other Info',this.User);
		if(this.User.other_info!=null)
			this.other_info = JSON.parse(this.User.other_info);
		this.gallery = JSON.parse(this.User.gallery);

		if(this.User.dob){
			this.User._dob = Helpers.longShortDate(this.User.dob);
		}
	}

}
